import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Unit } from 'src/app/entities/units/unit.entity';
import { UnitsService } from '../../unit/units.service';
import { Router } from '@angular/router';
import { APIService } from '../../api.service';
import { PERMISSIONS } from '../permissions';
import { UserRole } from '../../entities/residents/user.entity';

export interface Menu {
  path?: string;
  queryParams?: any;
  title?: string;
  icon?: string;
  type?: string;
  headTitle?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  children?: Menu[];
  disabled?: boolean;
}

// @Injectable()
@Injectable({
  providedIn: 'root',
})
export class NavService {
  constructor(public unitsService: UnitsService, public router: Router, private API: APIService) {
    this.onResize();
    if (this.screenWidth < 1199) {
      this.openToggle = true;
    }

    this.currentUnit$.asObservable().subscribe(async currentUnit => {
      if (!currentUnit) {
        const units = await this.unitsService.getAll();
        const oldUnit = window.localStorage.getItem('currentUnit');
        const oldHotel = window.localStorage.getItem('currentHotel');
        console.log(oldUnit, oldHotel);
        if (oldUnit && oldHotel) {
          return this.setCurrentUnit(oldUnit, parseInt(oldHotel, 10));
        } else if (units && units.length) {
          return this.currentUnit$.next(units[0]);
        }
        return;
      }

      let unitCode;
      if (currentUnit) {
        console.log('TCL: NavService -> createMenuItems -> currentUnit', currentUnit);
        unitCode = currentUnit.code;

        this.hotelInfo = new Unit(currentUnit);
      }

      console.log(`/unit/${unitCode}/spaces`);

      // tslint:disable: max-line-length
      const MENUITEMS: Menu[] = [
        { headTitle: 'Geral' },
        { title: 'Informações', icon: 'icon-info-alt', type: 'link', path: `/units/${this.hotelInfo.hotelCode}/edit`, disabled: !this.checkPermission('units', 'update') },
        { title: 'Espaços para Reserva', icon: 'icon-home', type: 'link', path: `/unit/${this.hotelInfo.code}/spaces` },
        { title: 'Áreas Comuns', icon: 'icon-location-pin', type: 'link', path: `/unit/${this.hotelInfo.code}/areas` },
        { title: 'Quartos', icon: 'fa fa-bed', type: 'link', path: `/unit/${this.hotelInfo.code}/rooms` },

        { headTitle: 'Moradores' },
        { title: 'Novo Morador', icon: 'icon-plus', type: 'link', path: `/unit/${this.hotelInfo.code}/residents/create`, disabled: !this.checkPermission('residents', 'create') },
        { title: 'Lista', icon: 'icon-list', type: 'link', path: `/unit/${this.hotelInfo.code}/residents` },
        { title: 'Extratos', icon: 'icon-money', type: 'link', path: `/unit/${this.hotelInfo.code}/statements` },
        { title: 'Sincronizar', icon: 'icon-reload', type: 'link', path: `/unit/${this.hotelInfo.code}/residents/sync`, disabled: !this.checkPermission('residents', 'sync') },
        { title: 'Notificar ', icon: 'icon-bell', type: 'link', path: `/unit/${this.hotelInfo.code}/residents/notify`, disabled: !this.checkPermission('residents', 'create') },
        // {title: 'Pagamentos', icon: 'fa fa-bath', type: 'link', path: `/unit/${this.hotelInfo.code}/billing`},

        { headTitle: 'Visitas', disabled: !this.checkPermission('visits', 'list') },
        { title: 'Visitas', icon: 'icon-shift-right', type: 'link', path: `/unit/${this.hotelInfo.code}/visits`, disabled: !this.checkPermission('visits', 'list') },
        // {title: 'Visitantes', icon: 'icon-user', type: 'link', path: `/unit/${this.hotelInfo.code}/visitors`, disabled: !this.checkPermission('visits', 'list')},

        { headTitle: 'Reservas de Espaços', disabled: !this.checkPermission('schedule', 'list') },
        { title: 'Nova reserva', icon: 'icon-plus', type: 'link', path: `/unit/${this.hotelInfo.code}/schedule/create`, disabled: !this.checkPermission('schedule', 'create') },
        { title: 'Calendário', icon: 'icon-calendar', type: 'link', path: `/unit/${this.hotelInfo.code}/schedule`, disabled: !this.checkPermission('schedule', 'list') },

        { headTitle: 'Funcionários', disabled: !this.checkPermission('employees', 'list') },
        { title: 'Cadastrar', icon: 'icon-plus', type: 'link', path: `/unit/${this.hotelInfo.code}/employees/All/create`, disabled: !this.checkPermission('employees', 'create') },
        { title: 'Todos', icon: 'icon-menu-alt', type: 'link', path: `/unit/${this.hotelInfo.code}/employees/All`, disabled: !this.checkPermission('employees', 'list') },
        { title: 'Marketing', icon: 'icon-id-badge', type: 'link', path: `/unit/${this.hotelInfo.code}/employees/Marketing`, disabled: !this.checkPermission('employees', 'list') },
        {
          title: 'Community Managers',
          icon: 'icon-agenda',
          type: 'link',
          path: `/unit/${this.hotelInfo.code}/employees/Manager`,
          disabled: !this.checkPermission('employees', 'list'),
        },
        {
          title: 'Student Relations',
          icon: 'icon-id-badge',
          type: 'link',
          path: `/unit/${this.hotelInfo.code}/employees/Porter`,
          disabled: !this.checkPermission('employees', 'list'),
        },
        { title: 'Limpeza', icon: 'icon-brush-alt', type: 'link', path: `/unit/${this.hotelInfo.code}/employees/Cleaning`, disabled: !this.checkPermission('employees', 'list') },
        {
          title: 'Manutenção',
          icon: 'icon-hummer',
          type: 'link',
          path: `/unit/${this.hotelInfo.code}/employees/Maintenance`,
          disabled: !this.checkPermission('employees', 'list'),
        },
        { title: 'ULeaders', icon: 'icon-star', type: 'link', path: `/unit/${this.hotelInfo.code}/employees/Uleader`, disabled: !this.checkPermission('employees', 'list') },
        {
          title: 'Comercial',
          icon: 'icon-headphone-alt',
          type: 'link',
          path: `/unit/${this.hotelInfo.code}/employees/${UserRole.Commercial}`,
          disabled: !this.checkPermission('employees', 'list'),
        },
        {
          title: 'Facilities',
          icon: 'icon-user',
          type: 'link',
          path: `/unit/${this.hotelInfo.code}/employees/${UserRole.Facilities}`,
          disabled: !this.checkPermission('employees', 'list'),
        },

        { headTitle: 'Eventos', disabled: !this.checkPermission('events', 'list') },
        { title: 'Criar evento', icon: 'icon-plus', type: 'link', path: `/unit/${this.hotelInfo.code}/events/all/create`, disabled: !this.checkPermission('events', 'create') },
        {
          title: 'Hoje',
          icon: 'icon-calendar',
          type: 'link',
          path: `/unit/${this.hotelInfo.code}/events/calendar`,
          queryParams: { today: true },
          disabled: !this.checkPermission('events', 'list'),
        },
        { title: 'Todos', icon: 'icon-menu-alt', type: 'link', path: `/unit/${this.hotelInfo.code}/events/all`, disabled: !this.checkPermission('events', 'list') },

        { headTitle: 'Limpeza', disabled: !this.checkPermission('cleaning', 'list') },
        {
          title: 'Tipos de Limpeza',
          icon: 'fa fa-tags',
          type: 'link',
          path: `/unit/${this.hotelInfo.code}/cleaning/cleaning-types`,
          disabled: !this.checkPermission('cleaning', 'list'),
        },
        // {title: 'QR Codes', icon: 'fa fa-qrcode', type: 'link', path: `/unit/${this.hotelInfo.code}/cleaning/qr-codes`},
        { title: 'Agenda', icon: 'icon-calendar', type: 'link', path: `/unit/${this.hotelInfo.code}/cleaning/calendar`, disabled: !this.checkPermission('cleaning', 'list') },
        // {title: 'Todos', icon: 'icon-menu-alt', type: 'link', path: `/unit/${this.hotelInfo.code}/cleaning/all`},

        { headTitle: 'Manutenções', disabled: !this.checkPermission('maintenance', 'list') },
        {
          title: 'Manutenções',
          icon: 'icon-paint-roller',
          type: 'link',
          path: `/unit/${this.hotelInfo.code}/maintenance/request`,
          disabled: !this.checkPermission('maintenance', 'list'),
        },
        { title: 'Subcategorias', icon: 'icon-settings', type: 'link', path: `/config/maintenance/subcategories`, disabled: !this.checkPermission('config.maintenance', 'list') },
        {
          title: 'Relatórios',
          icon: 'icon-bar-chart',
          type: 'link',
          path: `/unit/${this.hotelInfo.code}/maintenance/reports`,
          disabled: !this.checkPermission('maintenance', 'reports'),
        },


        { headTitle: 'Ocorrências', disabled: !this.checkPermission('occurrence', 'list') },
        {
          title: 'Ocorrências',
          icon: 'icon-pin-alt',
          type: 'link',
          path: `/unit/${this.hotelInfo.code}/occurrence/request`,
          disabled: !this.checkPermission('occurrence', 'list'),
        },
        { title: 'Subcategorias', icon: 'icon-settings', type: 'link', path: `/config/occurrence/subcategories`, disabled: !this.checkPermission('config.occurrence', 'list') },

        { headTitle: 'Feedback', disabled: !this.checkPermission('feedback', 'list') },
        {
          title: 'Sugestões',
          icon: 'icon-face-smile',
          type: 'link',
          path: `/unit/${this.hotelInfo.code}/feedback/suggestions`,
          disabled: !this.checkPermission('feedback', 'list'),
        },
        {
          title: 'Reclamações',
          icon: 'icon-face-sad',
          type: 'link',
          path: `/unit/${this.hotelInfo.code}/feedback/complainings`,
          disabled: !this.checkPermission('feedback', 'list'),
        },
        { headTitle: 'Orange Club', disabled: !this.checkPermission('map', 'list') && !this.checkPermission('vouchers', 'list') },
        { title: 'Vouchers', icon: 'icon-ticket', type: 'link', path: `/unit/${this.hotelInfo.code}/vouchers`, disabled: !this.checkPermission('vouchers', 'list') },
      ];
      // tslint:enable: max-line-length
      console.log('TCL: NavService -> constructor -> MENUITEMS', MENUITEMS);

      // this.items = new BehaviorSubject<Menu[]>(MENUITEMS);

      this.items.next(MENUITEMS);

      console.log('TCL: NavService -> constructor -> this.items', this.items);
    });

    // this.createMenuItems();
  }
  public screenWidth: any;
  public openToggle = false;
  public hotelInfo: Unit = null;

  public currentUnit$: BehaviorSubject<Unit> = new BehaviorSubject<Unit>(null);

  public items: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>([]);

  public units: Unit[] = [];

  // Windows width
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  setCurrentUnit(unitCode: string, oldHotel?: number, units?: Unit[]) {
    if (!oldHotel) {
      oldHotel = parseInt(window.localStorage.getItem('currentHotel'), 10);
    }
    if (!units) {
      units = this.units || [];
    }
    console.log('DEBUG: NavService -> setCurrentUnit -> unitCode', unitCode);
    this.hotelInfo = units.find(u => u.code === unitCode) || new Unit({ code: unitCode, hotelCode: oldHotel });
    if (!this.hotelInfo || !this.hotelInfo.code) {
      const oldUnit = window.localStorage.getItem('currentUnit');
      const oldHotel = window.localStorage.getItem('currentHotel');
      if (oldUnit && oldHotel) {
        this.setCurrentUnit(oldUnit, parseInt(oldHotel, 10));
      } else {
        this.hotelInfo = units[0];
      }
    }
    if (this.currentUnit$.getValue()) {
      this.router.navigateByUrl(this.router.url.replace(this.currentUnit$.getValue().code, unitCode));
    }
    window.localStorage.setItem('currentUnit', this.hotelInfo.code);
    window.localStorage.setItem('currentHotel', this.hotelInfo.hotelCode.toString());
    this.currentUnit$.next(this.hotelInfo);
  }

  async getUnitsArray() {
    this.units = await this.unitsService.getAll();
    return this.units;
  }

  canDelete(path) {
    return this.checkPermission(path, 'delete');
  }

  canSync() {
    return this.checkPermission('residents', 'sync');
  }

  checkPermission(path, action) {
    const user = this.API.loggedUser$.getValue();
    if (PERMISSIONS[path] && PERMISSIONS[path][action] && user) {
      return PERMISSIONS[path][action].includes(user.role);
    }
    return false;
  }
}
