import { Component, OnInit } from '@angular/core';
import { Unit } from 'src/app/entities/units/unit.entity';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NavService } from 'src/app/shared/service/nav.service';
import Swal from 'sweetalert2';
import { DEFAULT_AVATAR, User } from 'src/app/entities/residents/user.entity';
import { CleaningType } from 'src/app/entities/cleaning/cleaning-type.entity';
import { CleaningService } from '../cleaning.service';

@Component({
  selector: 'app-details-cleaning-type',
  templateUrl: './details-cleaning-type.component.html',
  styleUrls: ['./details-cleaning-type.component.scss']
})
export class DetailsCleaningTypeComponent implements OnInit {

  type: CleaningType = new CleaningType();
  hotelInfo: Unit;
  loading = false;
  constructor(
    public service: CleaningService,
    public router: Router,
    public route: ActivatedRoute,
    public navService: NavService,
    public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.hotelInfo = this.navService.hotelInfo;
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.type.id = params.id;
        this.getData(params.id);
      } else {
        this.router.navigate(['error', '404']);
      }
    });
  }

  getData(code: number) {
    this.loading = true;
    this.service.readType(code).then((type) => {
      this.type = new CleaningType(type);
      this.loading = false;
  }).catch((err) => {
      this.loading = false;
      console.error('Erro ao ler cleaning type', err);
      Swal.fire({
        title: 'Ops, ocorreu um erro',
        text: 'Por favor, tente novamente ou contate a equipe de desenvolvimento',
        showConfirmButton: true,
        confirmButtonText: 'Ok',
        showCloseButton: true,
        type: 'error'
      });
    });
  }

  clickDelete(id: number) {
    Swal.fire({
      title: 'Deseja mesmo excluir este serviço?',
      showConfirmButton: true,
      confirmButtonText: 'Sim, excluir',
      confirmButtonClass: 'btn btn-danger',
      showCancelButton: true,
      cancelButtonClass: 'btn btn-secondary',
      cancelButtonText: 'Cancelar',
      showCloseButton: true,
      type: 'warning',
    }).then(resp => {
     if (resp.value) {
        this.deleteType(id);
     }
    });
  }

  deleteType(id: number) {
    this.service.deleteType(id).then(del => {
      Swal.fire({
        type: 'success',
        text: 'Serviço extra ' + id + ' deletado com sucesso.',
        titleText: 'Excluído.'
      }).then(resp => {
        this.goToList();
      });
    }).catch(err => {
      Swal.fire({
        type: 'error',
        titleText: 'Erro ao excluir.',
        text: 'Por favor, tente novamente.',
      });
    });
  }

  goToList() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'cleaning',  'cleaning-types']);
  }

}
