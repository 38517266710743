import { RestEntity } from '../rest.entity';
import { User } from '../residents/user.entity';
import { Unit } from '../units/unit.entity';
import { Cleaning } from '../cleaning/cleaning.entity';

export class Area extends RestEntity<Area> {

  unit?: Unit;

  name?: string;

  cleanings?: Cleaning[];

}
