import { Component, OnInit, Input } from '@angular/core';
import * as chartJs from 'chart.js';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ChartColors } from '../chart-colors';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {
  constructor(
    public router: Router
  ) { }
  @Input() public options: chartJs.ChartOptions = {
    responsive: true,
    scales: ({
      ticks: {
        suggestedMin: 0,
        precision: 0,
        beginAtZero: true,
      }
    } as chartJs.LinearScale)
  };
  @Input() public title = '';
  @Input() public canvasId: string;
  @Input() public groups = [];
  @Input() public labels = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  @Input() public type = 'line';
  @Input() public legend = true;
  @Input() public datasets = [
    {data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A'},
    {data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B'}
  ];

  @Input() public colors = [
    ChartColors.yellow,
    ChartColors.purple,
    ChartColors.blue,
    ChartColors.pink,
    ChartColors.green,
  ];

  ngOnInit() {
  }

}
