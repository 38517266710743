import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Unit } from 'src/app/entities/units/unit.entity';
import { Router, ActivatedRoute } from '@angular/router';
import { NavService } from 'src/app/shared/service/nav.service';
import Swal from 'sweetalert2';
import { IssueCategoriesService } from '../issue-categories.service';
import { CleaningService } from '../../cleaning/cleaning.service';
import { IssueCategory } from '../issue-category.entity';
import { MaintenanceType, MaintenanceTypeTranslated, maintenanceTypeTranslator } from '../../maintenances/maintenance.service';

@Component({
  selector: 'app-issue-category-form',
  templateUrl: './issue-category-form.component.html',
  styleUrls: ['./issue-category-form.component.scss']
})
export class IssueCategoryFormComponent implements OnInit {

  form: FormGroup;
  issueCategory: IssueCategory = new IssueCategory();
  loading = false;
  errorMessage: string;
  id: number = undefined;

  types = Object.keys(MaintenanceType).map(k => ({ name: maintenanceTypeTranslator(k as MaintenanceType), value: k }));

  constructor(
    private formBuilder: FormBuilder,
    public service: IssueCategoriesService,
    public router: Router,
    public route: ActivatedRoute,
    public navService: NavService,
    public cleaningService: CleaningService) {
    }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (params.issueCategoryId) {
        this.id = params.issueCategoryId;
        this.initCreateForm();
        this.initUpdateForm();
      } else {
        this.initCreateForm();
      }
    });
  }

  initCreateForm() {
    this.form = this.formBuilder.group({
      name: [null, [Validators.required, Validators.minLength(1)]],
      type: [null, [Validators.required]],
    });
  }

  initUpdateForm() {
    this.loading = true;
    this.service.read(this.id).then(issueCategory => {
      this.loading = false;
      this.errorMessage = null;

      this.issueCategory = issueCategory;

      this.form.patchValue({
        ['name']: issueCategory.name,
        ['type']: issueCategory.type,
      });

    }, _err => {
      this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
    });
  }

  submit() {
    if (this.id) {
      this.update();
    } else {
      this.create();
    }
  }

  create() {
    this.loading = true;
    this.issueCategory = new IssueCategory(this.form.value);
    this.service.create(this.issueCategory).then(issueCategory => {
      this.successNew(issueCategory);
      this.loading = false;
      this.errorMessage = null;
    }, err => {
      console.error('Error in create issueCategory component', err);
      this.loading = false;
      if (err.statusCode === 400) {
        this.errorMessage = 'Ops! Verifique os campos abaixo';
        if (err.message && err.message.length > 0) {
          for (const e of err.message) {
            this.form.get(e.property).setErrors({
              error: true
            });
          }
        }
      } else {
        this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
      }
    });
  }

  update() {
    this.loading = true;
    console.log(this.issueCategory);
    const issueCategory = new IssueCategory({
      name: this.form.value.name,
      type: this.form.value.type,
    });
    this.service.update(issueCategory, this.id).then(_issueCategory => {
      this.successEdit();
      this.loading = false;
      this.errorMessage = null;
    }, err => {
      console.error('Error in update issueCategory component', err);
      this.loading = false;
      if (err.statusCode === 400) {
        this.errorMessage = 'Ops! Verifique os campos abaixo';
        if (err.message && err.message.length > 0) {
          for (const e of err.message) {
            this.form.get(e.property).setErrors({
              error: true
            });
          }
        }
      } else {
        this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
      }
    });
  }


  showError(field?: string) {
    // console.log('DEBUG: IssueCategoryFormComponent -> showError -> field', field);
    return (this.form.get(field).invalid && this.form.get(field).touched) ||
           (!this.form.get(field).touched && this.errorMessage && this.errorMessage.length > 0 && this.form.get(field).invalid );
  }

  successNew(issueCategory: IssueCategory) {
    Swal.fire({
      title: 'Subcategoria ' + this.issueCategory.name + ' cadastrada',
      type: 'success',
      showConfirmButton: true,
      confirmButtonText: 'Ir para subcategoria',
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary float-left',
      cancelButtonText: 'Cadastrar outra',
      showCloseButton: true,
      onClose: () => this.goToList(),
    }).then(resp => {
      if (resp.value) {
        this.goToDetails(issueCategory.id);
      } else {
        this.goToForm();
      }
    });
  }

  successEdit() {
    return Swal.fire({
      title: 'Subcategoria editada',
      type: 'success',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary',
      cancelButtonText: 'Voltar',
      showCloseButton: true,
      onClose: () => {
        this.goToList();
      },
    });
  }

  clickDelete(id: number) {
    Swal.fire({
      title: 'Deseja mesmo excluir esta subcategoria?',
      text: 'As ordens de serviço associadas a ela perderão essa informação',
      showConfirmButton: true,
      confirmButtonText: 'Sim, excluir',
      confirmButtonClass: 'btn btn-danger',
      showCancelButton: true,
      cancelButtonClass: 'btn btn-secondary',
      cancelButtonText: 'Cancelar',
      showCloseButton: true,
    }).then(resp => {
      if (resp.value) {
        this.delete(id);
      }
    });
  }

  delete(id: number) {
    this.service
      .delete(id)
      .then(del => {
        Swal.fire({
          type: 'success',
          text: `Subcategoria ${id} deletada com sucesso.`,
          titleText: 'Excluído.',
        }).then(resp => {
          this.goToList();
        });
      })
      .catch(err => {
        Swal.fire({
          type: 'error',
          titleText: 'Erro ao excluir.',
          text: 'Por favor, tente novamente.',
        });
      });
  }

  goToList() {
    this.router.navigate(['/config', 'maintenance', 'subcategories']);
  }
  goToDetails(id: number) {
    this.router.navigate(['/config', 'maintenance', 'subcategories', id, 'edit']);
  }

  goToForm() {
    this.router.navigate(['/config', 'maintenance', 'subcategories', 'create']);
  }

}
