import { Space } from './space.entity';
import { User } from '../residents/user.entity';
import { Guest } from '../residents/guest.entity';
import { RestEntity, DeepPartial } from '../rest.entity';

export enum ScheduleStatus {
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED',
  FINISHED = 'FINISHED',
}

export enum AttendanceStatus {
  INVITE = 'INVITE',
  IN = 'IN',
  OUT = 'OUT',
}

export interface Attendance {
  id?: number;
  status?: AttendanceStatus;
  event?: any;
  schedule?: Schedule;
  user?: User;
  fromUser?: User;
  presence?: boolean;
}

export class Schedule extends RestEntity<Schedule> {

  constructor(obj?: DeepPartial<Schedule>) {
    super(obj);
    if (obj && obj.startDate) {
      this.startDate = new Date(String(obj.startDate));
    }
    if (obj && obj.endDate) {
      this.endDate = new Date(String(obj.endDate));
    }
    if (obj && obj.user) {
      this.user = new User(obj.user);
    }
    if (obj && obj.space) {
      this.space = new Space(obj.space);
    }

  }
  space?: Space;

  user?: User;

  startDate?: Date;

  endDate?: Date;

  status?: ScheduleStatus;

  attendances?: Attendance[];

  event?: any;
}
