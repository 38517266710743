import { RestEntity } from '../entities/rest.entity';
import { OccurrenceType } from '../occorrences/occurrence.service';

export enum OccurrenceCategorySortOptions {
  CATEGORY = 'CATEGORY',
  CATEGORYINVERSE = 'CATEGORYINVERSE',
  SUBCAT = 'SUBCAT',
  SUBCATINVERSE = 'SUBCATINVERSE',
}

export class OccurrenceCategory extends RestEntity<OccurrenceCategory> {
  type?: OccurrenceType;
  name?: string;
}
