import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Unit } from 'src/app/entities/units/unit.entity';
import { Router, ActivatedRoute } from '@angular/router';
import { NavService } from 'src/app/shared/service/nav.service';
import Swal from 'sweetalert2';
import { UnitsService } from '../units.service';
import { DeepPartial } from '../../entities/rest.entity';

@Component({
  selector: 'app-units-form',
  templateUrl: './units-form.component.html',
  styleUrls: ['./units-form.component.scss'],
})
export class UnitsFormComponent implements OnInit {
  form: FormGroup;
  unit: Unit = new Unit();
  loading = false;
  errorMessage: string;
  errors: DeepPartial<Unit> = {};
  hotelCode: number;
  uploadForm: FormGroup;
  isEditForm = false;
  constructor(
    private formBuilder: FormBuilder,
    public service: UnitsService,
    public router: Router,
    public route: ActivatedRoute,
    public navService: NavService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.hotelCode = params.hotelCode;

      if(this.route.routeConfig.data.action === "update") {
        this.isEditForm = true
      }

      if (params.hotelCode) {
        this.initCreateForm();
        this.initUpdateForm();
      } else {
        this.initCreateForm();
      }
    });
    this.uploadForm = this.formBuilder.group({
      file: [''],
    });
  }

  initCreateForm() {
    this.form = this.formBuilder.group(
      {
        name: [null, [Validators.minLength(2)]],
        code: [null, [Validators.required, Validators.minLength(2)]],
        hotelCode: new FormControl({value: null, disabled: this.isEditForm}, [Validators.required, Validators.min(1)]),
        address: [null, [Validators.min(3)]],
        morningStart: [null],
        morningEnd: [null],
        afternoonStart: [null],
        afternoonEnd: [null],
        nightStart: [null],
        nightEnd: [null],
      },
      {
        validators: [],
      },
    );
  }

  initUpdateForm() {
    this.loading = true;
    this.service.read(this.hotelCode).then(
      unit => {
        this.loading = false;
        this.errorMessage = null;

        this.unit = unit;

        this.form.patchValue({
          ['name']: unit.name,
          ['code']: unit.code,
          ['hotelCode']: unit.hotelCode,
          ['address']: unit.address,
          ['morningStart']: unit.morningStart,
          ['morningEnd']: unit.morningEnd,
          ['afternoonStart']: unit.afternoonStart,
          ['afternoonEnd']: unit.afternoonEnd,
          ['nightStart']: unit.nightStart,
          ['nightEnd']: unit.nightEnd,
        });
      },
      _err => {
        this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
      },
    );
  }

  submit() {
    const isHoursValid = this.validateHours();
    if (!isHoursValid) {
      return;
    }

    if (this.hotelCode) {
      return this.update();
    }

    return this.create();
  }

  validateHours() {
    const morningStart = this.form.value.morningStart;
    const morningEnd = this.form.value.morningEnd;
    const afternoonStart = this.form.value.afternoonStart;
    const afternoonEnd = this.form.value.afternoonEnd;

    if (morningStart && !morningEnd) {
      Swal.fire({
        titleText: 'Horários de funcionamento',
        text: 'É necessário informar o encerramento do período da manhã',
        type: 'error',
      });
      return false;
    }

    if (!morningStart && morningEnd) {
      Swal.fire({
        titleText: 'Horários de funcionamento',
        text: 'É necessário informar o início do período da manhã',
        type: 'error',
      });
      return false;
    }

    if (afternoonStart && !afternoonEnd) {
      Swal.fire({
        titleText: 'Horários de funcionamento',
        text: 'É necessário informar o encerramento do período da tarde',
        type: 'error',
      });
      return false;
    }

    if (!afternoonStart && afternoonEnd) {
      Swal.fire({
        titleText: 'Horários de funcionamento',
        text: 'É necessário informar o início do período da tarde',
        type: 'error',
      });
      return false;
    }

    return true;
  }

  create() {
    this.loading = true;
    this.unit = new Unit(this.form.value);

    this.service.create(this.clearBlanks(this.unit)).then(
      unit => {
        this.successNew(unit);
        this.loading = false;
        this.errorMessage = null;
      },
      err => {
        console.error('Error in create unit component', err);
        this.loading = false;
        if (err.statusCode === 400) {
          this.errorMessage = 'Ops! Verifique os campos abaixo';
        } else {
          this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
        }
      },
    );
  }

  update() {
    this.loading = true;
    const unit = this.clearBlanks(new Unit(this.form.value));
    this.service.update(unit, this.hotelCode).then(
      _unit => {
        this.successEdit();
        this.loading = false;
        this.errorMessage = null;
        this.errors = {};
      },
      err => {
        console.error('Error in update unit component', err);
        this.loading = false;
        if (err.statusCode === 400) {
          this.errorMessage = 'Ops! Verifique os campos abaixo';
          err.message.forEach(e => {
            this.errors[e.property] = true;
          });
        } else if (err.statusCode === 409) {
          this.errorMessage = 'Este email já está registrado para outro usuário. Por favor, escolha outro.';
        } else {
          this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
        }
      },
    );
  }

  showError(field?: string) {
    return (this.form.get(field).invalid && this.form.get(field).touched) || this.errors[field];
  }

  successNew(unit: Unit) {
    Swal.fire({
      title: 'Unidade ' + this.unit.name + ' criada!',
      type: 'success',
      showConfirmButton: true,
      confirmButtonText: 'Ir para Unidade',
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary float-left',
      cancelButtonText: 'Cadastrar outra',
      showCloseButton: true,
      onClose: () => this.goToList(),
    }).then(resp => {
      if (resp.value) {
        this.goToDetails(unit.code);
      } else {
        this.goToForm();
      }
    });
  }

  successEdit() {
    return Swal.fire({
      title: 'Unidade editada!',
      type: 'success',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary',
      cancelButtonText: 'Voltar',
      showCloseButton: true,
      onClose: () => {},
    });
  }

  goToList() {
    this.router.navigate(['units']);
  }
  goToDetails(code: string) {
    this.router.navigate(['/unit', code]);
  }

  goToForm() {
    this.router.navigate(['units', this.unit.hotelCode, 'edit']);
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      Swal.fire({
        titleText: 'Deseja atualizar o regulamento com este arquivo?',
        text: file.name,
        type: 'question',
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
      }).then(res => {
        if (res.value) {
          this.uploadFile(file);
        }
      });
    }
  }

  uploadFile(file: any) {
    this.service.updateDocument(this.unit.hotelCode, file).then(
      res => {
        console.log('Upload feito', res);
        Swal.fire({
          type: 'success',
          text: 'Regulamento atualizado.',
          titleText: 'Feito!',
        });
        this.uploadForm.reset();
        this.unit.documentUrl = res.documentUrl;
      },
      err => {
        console.error('Erro no upload', err);
        if (err.statusCode === 413) {
          Swal.fire({
            titleText: 'Arquivo muito grande!',
            text: 'Por favor, envie imagens com menos de 100MB',
            type: 'error',
          });
          this.uploadForm.reset();
        } else {
          Swal.fire({
            titleText: 'Ops!',
            text: 'Ocorreu um erro. Por favor, tente novamente ou entre em contato com a equipe de desenvolvimento',
            type: 'error',
          });
        }
      },
    );
  }

  clearBlanks(data: Unit) {
    const unit = new Unit(data);
    unit.morningStart = unit.morningStart || null;
    unit.morningEnd = unit.morningEnd || null;
    unit.afternoonStart = unit.afternoonStart || null;
    unit.afternoonEnd = unit.afternoonEnd || null;
    unit.nightStart = unit.nightStart || null;
    unit.nightEnd = unit.nightEnd || null;
    return unit;
  }
}
