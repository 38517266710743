import { Injectable } from '@angular/core';
import { Unit } from '../entities/units/unit.entity';
import { APIService, APIPaginationdResponse } from '../api.service';

export interface Voucher {
  id?: number;
  title?: string;
  description?: string;
  partnerName?: string;
  logoUrl?: string;
  unit?: Unit;
  buttonUrl?: string;
  priority?: number;
  showToAllUnits?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class VouchersService {
  constructor(private API: APIService) {}

  async loadUnitVouchers(page: number = 1, limit: number = 100): Promise<APIPaginationdResponse<Voucher>> {
    const response = await this.API.get<APIPaginationdResponse<Voucher>>('/admin/voucher', {
      page,
      limit,
    }, {tokenRequired: true});
    return {...response, data: response.data};
  }

  async loadVoucherById(voucherId: number): Promise<Voucher> {
    return await this.API.get(`/admin/voucher/${voucherId}`, {}, { tokenRequired: true });
  }

  async loadAllUnits(): Promise<Unit[]> {
    return await this.API.get(`/units/getAll`, {}, { tokenRequired: true });
  }

  async createNewVoucher(formData: { unit: Unit; title: string; description: string; buttonUrl: string; priority?: number;}, file?: any): Promise<Voucher> {
    let voucherData: Voucher | FormData;

    if (file) {
      const form = new FormData();
      form.append('file', file);
      form.append('unit', String(formData.unit));
      form.append('description', formData.description);
      form.append('buttonUrl', formData.buttonUrl);
      form.append('title', formData.title);
      form.append('priority', String(formData.priority));
      voucherData = form;
    } else {
      voucherData = formData;
    }

    return this.API.post<Voucher | FormData, Voucher>('/admin/voucher', voucherData, { tokenRequired: true });
  }

  async editVoucher(voucherId: number, formData: { unit?: Unit; title?: string; description?: string; buttonUrl?: string; priority?: number;}, file?: any) {
    let newVoucherData: Voucher | FormData;

    if (file) {
      const form = new FormData();
      form.append('file', file);
      form.append('unit', String(formData.unit));
      form.append('description', formData.description);
      form.append('buttonUrl', formData.buttonUrl);
      form.append('title', formData.title);
      form.append('priority', String(formData.priority));
      newVoucherData = form;
    } else {
      newVoucherData = formData;
    }

    return await this.API.patch(`/admin/voucher/${voucherId}`, newVoucherData, { tokenRequired: true });
  }

  async deleteVoucher(voucherId: number) {
    if (!voucherId) {
      throw new Error('A voucher id must be informed');
    }

    return await this.API.delete(`/admin/voucher/${voucherId}`, {}, { tokenRequired: true });
  }
}
