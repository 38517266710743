import { Component, OnInit } from '@angular/core';
import { Employee } from 'src/app/entities/employees/employee.entity';
import { EmployeesService } from '../employees.service';
import { ActivatedRoute } from '@angular/router';
import { UserRole } from 'src/app/entities/residents/user.entity';
import { NavService } from 'src/app/shared/service/nav.service';
import { Unit } from '../../entities/units/unit.entity';
import { ResidentsService } from 'src/app/residents/residents.service';

@Component({
  selector: 'app-list-employees',
  templateUrl: './list-employees.component.html',
  styleUrls: ['./list-employees.component.scss']
})
export class ListEmployeesComponent implements OnInit {

  employees: Employee[] = [];
  searchTerm = '';
  loading = true;
  hotelInfo: Unit;
  role: string;

  pagination = {
    page: 1,
    limit: 25,
    pages: 5,
    collectionSize: 100,
  };

  constructor(
    public employeesSrvc: EmployeesService,
    public route: ActivatedRoute,
    public navService: NavService,
    public residentsSrvc: ResidentsService,
  ) {
  }

  ngOnInit() {
    this.route.parent.params.subscribe((params) => {
      this.role = params.role;
      this.hotelInfo = this.navService.hotelInfo;

      this.getAll();
    });
    this.navService.currentUnit$.subscribe((unit) => {
      this.hotelInfo = unit;

      this.getAll();
    });
  }

  async getAll() {
    this.loading = true;
    if (!this.hotelInfo) {
      this.loading = false;
      return;
    }

    const resp = await this.employeesSrvc.search(this.hotelInfo.hotelCode, '', this.role)
      .catch((e) => {
        alert('Erro ao buscar funcionários');
        this.loading = false;
        console.error(e);
        return {data: [], totalPages: 0};
      });

    this.employees = resp.data;
    this.pagination.pages = resp.totalPages;
    this.pagination.collectionSize = resp.totalPages * this.pagination.limit;
    this.loading = false;
  }

  async search() {
    this.loading = true;
    if (!this.hotelInfo) {
      this.loading = false;
      return;
    }

    const resp = await this.employeesSrvc.search(this.hotelInfo.hotelCode, this.searchTerm, this.role)
      .catch((e) => {
        alert('Erro ao buscar funcionários');
        this.loading = false;
        console.error(e);
        return {data: [], totalPages: 0};
      });

    this.employees = resp.data;
    this.pagination.pages = resp.totalPages;
    this.pagination.collectionSize = resp.totalPages * this.pagination.limit;
    this.loading = false;
  }
}
