import { Injectable } from '@angular/core';
import { APIService, APIPaginationdResponse } from '../api.service';
import { User } from '../entities/residents/user.entity';
import { UserEvent } from '../events/event.entity';
import { Unit } from '../entities/units/unit.entity';
import { Guest } from '../entities/residents/guest.entity';
import { Employee } from '../entities/employees/employee.entity';

export interface Visitor {
  id?: number;
  createdDate?: string | Date;
  updatedDate?: string | Date;
  email?: string;
  name?: string;
  cpf?: string;
  rg?: string;
  address?: string;
  visits?: Visits[];
  unit?: Unit;
  photoUrl?: string;
}

export interface Visits {
  id?: number;
  createdDate?: string | Date;
  updatedDate?: string | Date;
  uuid?: string;
  status?: VisitStatus;
  startDate?: string | Date;
  endDate?: string | Date;
  entryDate?: string | Date;
  predictedLeaveDate?: string | Date;
  realLeaveDate?: string | Date;
  overnightStay?:boolean;
  user?: User;
  guest?: Guest;
  visitor?: Visitor;
  event?: UserEvent;
  visitType?: VisitType;
  employee?: Employee;
}

export enum VisitSorting {
  USER = 'USER',
  VISITOR = 'VISITOR',
  START = 'START',
  END = 'END',
  ENTRY = 'ENTRY',
  LEAVEPREDICITION = 'LEAVEPREDICTION',
  REALLEAVE = 'REALLEAVE',
  CREATED = 'CREATED',
}

export enum VisitType {
  GUEST = 'GUEST',
  COMMERCIAL_LEAD = 'COMMERCIAL_LEAD',
  MAINTENANCE_SUPLIER = 'MAINTENANCE_SUPLIER',
}

export const translatedVisitType = [
  { type: VisitType.GUEST, name: "Morador" },
  { type: VisitType.COMMERCIAL_LEAD, name: "Lead comercial"},
  { type: VisitType.MAINTENANCE_SUPLIER, name: "Fornecedor de manutenção" }
];

export interface SortingOptionsType {
  label?: string;
  value?: VisitSorting;
  order?: 'ASC' | 'DESC';
}

export const visitSortingOptions: SortingOptionsType[] = [
  { label: 'Por morador (A-z)', value: VisitSorting.USER, order: 'ASC' },
  { label: 'Por morador (z-A)', value: VisitSorting.USER, order: 'DESC' },
  { label: 'Por visitante (A-z)', value: VisitSorting.VISITOR, order: 'ASC' },
  { label: 'Por visitante (z-A)', value: VisitSorting.VISITOR, order: 'DESC' },
  { label: 'Fim da visita (mais antigas)', value: VisitSorting.END, order: 'ASC' },
  { label: 'Fim da visita (mais novas)', value: VisitSorting.END, order: 'DESC' },
  { label: 'Data de entrada (mais antigas)', value: VisitSorting.ENTRY, order: 'ASC' },
  { label: 'Data de entrada (mais novas)', value: VisitSorting.ENTRY, order: 'DESC' },
  { label: 'Data prevista de saída (mais antigas)', value: VisitSorting.LEAVEPREDICITION, order: 'ASC' },
  { label: 'Data prevista de saída (mais novas)', value: VisitSorting.LEAVEPREDICITION, order: 'DESC' },
  { label: 'Data real de saída (mais antigas)', value: VisitSorting.REALLEAVE, order: 'ASC' },
  { label: 'Data real de saída (mais novas)', value: VisitSorting.REALLEAVE, order: 'DESC' },
  { label: 'Data criada (mais antigas)', value: VisitSorting.CREATED, order: 'ASC' },
  { label: 'Data criada (mais novas)', value: VisitSorting.CREATED, order: 'DESC' },
];

export enum DateFilterParam {
  CREATEDATE = 'CREATEDATE',
  STARTDATE = 'STARTDATE',
  ENTRYDATE = 'ENTRYDATE',
  PREDICTEDLEAVEDATE = 'PREDICTEDLEAVEDATE',
  REALLEAVEDATE = 'REALLEAVEDATE',
}

export enum VisitStatus {
  ALL = 'ALL',
  VISITOR_UNREGISTERED = 'VISITOR_UNREGISTERED',
  PENDING_VISIT = 'PENDING_VISIT',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
  CONFIRMED = 'CONFIRMED',
  LATELEAVE = 'LATELEAVE',
  OVERNIGHT = 'OVERNIGHT',
}

// translate = {
//   VISITOR_UNREGISTERED: 'Não registrado',
//   PENDING_VISIT: 'Agendado',
//   CONFIRMED: 'Autorizado',
//   DONE: 'Finalizada',
//   CANCELLED: 'Cancelada',
// };

export const visitStatusOptions = [
  { label: 'Todos', value: VisitStatus.ALL },
  //{ label: 'Não registrado', value: VisitStatus.VISITOR_UNREGISTERED },
  { label: 'Agendado', value: VisitStatus.PENDING_VISIT },
  { label: 'Autorizado', value: VisitStatus.CONFIRMED },
  { label: 'Finalizada', value: VisitStatus.DONE },
  { label: 'Cancelada', value: VisitStatus.CANCELLED },
  { label: 'Saída atrasada', value: VisitStatus.LATELEAVE },
];

@Injectable({
  providedIn: 'root',
})
export class VisitsService {
  constructor(private API: APIService) {}

  async completeRegistration(visitorData: Visitor) {
    return await this.API.patch('/visitors/completeRegistration', visitorData, { hideLoading: true });
  }

  async getVisitsByUnit(
    hotelCode,
    page,
    limit,
    orderByEntity?,
    order: 'ASC' | 'DESC' = 'DESC',
    status?: VisitStatus,
    dateEntity?: DateFilterParam,
    startDate?: any,
    endDate?: any,
  ) {
    const params = { hotelCode, page, limit, orderByEntity, order };

    if (status) {
      Object.assign(params, { status });
    }

    if (dateEntity && startDate && endDate) {
      Object.assign(params, { dateEntity, startDate, endDate });
    }

    return await this.API.get<APIPaginationdResponse<Visits>>(`/visits`, params, { tokenRequired: true });
  }

  async getUnitVisitsReport(hotelCode: number): Promise<{ total: number; confirmed: number; present: number; lateLeave: string }> {
    return this.API.get(`/visits/reports/${hotelCode}`, {}, { tokenRequired: true, hideLoading: true });
  }

  async getAllVisitors(hotelCode: number, page, limit) {
    return await this.API.get<APIPaginationdResponse<Visitor>>(`/visitors`, { page, limit, hotelCode: String(hotelCode) }, { tokenRequired: true });
  }

  async readVisit(id) {
    return await this.API.get<Visits>(`/visits/${id}`, {}, { tokenRequired: true });
  }

  async getById(id: number) {
    return await this.API.get<Visitor>('/visitors/getByEmail', { id }, { hideLoading: true });
  }

  async readVisitor(id) {
    return await this.API.get<Visitor>(`/visitors/${id}`, { join: ['visits', 'user', 'user.guest'] }, { tokenRequired: true });
  }

  async confirmLeave(uuid: string) {
    return this.API.patch(`/visits/confirmLeave/${uuid}`, {}, { tokenRequired: true });
  }

  async confirmEntry(uuid: string) {
    return this.API.patch(`/visits/confirmEntry/${uuid}`, {}, { tokenRequired: true });
  }

  async deleteVisit(visitId: number) {
    return this.API.delete(`/visits/${visitId}`, {}, { tokenRequired: true, hideLoading: true });
  }

  async updateVisit(visitId: number, payload: Visits) {
    return this.API.patch(`/visits/${visitId}`, payload, { tokenRequired: true, hideLoading: true });
  }

  async createVisitor(payload: Visitor, file?: any) {
    let visitorData: Visitor | FormData;

    if (file) {
      const form = new FormData();
      form.append('file', file);
      form.append('name', payload.name);
      form.append('cpf', String(payload.cpf));

      visitorData = form;
    } else {
      visitorData = payload;
    }

    return this.API.post('/visitors', visitorData, { tokenRequired: true, hideLoading: true });
  }

  async updateVisitor(id, payload?: Visitor, file?: any) {
    let visitorData: Visitor | FormData;

    if (file) {
      const form = new FormData();
      form.append('file', file);

      if (payload.name) {
        form.append('name', payload.name);
      }

      if (payload.cpf) {
        form.append('cpf', String(payload.cpf));
      }

      visitorData = form;
    } else {
      visitorData = payload;
    }

    return this.API.patch(`/visitors/${id}`, visitorData, { tokenRequired: true, hideLoading: true });
  }

  async createVisit(payload: Visits) {
    return this.API.post('/visits', payload, { tokenRequired: true, hideLoading: true });
  }
}
