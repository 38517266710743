import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Unit } from '../../entities/units/unit.entity';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Space } from 'src/app/entities/spaces/space.entity';
import { Router, ActivatedRoute } from '@angular/router';
import { NavService } from 'src/app/shared/service/nav.service';
import { Schedule, Attendance } from '../../entities/spaces/schedule.entity';
import { ResidentsService } from '../../residents/residents.service';
import { Guest } from '../../entities/residents/guest.entity';
import { AuthService } from 'src/app/auth.service';
import Swal from 'sweetalert2';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { addHours } from 'date-fns';
import { User } from 'src/app/entities/residents/user.entity';
import { UserEvent } from '../event.entity';
import { EventsService } from '../events.service';
import { UnitsService } from '../../unit/units.service';
import { SpacesService } from '../../unit/spaces.service';
import { SchedulesService } from '../../unit/schedules.service';

@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.scss']
})
export class EventCalenderFormComponent implements OnInit, OnChanges {

  form: FormGroup;
  spaces: Space[] = [];
  residents: Guest[] = [];
  attendants: Attendance [] = [];
  schedules: Schedule[] = [];
  event: UserEvent = new UserEvent();
  attendees: User[];
  adminId: number = null;
  loading = false;
  errorMessage: string;
  hotelInfo: Unit;

  @Input() id: number = undefined;
  @Input() isModal = false;
  constructor(
    private formBuilder: FormBuilder,
    public spacesService: SpacesService,
    public schedulesService: SchedulesService,
    public eventsService: EventsService,
    public unitsService: UnitsService,
    public residentsService: ResidentsService,
    public authService: AuthService,
    public router: Router,
    public route: ActivatedRoute,
    public navService: NavService,
    public modal: NgbActiveModal) {
  }

  closeModal() {
    this.modal.close({updated: true});
  }

  async ngOnInit() {
    this.initCreateForm();
    this.hotelInfo = this.navService.hotelInfo;

    this.loading = true;
    const [schedules, allResidents, spacesRes] = await Promise.all([
      this.schedulesService.getFutureFromUnit(this.hotelInfo.hotelCode),
      this.residentsService.getAllGuests(this.hotelInfo.hotelCode),
      this.unitsService.read(this.hotelInfo.hotelCode),
    ]);
    this.loading = false;

    this.spaces = spacesRes.spaces;
    this.schedules = schedules;
    this.residents = allResidents.data.filter(r => r.user ? true : false);

    this.route.params.subscribe((params) => {

      if (params.eventId) {
        this.id = params.eventId;
        this.initUpdateForm();
      } else {
      }
    });

    if (this.id) {
      this.initUpdateForm();
    }

    this.authService.getLoggedProfile().subscribe(user => {
      this.adminId = user.id;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('DEBUG: ScheduleFormComponent -> ngOnChanges -> changes.id.currentValue', changes.id);
    if (changes.id.currentValue !== changes.id.previousValue) {
      this.initUpdateForm();
    }
  }

  initCreateForm() {
    this.form = this.formBuilder.group({
      scheduleId: [null],
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      userId: [null, [Validators.required]],
      private: [false],
      title: [null, Validators.required],
      description: [null, Validators.required]
    }, {
      validators: [
        this.checkDates,
      ]
    });
  }

  initUpdateForm() {
    this.loading = true;
    this.eventsService.read(this.id, {}).then(event => {
      this.loading = false;
      this.errorMessage = null;

      this.event = new UserEvent(event);
      this.event.startDate = addHours(this.event.startDate, -3);
      this.event.endDate = addHours(this.event.endDate, -3);

      this.form.patchValue({
        ['scheduleId']: this.event.schedule ? this.event.schedule.id : null,
        ['startDate']: String(this.event.startDate.toISOString()).slice(0, 16),
        ['endDate']: String(this.event.endDate.toISOString()).slice(0, 16),
        ['userId']: this.event.user.id,
        ['private']: this.event.private,
        ['title']: this.event.title,
        ['description']: this.event.description,
      });
    }, _err => {
      this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
    });
  }

  checkDates(group: FormGroup) { // here we have the 'passwords' group
    const start = group.get('startDate');
    const end = group.get('endDate');
    if (start > end) {
      end.setErrors({wrongIntervalsRange: true});
    }
    return start < end ? null : { wrongIntervalsRange: true };
  }

  submit() {
    if (this.id) {
      this.update();
    } else {
      this.create();
    }
  }

  create() {
    this.loading = true;
    const startDate = new Date(this.form.value.startDate);
    const endDate = new Date(this.form.value.endDate);
    const startDateUTC =  Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate(),
                          startDate.getUTCHours(), startDate.getUTCMinutes(), startDate.getUTCSeconds());
    const endDateUTC =  Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate(),
                          endDate.getUTCHours(), endDate.getUTCMinutes(), endDate.getUTCSeconds());
    this.event = new UserEvent({
      startDate: new Date(startDateUTC).toISOString(),
      endDate: new Date(endDateUTC).toISOString(),
      title: this.form.value.title,
      private: this.form.value.private ? 1 : 0,
      description: this.form.value.description,
    });
    this.eventsService.create(this.event).then(event => {
      this.successNew(event);
      this.loading = false;
      this.errorMessage = null;
    }, err => {
      console.error('Error in create event component', err);
      this.loading = false;
      Swal.fire({
        type: 'error',
        title: 'Erro - ' + err.statusCode,
        text: err.message,
      });
    });
  }

  update() {
    this.loading = true;
    console.log(this.event);
    const event = new UserEvent({
      startDate: this.form.value.startDate,
      endDate: this.form.value.endDate,
      title: this.form.value.title,
      private: this.form.value.private ? 1 : 0,
      description: this.form.value.description,
      id: this.id,
    });
    this.eventsService.update(event, this.id).then(() => {
      this.successEdit();
      this.loading = false;
      this.errorMessage = null;
    }, err => {
      console.error('Error in update event component', err);
      this.loading = false;
      Swal.fire({
        type: 'error',
        title: 'Erro - ' + err.statusCode,
        text: err.message,
      });
    });
  }

  showError(field?: string) {
    // console.log('DEBUG: SpaceFormComponent -> showError -> field', field);
    return (this.form.get(field).invalid && this.form.get(field).touched) ||
           (!this.form.get(field).touched && this.errorMessage && this.errorMessage.length > 0 && this.form.get(field).invalid );
  }

  successNew(event: UserEvent) {
    Swal.fire({
      title: 'Evento criada!',
      type: 'success',
      showConfirmButton: true,
      confirmButtonText: 'Ok',
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary float-left',
      cancelButtonText: 'Cadastrar outra',
      showCloseButton: true,
      onClose: () => this.goToList(),
    }).then(resp => {
      if (this.isModal) {
        this.closeModal();
      } else {
        if (resp.value) {
          this.goToDetails(event.id);
        } else {
          this.goToForm();
        }
      }
    });
  }

  successEdit() {
    return Swal.fire({
      title: 'Evento editado!',
      type: 'success',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary',
      cancelButtonText: 'Voltar',
      showCloseButton: true,
      onClose: () => {},
    });
  }

  clickDelete(id: number) {
    Swal.fire({
      title: 'Deseja mesmo excluir este evento?',
      type: 'warning',
      showConfirmButton: true,
      confirmButtonText: 'Sim, excluir',
      confirmButtonClass: 'btn btn-danger',
      showCancelButton: true,
      cancelButtonClass: 'btn btn-secondary',
      cancelButtonText: 'Cancelar',
      showCloseButton: true,
    }).then(resp => {
     if (resp.value) {
        this.delete(id);
     }
    });
  }

  delete(id: number) {
    this.eventsService.delete(id).then(del => {
      Swal.fire({
        type: 'success',
        text: 'Evento ' + id + ' deletado com sucesso.',
        titleText: 'Excluído.'
      }).then(resp => {
        if (this.isModal) {
          this.closeModal();
        }
      });
    }).catch(err => {
      Swal.fire({
        type: 'error',
        titleText: 'Erro ao excluir.',
        text: 'Por favor, tente novamente.',
      });
    });
  }



  goToList() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'event']);
  }
  goToDetails(id: number) {
    this.router.navigate(['/unit', this.hotelInfo.code, 'event']);
  }
  goToForm() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'event', 'create']);
  }

}
