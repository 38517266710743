import { Component, OnInit } from '@angular/core';
import { Professional } from './professional.entity';
import { ProfessionalsService } from './professionals.service';

@Component({
  selector: 'app-professionals',
  templateUrl: './professionals.component.html',
  styleUrls: ['./professionals.component.scss']
})
export class ProfessionalsComponent implements OnInit {

  professionals: Professional[];
  searchTerm = '';
  loading = true;
  constructor(public professionalsService: ProfessionalsService) {
    this.search();
  }

  ngOnInit() {
  }

  search() {
    this.loading = true;
    this.professionalsService.search(this.searchTerm).subscribe(professionals => {
      this.professionals = professionals.map(p => new Professional(p));
      this.loading = false;
    });
  }

}
