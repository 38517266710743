import { APIService } from '../api.service';
import { Unit } from '../entities/units/unit.entity';
import { Injectable } from '@angular/core';

export interface CleaningLimitInput {
  unit: number | Unit;
  date: string;
  morning?: number;
  afternoon?: number;
  night?: number;
}

export class CleaningLimit {
  id?: number;
  unit?: Unit;
  date?: string | Date;
  morning?: number;
  afternoon?: number;
  night?: number;
}

@Injectable({
  providedIn: 'root',
})
export class CleaningLimitsService {
  API_ENDPOINT = '/cleaning-limits';

  constructor(public API: APIService) {}

  async createCleaningLimit(hotelCode: number, payload: CleaningLimitInput) {
    return this.API.post(this.API_ENDPOINT, payload, { tokenRequired: true, hideLoading: true });
  }

  async createCleaningLimits(hotelCode: number, payload: CleaningLimitInput[]) {
    return this.API.post(`${this.API_ENDPOINT}/bulk`, { bulk: payload }, { tokenRequired: true, hideLoading: true });
  }

  async updateCleaningLimit(hotelCode: number, cleaningLimitId: number, payload: CleaningLimitInput) {
    return this.API.patch(`${this.API_ENDPOINT}/${cleaningLimitId}`, payload, { tokenRequired: true, hideLoading: true });
  }

  async getUnitCleaningLimits(hotelCode: number): Promise<CleaningLimit[]> {
    const params = {
      join: ['unit'],
      filter: [`unit.hotelCode||eq||${hotelCode}`],
    };

    return this.API.get(this.API_ENDPOINT, params, { tokenRequired: true, hideLoading: true });
  }

  async deleteCleaningLimits(cleaningLimitsIds: number[]) {
    return this.API.patch(`${this.API_ENDPOINT}/remove`, cleaningLimitsIds, { tokenRequired: true, hideLoading: true });
  }
}
