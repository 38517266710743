import { Component, OnInit } from '@angular/core';
import { Unit } from 'src/app/entities/units/unit.entity';
import { ActivatedRoute } from '@angular/router';
import { NavService } from 'src/app/shared/service/nav.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Occurrence, OccurrenceService, OccurrenceSort, OccurrenceStatus, OccurrenceType, occurrenceTypeTranslator } from '../occurrence.service';

@Component({
  selector: 'app-list-occurrences',
  templateUrl: './list-occurrences.component.html',
  styleUrls: ['./list-occurrences.component.scss'],
})
export class ListOccurrencesComponent implements OnInit {
  occurrences: Occurrence[] = [];
  types = Object.keys(OccurrenceType).map(k => ({ name: occurrenceTypeTranslator(k as OccurrenceType), value: k }));

  loading = true;
  hotelInfo: Unit;

  pagination = {
    page: 1,
    limit: 15,
    pages: 5,
    collectionSize: 75,
  };

  searchTerm = '';

  chosenStatus: OccurrenceStatus = 'ALL' as any;
  chosenType: OccurrenceType = 'ALL' as any;
  chosenSortingOrder: OccurrenceSort = OccurrenceSort.NEWER;
  searchQuery: string = null;
  minDate;
  maxDate;

  occurrenceTypeTranslator = occurrenceTypeTranslator;
  OccurrenceStatus = OccurrenceStatus;
  occurrenceSortOptions = OccurrenceSort;

  constructor(private occurrenceSrvc: OccurrenceService, public route: ActivatedRoute, public navService: NavService, public modalService: NgbModal) {}

  ngOnInit() {
    this.navService.currentUnit$.subscribe(unit => {
      this.hotelInfo = unit;

      this.loadOccurrenceRequests();
    });
  }

  async loadOccurrenceRequests() {
    const params: any = {};
    if(!this.hotelInfo) return;

    if (this.chosenStatus) {
      params.status = this.chosenStatus;
      if (this.chosenStatus === ('ALL' as any)) {
        delete params.status;
      }
    }
    if (this.chosenType) {
      params.type = this.chosenType;
      if (this.chosenType === ('ALL' as any)) {
        delete params.type;
      }
    }
    if (this.chosenSortingOrder) {
      params.sortBy = this.chosenSortingOrder;
    }

    if (this.searchQuery) {
      params.searchQuery = this.searchQuery;
    }

    if (this.minDate && this.maxDate) {
      params.endDate = this.maxDate;
      params.startDate = this.minDate;
    }

    const requests = await this.occurrenceSrvc.loadHotelOccurrences(this.hotelInfo.hotelCode, this.pagination.page, this.pagination.limit, params);
    this.occurrences = [];
    requests.data.forEach(request => {
      console.log(request);
      const occurrence = new Occurrence(request);

      this.occurrences.push(occurrence);
    });

    this.pagination.pages = requests.totalPages;
    this.pagination.collectionSize = requests.totalPages * this.pagination.limit;
    this.loading = false;

    console.log(requests);

    console.log('TCL: ListOccurrencesComponent -> loadOccurrenceRequests -> this.occurrences', this.occurrences);
  }

  openFilterModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    }, (reason) => {
    });
  }

  clean() {
    this.chosenStatus = 'ALL' as any;
    this.chosenType = 'ALL' as any;
    this.chosenSortingOrder = OccurrenceSort.NEWER;
    this.searchQuery = null;
    this.minDate = null;
    this.maxDate = null;

    this.loadOccurrenceRequests();
  }
}
