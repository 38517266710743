import { Component, OnInit, Input } from '@angular/core';
import { CleaningType } from '../../entities/cleaning/cleaning-type.entity';
import { CleaningService } from '../cleaning.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Unit } from 'src/app/entities/units/unit.entity';
import { Router, ActivatedRoute } from '@angular/router';
import { NavService } from 'src/app/shared/service/nav.service';
import Swal from 'sweetalert2';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Cleaning, CleaningPeriod, CleaningStatus } from 'src/app/entities/cleaning/cleaning.entity';
import { Area } from '../../entities/units/area.entity';
import { AreasService } from '../../areas/areas.service';
import { ExtraService } from '../../entities/cleaning/extra-service.entity';
import { Employee } from '../../entities/employees/employee.entity';
import { Room } from '../../entities/rooms/room.entity';
import { RoomsService } from '../../rooms/rooms.service';
import { EmployeesService } from '../../employees/employees.service';
import { UserRole } from '../../entities/residents/user.entity';
import { BillingService } from '../../billing/billing.service';
import { addMinutes, format, addDays } from 'date-fns';

const AFTERNOON_END_HOUR_LIMIT = 18;
const AFTERNOON_START_HOUR_LIMIT = 12;

@Component({
  selector: 'app-calendar-form',
  templateUrl: './calendar-form.component.html',
  styleUrls: ['./calendar-form.component.scss'],
})

export class CalendarFormComponent implements OnInit {
  local: any;
  selectedOption: string;
  form: FormGroup;
  billingForm: FormGroup;
  cleaning: Cleaning = new Cleaning();
  periods: CleaningPeriod[] = [];
  status: CleaningStatus[] = [];
  loading = false;
  errorMessage: string;
  hotelInfo: Unit;
  @Input() id: number = undefined;
  CleaningStatus = CleaningStatus;
  CleaningPeriod = CleaningPeriod;
  areas: Area[] = [];
  @Input() isModal = false;
  @Input() event: Cleaning;
  extraServices: ExtraService[] = [];
  cleaningTypes: CleaningType[] = [];
  employees: Employee[] = [];
  rooms: Room[] = [];
  duration: number;
  startDate: Date;
  weeksToRepeat: number;

  selectedExtraServices: ExtraService[] = [];

  constructor(
    private formBuilder: FormBuilder,
    public service: CleaningService,
    public areaService: AreasService,
    public roomsService: RoomsService,
    public employeesService: EmployeesService,
    public billingSrvc: BillingService,
    public router: Router,
    public route: ActivatedRoute,
    public navService: NavService,
    public modal: NgbActiveModal,
  ) {
    this.startDate = this.route.snapshot.paramMap.get('date')? new Date(this.route.snapshot.paramMap.get('date')) : null;
  }

  ngOnInit() {
    this.loading = false;
    this.route.params.subscribe(async () => {
      this.hotelInfo = this.navService.hotelInfo;
      if(!this.hotelInfo) return;

      let cleanings;
      let facilities;
      [this.areas, cleanings, this.rooms, this.cleaningTypes, this.extraServices, facilities] = await Promise.all([
        this.areaService.getAllFromUnit(this.hotelInfo.hotelCode),
        this.employeesService.search(this.hotelInfo.hotelCode, '', UserRole.Cleaning),
        this.roomsService.getAllFromUnit(this.hotelInfo.hotelCode),
        this.service.getAllCleaningTypes(this.hotelInfo.hotelCode),
        this.service.getAllExtraServices(this.hotelInfo.hotelCode),
        this.employeesService.search(this.hotelInfo.hotelCode, '', UserRole.Facilities),
      ]);

      this.employees = [...cleanings.data, ...facilities.data];
    });
    if (this.id) {
      this.initCreateForm();
      this.initUpdateForm();
    } else {
      this.initCreateForm();
    }
  }

  closeModal() {
    this.modal.close({ updated: true });
  }

  initCreateForm() {
    this.form = this.formBuilder.group({
      user: [null],
      observation: [null],
      totalPrice: [null],
      area: [null, [Validators.required]],
      period: [null, [Validators.required]],
      startDate: [this.startDate? format(this.startDate, 'YYYY-MM-DDTHH:mm') : null, [Validators.required]],
      status: [null, [Validators.required]],
      local: [null, [Validators.required]],
      employee: [null],
      room: [null, [Validators.required]],
      cleaningType: [null, [Validators.required]],
      duration: [null, [Validators.required]],
      weeksToRepeat: ['0', [Validators.required]],
      // extraService: [null, [Validators.required]],
    });

    this.billingForm = this.formBuilder.group({
      Description: [null, [Validators.required]],
      Value: [null, [Validators.required]],
      BillingHidden: [null, [Validators.required]],
      totvsId: [null],
    });

    this.loading = false;
  }

  initUpdateForm() {
    this.loading = true;
    this.service.read(this.id).then(
      cleaning => {
        this.loading = false;
        this.errorMessage = null;
        this.cleaning = new Cleaning(cleaning);
        if (this.cleaning.area) {
          this.form.patchValue({ ['local']: 'AREA' });
        } else if (this.cleaning.room) {
          this.form.patchValue({ ['local']: 'ROOM' });
        }
        console.log(this.form.value);
        console.log(this.cleaning);

        let duration;

        if (this.cleaning.endDate && this.cleaning.startDate) {
          duration = (((this.cleaning.endDate.getTime() - this.cleaning.startDate.getTime()) / 1000) / 60);
        } else {
          duration = this.cleaning.cleaningType.duration;
        }

        this.selectedExtraServices = this.cleaning.extraService.map(e => new ExtraService({ id: e.id })) || [];
        this.form.patchValue({
          ['user']: this.cleaning.user ? this.cleaning.user.name : null,
          ['observation']: this.cleaning.observation,
          ['area']: this.cleaning.area ? this.cleaning.area.id : null,
          ['period']: this.cleaning.period,
          ['startDate']: this.cleaning.startDate ? format(this.cleaning.startDate, 'YYYY-MM-DDTHH:mm') : this.cleaning.date? format( this.cleaning.date, 'YYYY-MM-DDTHH:mm') : null,
          ['duration']: duration || null,
          ['date']: this.cleaning.date,
          ['status']: this.cleaning.status,
          ['room']: this.cleaning.room ? this.cleaning.room.id : null,
          ['cleaningType']: this.cleaning.cleaningType ? this.cleaning.cleaningType.id : null,
          // ['extraService']: this.cleaning.extraService = [],
          ['employee']: this.cleaning.employee ? this.cleaning.employee.id : null,
          ['totalPrice']: this.cleaning.totalPrice,
        });
        if (this.cleaning.billing) {
          this.billingForm.patchValue({
            ['Description']: this.cleaning.billing.Description ? this.cleaning.billing.Description : null,
            ['Value']: this.cleaning.billing.Value ? this.cleaning.billing.Value : null,
            ['BillingHidden']: this.cleaning.billing.BillingHidden ? !!this.cleaning.billing.BillingHidden : null,
            ['totvsId']: this.cleaning.billing.totvsId ? this.cleaning.billing.totvsId : null,
          });
        }
      },
      _err => {
        this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
      },
    );
  }

  roomChanged() {
    const room = this.rooms.find(r => r.id === Number(this.form.value.room));
    this.form.patchValue({
      ['user']: room.guest ? `${room.guest.GivenName} ${room.guest.SurName}` : 'Uliving',
    });
  }

  submit() {
    if (this.id) {
      this.update();
    } else {
      this.create();
    }
  }

  create() {
    const room = this.rooms.find(r => r.id === Number(this.form.value.room));
    this.loading = true;
    const cleaningStartDate = new Date(this.form.value.startDate);
    const weeksToRepeat = this.form.value.weeksToRepeat;
    const cleaningEndDate = addMinutes(cleaningStartDate, this.form.value.duration);
    this.form.value.employee = this.form.value.employee == 'undefined'? undefined : this.form.value.employee;
    this.form.value.period = this.getPeriod(cleaningStartDate);
    this.cleaning = new Cleaning({
      status: this.form.value.status,
      date: cleaningStartDate,
      startDate: cleaningStartDate,
      endDate: cleaningEndDate,
      period: this.form.value.period,
      observation: this.form.value.observation,
      employee: this.form.value.employee
        ? {
            id: Number(this.form.value.employee),
          }
        : undefined,
      unit: {
        hotelCode: this.hotelInfo.hotelCode,
      },
    });

    if (this.form.value.local === 'ROOM') {
      this.cleaning.cleaningType = new CleaningType({
        id: Number(this.form.value.cleaningType),
      });

      this.cleaning.room = new Room({
        id: Number(this.form.value.room),
        roomNumber: room.roomNumber,
        guest: room.guest ? room.guest : undefined,
      });

      this.cleaning.extraService = this.selectedExtraServices;
      this.cleaning.totalPrice = this.getTotalPrice();
    }

    if (this.form.value.local === 'AREA') {
      this.cleaning.area = new Area({
        id: Number(this.form.value.area),
      });
    }

    if (new Date(this.form.value.startDate) > new Date(this.form.value.endDate)) {
      console.error('Error with the date');
      this.errorMessage = 'As datas de início e fim estão incorretas';
      return (this.loading = false);
    } else {
      this.service.create(this.cleaning, weeksToRepeat).then(
        cleaning => {
          this.successNew(cleaning);
          this.loading = false;
          this.errorMessage = null;
        },
        err => {
          console.error('Error in create calendar component', err);
          this.loading = false;
          this.handleErrors(err.error);

          if(err.message && err.message.length) {
            for (const e of err.message) {
              if(e && e.property) {
                this.form.get(e.property).setErrors({ error: true });
              }
            }
          }
        },
      );
    }
  }

  update() {
    this.loading = true;
    const cleaningStartDate = new Date(this.form.value.startDate);
    const cleaningEndDate = addMinutes(cleaningStartDate, this.form.value.duration);
    this.form.value.period = this.getPeriod(cleaningStartDate);
    const cleaning = new Cleaning({
      status: this.form.value.status,
      date: cleaningStartDate,
      startDate: cleaningStartDate,
      endDate: cleaningEndDate,
      period: this.form.value.period,
      observation: this.form.value.observation,
      area: this.form.value.area,
      room: {
        id: this.form.value.room ? Number(this.form.value.room) : null,
      },
      cleaningType: this.form.value.cleaningType,
      extraService: this.selectedExtraServices,
      totalPrice: this.form.value.totalPrice,
      id: this.id,
      employee: {
        id: this.form.value.employee ? Number(this.form.value.employee) : null,
      },
      unit: {
        hotelCode: this.hotelInfo.hotelCode,
      },
    });

    this.service.updateAdmin(cleaning, this.id).then(
      () => {
        this.successEdit();
        this.loading = false;
        this.errorMessage = null;
      },
      err => {
        console.error('Error in update type component', err);
        this.loading = false;
        this.handleErrors(err.error);

        if(err.message && err.message.length) {
          for (const e of err.message) {
            if(e && e.property) {
              this.form.get(e.property).setErrors({ error: true });
            }
          }
        }
      },
    );
  }

  async submitBilling() {
    await this.updateBilling();
    this.initUpdateForm();
  }

  async updateBilling() {
    return await this.billingSrvc.delete(this.cleaning.billing.id);
  }

  showError(field?: string) {
    return (
      (this.form.get(field).invalid && this.form.get(field).touched) ||
      (!this.form.get(field).touched && this.errorMessage && this.errorMessage.length > 0 && this.form.get(field).invalid)
    );
  }

  successNew(type: Cleaning) {
    Swal.fire({
      title: 'Limpeza agendada',
      type: 'success',
      showConfirmButton: true,
      confirmButtonText: 'Voltar ao calendário',
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary float-left',
      cancelButtonText: 'Cadastrar outra',
      showCloseButton: true,
      onClose: () => this.goToList(),
    }).then(resp => {
      if (resp.value) {
        this.goToDetails();
      } else {
        this.goToForm();
      }
    });
  }

  successEdit() {
    return Swal.fire({
      title: 'Limpeza editada!',
      type: 'success',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary',
      cancelButtonText: 'Voltar',
      showCloseButton: true,
      onClose: () => {
        this.initUpdateForm();
      },
    });
  }

  clickDelete(id: number) {
    Swal.fire({
      title: 'Deseja mesmo excluir esta limpeza?',
      type: 'warning',
      showConfirmButton: true,
      confirmButtonText: 'Sim, excluir',
      confirmButtonClass: 'btn btn-danger',
      showCancelButton: true,
      cancelButtonClass: 'btn btn-secondary',
      cancelButtonText: 'Cancelar',
      showCloseButton: true,
    }).then(resp => {
      if (resp.value) {
        this.deleteCleaning(id);
      }
    });
  }

  deleteCleaning(id: number) {
    this.service
      .deleteCleaning(id)
      .then(del => {
        Swal.fire({
          type: 'success',
          text: 'Limpeza deletada com sucesso.',
          titleText: 'Excluído.',
        }).then(resp => {
          if (this.modal) {
            this.closeModal();
          }
        });
      })
      .catch(err => {
        Swal.fire({
          type: 'error',
          titleText: 'Erro ao excluir.',
          text: 'Por favor, tente novamente.',
        });
      });
  }

  goToList() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'cleaning', 'calendar']);
  }
  goToDetails() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'cleaning', 'calendar']);
  }

  goToForm() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'cleaning', 'calendar', 'create']);
  }

  onCheckChange(extraServiceId: number) {
    // esse serviço foi selecionado?
    const index = this.selectedExtraServices.findIndex(obj => Number(obj.id) === Number(extraServiceId));
    console.log(' index ', index);
    if (index === -1) {
      // ele está no array de selecionados?
      this.selectedExtraServices.push(new ExtraService({ id: Number(extraServiceId) }));
    } else {
      // caso ele já esteja no array de selecionado
      this.selectedExtraServices.splice(index, 1);
    }

    this.updateTotalPrice();

    console.log(' SELECTED', this.selectedExtraServices);
    console.log(' Price ', this.getTotalPrice());
  }

  isChecked(extraServiceId: number) {
    return this.selectedExtraServices.findIndex(obj => Number(obj.id) === Number(extraServiceId)) !== -1;
  }

  getTotalPrice() {
    const selectedCleaningType = this.cleaningTypes.find(type => Number(this.form.value.cleaningType) === type.id);
    const cleaningTypePrice: number = selectedCleaningType ? Number(selectedCleaningType.price) : 0;

    let extraPrice = 0;
    const selectedArray = this.extraServices.filter(e => this.isChecked(e.id));
    for (const e of selectedArray) {
      extraPrice += Number(e.price);
    }

    let totalPrice;
    const weeksToRepeat = this.form.value.weeksToRepeat;

    if (weeksToRepeat > 0) {
      totalPrice = ((cleaningTypePrice + extraPrice) * 7 ) * weeksToRepeat;
    } else {
      totalPrice = cleaningTypePrice + extraPrice;
    }

    return totalPrice;
  }

  updateTotalPrice() {
    this.form.patchValue({
      totalPrice: this.getTotalPrice(),
    });
  }

  handleErrors(error) {
    if (error === 'CHOSEN_PERIOD_AND_DAY_NOT_AVAILABLE') {
      this.errorMessage = 'Limite de limpezas do período excedido';
    } else if (error === 'CLEAN_TOO_SOON') {
      this.errorMessage = 'Agendamento deve ser feito com pelo menos 24h de antecedência';
    } else if (error === 'ALL_EMPLOYEES_BUSY') {
      this.errorMessage = 'Todos os funcionários da unidade estão indisponíveis nesse horário';
    } else if (error === 'BUSY_EMPLOYEE') {
      this.errorMessage = 'Funcionário já possui uma limpeza neste horário';
    } else if (error === 'MAX_LIMIT_TO_REPEAT') {
      this.errorMessage = 'Limite de repetição de agendamento excedido ( Máx 10 semanas )';
    } else {
      this.errorMessage = 'Verifique os campos incorretos';
    }
  }

  getPeriod(chosenHour) {
    if (chosenHour.getHours() > AFTERNOON_END_HOUR_LIMIT) {
      return CleaningPeriod.NIGHT;
    } else if (chosenHour.getHours() > AFTERNOON_START_HOUR_LIMIT) {
      return CleaningPeriod.AFTERNOON;
    } else {
      return CleaningPeriod.MORNING;
    }
  }
}
