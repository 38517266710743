import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from '../app-routing.module';
import { NgbAlertModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { SpacesService } from './spaces.service';
import { ListSpacesComponent } from './list-spaces/list-spaces.component';
import { SpacesFormComponent } from './spaces-form/spaces-form.component';
import { UnitsFormComponent } from './units-form/units-form.component';
import { ListUnitsComponent } from './list-units/list-units.component';
import { SpaceScheduleComponent } from './space-schedule/space-schedule.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ScheduleFormComponent } from './schedule-form/schedule-form.component';
import { ResidentsModule } from '../residents/residents.module';
import { SchedulesService } from './schedules.service';

@NgModule({
  declarations: [
    ListSpacesComponent,
    SpacesFormComponent,
    UnitsFormComponent,
    UnitsFormComponent,
    ListUnitsComponent,
    SpaceScheduleComponent,
    ScheduleFormComponent,
  ],
  entryComponents: [
    ScheduleFormComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AppRoutingModule,
    NgbAlertModule,
    NgbModalModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    ResidentsModule
  ],
  providers: [
    SpacesService,
    SchedulesService,
  ],
})
export class UnitModule { }
