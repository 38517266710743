import { BaseEntity } from '../common/base.entity';
import { Evaluation } from '../evaluations/evaluations.entity';

export enum UserStatus {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  BLOCKED = 'BLOCKED',
}

export enum UserRole {
  User = 'User',
  Employee = 'Employee',
  Professional = 'Professional',
  Admin = 'Admin',
}

export enum Professions {
  DOCTOR = 'DOCTOR',
  PHYSIO = 'PHYSIO',
  PHONO = 'PHONO',
  DENTIST = 'DENTIST',
  NURSE = 'NURSE',
  OTHER = 'OTHER',
}

export const PROFESSIONS_NAME = {
  'DOCTOR': 'Médico',
  'PHYSIO': 'Fisioterapeuta',
  'PHONO': 'Fonoaudiólogo',
  'DENTIST': 'Dentista',
  'NURSE': 'Enfermeiro',
  'OTHER': 'Outro',
};

export class Professional extends BaseEntity {

  constructor(values?: object) {
    super(values);
    if (values && this.evaluations) {
      this.evaluations = this.evaluations.map(ev => new Evaluation(ev));
    }
  }

  get note() {
    if (!this.evaluations || this.evaluations.length === 0) {
      return '?';
    }
    return this.evaluations[0].note;
  }

  phone?: string;
  city?: string;
  state?: string;
  countryCode?: string;
  job?: string;
  profession?: Professions;
  user?: any;
  evaluations: Evaluation[];
  reports: Evaluation[];
  name?: string;
  email?: string;
  password?: string;

  passwordHash?: string;
  role?: UserRole;
  status?: UserStatus;
  getPlace() {
    return `${this.city}-${this.state} (${this.countryCode})`;
  }

  getNoteColor() {
    if (this.evaluations && this.evaluations.length > 0) {
      return this.evaluations[0].getNoteColor();
    }
    return 'muted';
  }

  getProfession() {
    return PROFESSIONS_NAME[this.profession];
  }
}
