import { Component, OnInit } from '@angular/core';
import { UserEvent } from '../event.entity';
import { Unit } from 'src/app/entities/units/unit.entity';
import { EventsService } from '../events.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavService } from 'src/app/shared/service/nav.service';
import { Guest } from '../../entities/residents/guest.entity';
import { AuthService } from 'src/app/auth.service';
import { Space } from '../../entities/spaces/space.entity';
import { UnitsService } from '../../unit/units.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-list-events',
  templateUrl: './list-events.component.html',
  styleUrls: ['./list-events.component.scss']
})
export class ListEventsComponent implements OnInit {

  events: UserEvent[] = [];
  searchTerm = '';
  loading = true;
  hotelInfo: Unit;

  hasFilter = false;
  spaces: Space[] = [];
  adminId: number = null;

  pagination = {
    page: 1,
    limit: 25,
    pages: 5,
    collectionSize: 100,
  };

  filters: any;

  constructor(
    public eventsService: EventsService,
    public route: ActivatedRoute,
    public navService: NavService,
    public router: Router,
    public authService: AuthService,
    public unitsService: UnitsService,
    public modalService: NgbModal
  ) {
  }

  async ngOnInit() {
    this.navService.currentUnit$.subscribe((unit) => {
      this.hotelInfo = unit;
      this.spaces = undefined;
      if (unit) {
        this.spaces = unit.spaces;
        this.getAll();
      }
    });
  }

  async getAll() {
    if (!this.hotelInfo) {
      return;
    }
    this.loading = true;
    const resp = await this.eventsService.getAllFromUnit(this.hotelInfo.hotelCode, undefined, {
      limit: this.pagination.limit,
      page: this.pagination.page
    })
    .catch((e) => {
      alert('Erro ao buscar todos eventos');
      this.loading = false;
      console.error(e);
      return { data: [], totalPages: 0};
    });
    this.events = resp.data;
    this.pagination.pages = resp.totalPages;
    this.pagination.collectionSize = resp.totalPages * this.pagination.limit;
    this.loading = false;
    this.hasFilter = false;
    delete this.filters;
  }

  async filterResults(filters?: any) {
    if (filters) {
      this.filters = filters;
    }
    console.log('DEBUG: ListEventsComponent -> filterResults -> filters', this.filters, filters);
    this.loading = true;
    let query = '';
    if (this.filters.minDate) {
      query = query + `&startDate=${this.filters.minDate}`;
    }
    if (this.filters.maxDate) {
      query = query +
      `&endDate=${this.filters.maxDate + ' 23:59:59'}`;
    }
    if (this.filters.spaceId) { // TODO space event filter
      query = query + `&spaceId=${this.filters.spaceId}`;
    }
    if (this.filters.private) {
      query = query + `&private=${this.filters.private}`;
    }
    const resp = await this.eventsService.getAllFromUnit(this.hotelInfo.hotelCode, query, {
      limit: this.pagination.limit,
      page: this.pagination.page
    })
    .catch((e) => {
      alert('Erro ao filtrar eventos');
      this.loading = false;
      console.error(e);
      return { data: [], totalPages: 0};
    });
    this.events = resp.data;
    this.pagination.pages = resp.totalPages;
    this.pagination.collectionSize = resp.totalPages * this.pagination.limit;
    this.hasFilter = true;
    this.loading = false;
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    }, (reason) => {
    });
  }

}
