import { Unit } from '../entities/units/unit.entity';
import { RestEntity, DeepPartial } from '../entities/rest.entity';
import { User } from '../entities/residents/user.entity';
import { Schedule, Attendance } from '../entities/spaces/schedule.entity';

export class UserEvent extends RestEntity<UserEvent> {
  constructor(obj?: DeepPartial<UserEvent>) {
    super(obj);
    if (obj && obj.startDate) {
      this.startDate = new Date(String(obj.startDate));
    }
    if (obj && obj.endDate) {
      this.endDate = new Date(String(obj.endDate));
    }
    if (obj && obj.user) {
      this.user = new User(obj.user);
    }
    if (obj && obj.schedule) {
      this.schedule = new Schedule(obj.schedule);
    }
    if (obj && obj.attendances) {
      this.attendances = this.attendances.map(a => {
        if (a.user) {
          a.user = new User(a.user);
        }
        return a;
      });
    }

  }

  title: string;
  user: User;
  unit: Unit;
  schedule: Schedule;
  attendances?: Attendance[];
  spaceReserved: boolean;
  private: boolean | number;
  startDate: Date;
  endDate: Date;
  description: string;
}
