import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbAlertModule, NgbPaginationModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from '../app-routing.module';
import { MASK_OPTIONS, SharedModule } from '../shared/shared.module';
import { DetailsStatementComponent } from './details-statement/details-statement.component';
import { ListStatementsComponent } from './list-statement/list-statement.component';
import { StatementFormComponent } from './statement-form/statement-form.component';
import { StatementService } from './statement.service';

@NgModule({
  declarations: [ListStatementsComponent, DetailsStatementComponent, StatementFormComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbAlertModule,
    NgbPaginationModule,
    NgbTooltipModule,
    ToastrModule.forRoot(), // ToastrModule added
    NgxMaskModule.forRoot(MASK_OPTIONS),
  ],
  providers: [StatementService],
  exports: [],
})
export class StatementModule {}
