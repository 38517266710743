import { UserRole } from '../entities/residents/user.entity';

const admin = UserRole.Admin;
const manager = UserRole.Manager;
const porter = UserRole.Porter;
const cleaning = UserRole.Cleaning;
const maintenance = UserRole.Maintenance;
const marketing = UserRole.Marketing;
const commercial = UserRole.Commercial;
const doorman = UserRole.Doorman;
const facilities = UserRole.Facilities;
const all = [admin, manager, porter];
const open = [admin, manager, marketing, porter, maintenance, cleaning, commercial, doorman, facilities];

export const PERMISSIONS = {
  units: {
    create: [admin],
    list: [admin],
    read: [admin, manager],
    update: [admin, manager],
    delete: [admin],
    change: [admin, marketing, commercial],
  },

  spaces: {
    create: [admin, manager],
    list: open,
    read: open,
    update: [admin, manager],
    delete: [admin, manager],
  },

  areas: {
    create: [admin, manager],
    list: open,
    read: open,
    update: [admin, manager],
    delete: [admin, manager],
  },

  rooms: {
    create: [admin, manager],
    list: open,
    read: open,
    update: [admin, manager],
    delete: [admin, manager],
  },

  residents: {
    create: [admin, manager, porter],
    list: open,
    read: open,
    update: [admin, manager, porter],
    delete: [admin, manager, porter],
    sync: [admin, manager, porter],
  },

  visits: {
    create: [admin, manager, porter],
    list: all,
    read: all,
    update: [admin, manager, porter],
    delete: [admin, manager, porter],
  },

  visitors: {
    create: [admin, manager],
    list: all,
    read: all,
    update: [admin, manager],
    delete: [admin, manager],
  },

  schedule: {
    create: [admin, manager, marketing, porter, commercial, doorman],
    list: [...all, marketing, commercial, doorman],
    read: [...all, marketing, commercial, doorman],
    update: [admin, manager, marketing, porter, commercial],
    delete: [admin, manager, marketing, porter, commercial],
  },

  employees: {
    create: [admin, manager],
    list: [...all, marketing, commercial],
    read: [...all, marketing, commercial],
    update: [admin, manager],
    delete: [admin, manager],
  },

  events: {
    create: [admin, manager, marketing, porter, commercial],
    list: [...all, marketing, commercial],
    read: [...all, marketing, commercial],
    update: [admin, manager, marketing, porter, commercial],
    delete: [admin, manager, marketing, porter, commercial],
  },

  cleaning: {
    create: [admin, manager, cleaning, porter, doorman, facilities],
    list: [...all, cleaning, doorman, facilities],
    read: [...all, cleaning, doorman, facilities],
    update: [admin, manager, cleaning, porter, facilities],
    typeUpdate: [admin, manager],
    delete: [admin, manager, cleaning, porter, facilities],
  },

  maintenance: {
    create: [admin, manager, maintenance, porter, doorman, facilities],
    list: [...all, maintenance, doorman, facilities],
    read: [...all, maintenance, doorman, facilities],
    update: [admin, manager, maintenance, porter, facilities],
    delete: [admin, manager, maintenance, porter, facilities],
    reports: [admin, manager, maintenance, porter, facilities],
  },

  occurrence: {
    create: [admin, manager, facilities],
    list: [admin, manager, facilities],
    read: [admin, manager, facilities],
    update: [admin, manager, facilities],
    delete: [admin, manager, facilities],
    reports: [admin, manager, facilities],
  },

  feedback: {
    create: [admin, manager],
    list: [...all, commercial],
    read: [...all, commercial],
    update: [admin, manager],
    delete: [admin, manager],
  },

  posts: {
    create: [...all, marketing, commercial],
    list: [...all, marketing, commercial],
    read: [...all, marketing, commercial],
    update: [...all, marketing, commercial],
    delete: [...all, marketing, commercial],
  },

  topics: {
    create: [admin, manager, marketing, commercial],
    list: [...all, marketing, commercial],
    read: [...all, marketing, commercial],
    update: [admin, manager, marketing, commercial],
    delete: [admin, manager, marketing, commercial],
  },

  lifts: {
    create: [admin, manager, marketing, commercial],
    list: [...all, marketing, commercial],
    read: [...all, marketing, commercial],
    update: [admin, manager, marketing, commercial],
    delete: [admin, manager, marketing, commercial],
  },

  vouchers: {
    create: [admin, manager, marketing, commercial],
    list: [...all, marketing, commercial],
    read: [...all, marketing, commercial],
    update: [admin, manager, marketing, commercial],
    delete: [admin, manager, marketing, commercial],
  },

  map: {
    create: [admin, manager, marketing, commercial],
    list: [...all, marketing, commercial],
    read: [...all, marketing, commercial],
    update: [admin, manager, marketing, commercial],
    delete: [admin, manager, marketing, commercial],
  },

  'config.maintenance': {
    create: [admin, manager, maintenance, porter],
    list: [...all, marketing, commercial],
    read: [...all, marketing, commercial],
    update: [admin, manager, maintenance, porter],
    delete: [admin, manager, maintenance, porter],
  },

  'config.occurrence': {
    create: [admin, manager, facilities],
    list: [admin, manager, facilities],
    read: [admin, manager, facilities],
    update: [admin, manager, facilities],
    delete: [admin, manager, facilities],
  },
};

export function checkDelete(path: string, role: UserRole) {
  if (PERMISSIONS[path] && PERMISSIONS[path]['delete']) {
    return PERMISSIONS[path]['delete'].includes(role);
  }
  return true;
}
