import { Injectable } from '@angular/core';
import { APIService, APIRequestOptions } from '../api.service';
import { ReservationStatus, GuestInformation } from '../entities/residents/totvs.interfaces';
import { ReservationsIds } from '../entities/residents/reservationsIds.entity';
import { Guest } from '../entities/residents/guest.entity';

export interface GuestRelationInterface {
  LocatorId: string;
  HotelCode: number;
  RoomNumber: string;
  ReservationStatus: ReservationStatus;
  Checkin: string;
  Checkout: string;
  EffectiveCheckin: string;
  EffectiveCheckout: string;
  Guest: {
    Code: number;
    Name: string;
    MainGuest: boolean;
  };
  ReservationIds: ReservationsIds;
  ID: string;
  appGuest: Guest;
  GuestInformation?: GuestInformation;
  isLoading?: boolean;
  isSync?: boolean;
}

export interface GetRequestAttributes {
  HIGSReservationNumber?: string;
  PMSReservationNumber?: string;
  SourceReservationNumber?: string;
  ReservationStatus?: ReservationStatus;
  GuestRoom?: string;
  GuestCode?: number;
  AllTickets?: boolean;
  SelfTickets?: boolean;
  HotelCode?: number;
}

export interface GetManyResponse<T> {
  ResponseList: T[];
  Page: string;
  NextPage: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class TotvsService {

  constructor(
    public API: APIService
  ) { }

  async getGuestRelations(HotelCode: number, conditions: GetRequestAttributes = {}, Page = 1, loadAppGuest = false, options?: APIRequestOptions) {
    const resp = await (this.API.get<GetManyResponse<GuestRelationInterface>>('/totvs/guest', {
      ...conditions,
      Page,
      HotelCode,
      loadAppGuest,
    }, { tokenRequired: true, ...options }));
    return resp;
  }

  async getAllGuests(HotelCode: number, conditions: GetRequestAttributes = {}, Page = 1, options?: APIRequestOptions) {
    const resp = await (this.API.get<GuestRelationInterface[]>('/totvs/guest/all', {
      ...conditions,
      Page,
      HotelCode,
    }, { tokenRequired: true, ...options }));
    return resp;
  }
}
