import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventCalenderFormComponent } from './calendar-form/event-form.component';
import { EventFormComponent } from './event-form/event-form.component';
import { ListEventsComponent } from './list-events/list-events.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from '../app-routing.module';
import { NgbAlertModule, NgbModalModule, NgbActiveModal, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { EventsService } from './events.service';
import { EventCalendarComponent } from './calendar/calendar.component';

@NgModule({
  entryComponents: [EventCalenderFormComponent],
  declarations: [EventCalendarComponent, EventCalenderFormComponent, EventFormComponent, ListEventsComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AppRoutingModule,
    NgbAlertModule,
    NgbPaginationModule,
    NgbModalModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
  ],
  providers: [
    EventsService,
  ]
})
export class EventsModule { }
