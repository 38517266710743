import { Component, OnInit } from '@angular/core';
import { CleaningService } from '../cleaning.service';
import { CleaningType } from 'src/app/entities/cleaning/cleaning-type.entity';
import { ActivatedRoute } from '@angular/router';
import { NavService } from 'src/app/shared/service/nav.service';
import { ExtraService } from 'src/app/entities/cleaning/extra-service.entity';
import { Unit } from 'src/app/entities/units/unit.entity';

@Component({
  selector: 'app-list-extra-service',
  templateUrl: './list-extra-service.component.html',
  styleUrls: ['./list-extra-service.component.scss']
})
export class ListExtraServiceComponent implements OnInit {

  types: ExtraService[] = [];

  loading = true;
  hotelInfo: Unit;
  constructor(
    public service: CleaningService,
    public route: ActivatedRoute,
    public navService: NavService,
  ) {
  }

  ngOnInit() {
    this.navService.currentUnit$.subscribe((unit) => {
      this.hotelInfo = unit;
      this.getAll();
    });
  }

  async getAll() {
    this.loading = true;
    console.log(this.hotelInfo);
    this.types = await this.service.getAllExtraServices(this.hotelInfo.hotelCode)
    .catch((e) => {
      alert('Erro ao buscar serviçi extra');
      this.loading = false;
      console.error(e);
      return [];
    });
    this.loading = false;
  }

}
