import { Employee } from '../employees/employee.entity';
import { RestEntity } from '../rest.entity';
import { Space } from '../spaces/space.entity';
import { Room } from '../rooms/room.entity';
import { Area } from './area.entity';
import { LiftEvent } from '../social/liftEvent.entity';

export class Unit extends RestEntity<Unit> {

  name: string;
  code: string;
  hotelCode: number;
  address?: string;
  employees?: Employee[];
  documentUrl?: string;
  spaces?: Space[];
  rooms?: Room[];
  areas?: Area[];
  morningStart: string;
  morningEnd: string;
  afternoonStart: string;
  afternoonEnd: string;
  nightStart: string;
  nightEnd: string;
  latitude: number;
  longitude: number;

}
