import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { startOfMonth, endOfMonth, startOfWeek, addHours, addDays, addWeeks, addMonths, differenceInMinutes } from 'date-fns';
import { MaintenanceService, Maintenance, MaintenanceStatus, MaintenanceType, maintenanceTypeTranslator } from '../maintenance.service';
import { Unit } from '../../entities/units/unit.entity';
import { ActivatedRoute } from '@angular/router';
import { NavService } from '../../shared/service/nav.service';
import { ChartColors } from '../../shared/charts-components/chart-colors';
import { Workbook } from 'exceljs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-maintenances-reports',
  templateUrl: './maintenances-reports.component.html',
  styleUrls: ['./maintenances-reports.component.scss']
})
export class MaintenancesReportsComponent implements OnInit {

  hotelInfo: Unit;

  chartsData = {
    perStatus: {
      ready: false,
      labels: [],
      datasets: [],
      legend: false,
    },
    perType: {
      ready: false,
      labels: [],
      datasets: [],
      legend: true,
    },
    sla: {
      ready: false,
      labels: [],
      datasets: [],
      legend: false,
    },
  };

  stats = {
    perStatus: {
      total: 0,
      opened: 0,
      progress: 0,
      concluded: 0,
      cancelled: 0,
      approved: 0,
      refused: 0,
    },
    perType: {
      total: 0,
    } as any,
    sla: {
      mean: 0,
      stdev: 0,
      min: 0,
      max: 0,
    },
  };

  filters = {
    startDate: formatDate(startOfMonth(new Date()), 'yyyy-MM-dd', 'pt-PT'),
    endDate: formatDate(endOfMonth(new Date()), 'yyyy-MM-dd', 'pt-PT'),
    interval: 'DAY' as ('HOUR' | 'DAY' | 'WEEK' | 'MONTH'),
  };

  allMaintenances: Maintenance[] = [];

  constructor(
    public maintenanceSrvc: MaintenanceService,
    public route: ActivatedRoute,
    public navService: NavService,
  ) { }

  ngOnInit() {
    this.navService.currentUnit$.subscribe(unit => {
      this.hotelInfo = unit;

      this.loadAll();
    });
  }

  async loadAll() {
    const allMaintenances = [];
    let page = 0;
    let pagesLeft = 1;

    while (pagesLeft > 0) {
      page++;

      const resp = await this.maintenanceSrvc.loadHotelMaintenances(
        this.hotelInfo.hotelCode,
        page,
        undefined,
        {
          startDate: this.filters.startDate,
          endDate: this.filters.endDate,
        },
        true
      );

      if (!resp) {
        Swal.fire({
          type: 'error',
          title: 'Ops!',
          text: 'Erro ao carregar manutenções',
        });
        return;
      }

      if (resp && resp.totalPages) {
        pagesLeft = resp.totalPages - page;
      } else {
        pagesLeft = 0;
      }

      if (resp && resp.data) {
        allMaintenances.push(...resp.data.map(m => new Maintenance(m)));
      }

    }

    this.allMaintenances = allMaintenances;
    console.log('DEBUG: MaintenancesReportsComponent -> loadAll -> this.allMaintenances', this.allMaintenances);
    this.getPerStatusStats(this.allMaintenances);
    this.getPerTypeStats(this.allMaintenances);
    this.getSLAStats(this.allMaintenances);

  }

  handleFilterChange() {
    // tslint:disable-next-line: forin
    for (const chart in this.chartsData) {
      this.chartsData[chart].ready = false;
    }
    this.loadAll();
  }

  getPerStatusStats(maintenances: Maintenance[]) {
    if (maintenances.length === 0) {
      this.chartsData.perStatus = {
        labels: [],
        datasets: [],
        ready: true,
        legend: this.chartsData.perStatus.legend,
      };
      return;
    }

    const perStatus = getGroupedByKey(maintenances, 'status');
    const S = MaintenanceStatus;
    this.stats.perStatus = {
      total: maintenances.length,
      opened: (perStatus[S.RECEIVED] ? perStatus[S.RECEIVED].length : 0) + (perStatus[S.REQUESTED] ? perStatus[S.REQUESTED].length : 0),
      progress: (perStatus[S.PROGRESS] ? perStatus[S.PROGRESS].length : 0),
      concluded: (perStatus[S.FINISHED] ? perStatus[S.FINISHED].length : 0),
      approved: (perStatus[S.APPROVED] ? perStatus[S.APPROVED].length : 0),
      refused: (perStatus[S.REFUSED] ? perStatus[S.REFUSED].length : 0),
      cancelled: (perStatus[S.CANCELED] ? perStatus[S.CANCELED].length : 0),
    };

    const datasets = [];
    let labels = getIntervalPeriods(new Date(this.filters.startDate), new Date(this.filters.endDate), this.filters.interval);
    console.log('DEBUG: MaintenancesReportsComponent -> getPerStatusStats -> labels', labels);
    // tslint:disable-next-line: forin
    for (const status in perStatus) {
      const statusBadge = (new Maintenance({ status: status as any })).statusBadge;
      const perInterval = getGroupedByFunction(perStatus[status], (m) => getIntervalLabel(m.createdDate, this.filters.interval));
      console.log('DEBUG: MaintenancesReportsComponent -> getPerStatusStats -> perInterval', perInterval);
      datasets.push({
        label: statusBadge.text,
        ...ChartColors[statusBadge.class.split('-')[1]],
        data: labels.map(label => perInterval[label] ? perInterval[label].length : 0),
        stack: 'main',
      });
      const intervals = Object.keys(perInterval);
      labels = labels.concat(intervals.filter((item) => labels.indexOf(item) < 0));
    }
    this.chartsData.perStatus = {
      datasets,
      labels,
      ready: true,
      legend: this.chartsData.perStatus.legend,
    };
  }

  getPerTypeStats(maintenances: Maintenance[]) {
    if (maintenances.length === 0) {
      this.chartsData.perType = {
        labels: [],
        datasets: [],
        ready: true,
        legend: this.chartsData.perType.legend,
      };
      return;
    }

    const perType = getGroupedByKey(maintenances, 'type');
    const T = MaintenanceType;
    this.stats.perType = {
      total: maintenances.length,
    };

    const datasets = [];
    const labels = [];
    // tslint:disable-next-line: forin
    for (const type in perType) {
      if (this.stats.perType[type]) {
        this.stats.perType[type]++;
      } else {
        this.stats.perType[type] = 1;
      }
      datasets.push(perType[type].length);
      // tslint:disable-next-line: max-line-length
      labels.push(maintenanceTypeTranslator(type as MaintenanceType) + ' (' + (perType[type].length * 100 / this.stats.perType.total).toFixed(1) + '%)');
    }
    this.chartsData.perType = {
      datasets,
      labels,
      ready: true,
      legend: this.chartsData.perType.legend,
    };
  }

  getSLAStats(maintenances: Maintenance[]) {
    if (maintenances.length === 0) {
      this.chartsData.sla = {
        labels: [],
        datasets: [],
        ready: true,
        legend: this.chartsData.sla.legend,
      };
      return;
    }

    const slas = maintenances.map(m => m.slaHours).filter(sla => !!sla);
    this.stats.sla.mean = getMean(slas);
    this.stats.sla.stdev = getStandartDeviation(slas);
    this.stats.sla.min = Math.min(...slas);
    this.stats.sla.max = Math.max(...slas);

    const data = [];
    const labels = getIntervalPeriods(new Date(this.filters.startDate), new Date(this.filters.endDate), this.filters.interval);

    const perInterval = getGroupedByFunction(maintenances, (m) => getIntervalLabel(m.createdDate, this.filters.interval));
    let prevSLA = 0;
    for (const label of labels) {
      let sla: number;
      if (perInterval[label]) {
        const labelSlas = perInterval[label].map((m: Maintenance) => m.slaHours).filter(s => !!s);
        sla = labelSlas && labelSlas.length ? getMean(labelSlas) : prevSLA;
        prevSLA = sla;
      } else {
        sla = prevSLA;
      }
      data.push(sla);
    }
    console.log('DEBUG: MaintenancesReportsComponent -> getSLAStats -> data', data);

    this.chartsData.sla = {
      datasets: [
        { label: 'SLA médio', data },
      ],
      labels,
      ready: true,
      legend: this.chartsData.sla.legend,
    };
  }

  async exportExcel() {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Manutenções ' + this.filters.startDate + ' a ' + this.filters.endDate);

    worksheet.columns = [
      { header: 'ID', key: 'id'},
      { header: 'Num. OS', key: 'serviceNumber'},
      { header: 'Apto.', key: 'roomNumber'},
      { header: 'Relator', key: 'userName'}, // <<
      { header: 'Área', key: 'areaName'}, // <<
      { header: 'Tipo', key: 'type'}, // t
      { header: 'Problema', key: 'problem'}, // <<
      { header: 'Descrição', key: 'comments'}, // <<
      { header: 'Status', key: 'status'}, // t
      { header: 'Criado em', key: 'createdDate'},
      { header: 'Finalizado em', key: 'finishedDate'}, // t
      { header: 'Aprovado em', key: 'approvedDate'}, // t
      { header: 'Funcionário', key: 'employeeName'}, // <<
      { header: 'Comentário interno (admin)', key: 'adminComment'},
      { header: 'Comentário interno (funcinoário)', key: 'employeeComment'},
      { header: 'Resposta (p/ morador)', key: 'replyComment'},
      { header: 'Foto enviada', key: 'photoUrl'},
      { header: 'Foto respondida', key: 'replyPhotoUrl'},
      { header: 'Duração de resolução', key: 'resolutionDuration'},
      { header: 'Duração de serviço', key: 'serviceDuration'},
    ];

    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true };
    });
    worksheet.views = [
      { state: 'frozen', xSplit: 1, ySplit: 1, activeCell: 'B2' },
    ];

    const rows = this.allMaintenances.map(m => {
      console.log('DEBUG: MaintenancesReportsComponent -> exportExcel -> m', m);
      return {
        id: m.id,
        serviceNumber: m.serviceNumber,
        roomNumber: m.roomNumber || '',
        userName: m.user && m.user.guest ? m.user.guest.fullName : m.user ? m.user.name : '',
        areaName: m.area ? m.area.name : '',
        type: maintenanceTypeTranslator(m.type),
        problem: m.subcategory ? m.subcategory.name : '',
        comments: m.comments,
        status: m.statusBadge.text,
        createdDate: m.createdDate? formatDate(m.createdDate, 'dd/MM/yyyy HH:mm', 'pt-PT') : '',
        finishedDate: m.finishedDate? formatDate(m.finishedDate, 'dd/MM/yyyy HH:mm','pt-PT') : '',
        approvedDate: m.approvedDate? formatDate(m.approvedDate, 'dd/MM/yyyy HH:mm', 'pt-PT') : '',
        employee: m.employee ? m.employee.fullName : '',
        adminComment: m.adminComment,
        employeeComment: m.employeeComment,
        replyComment: m.replyComment,
        photoUrl: m.photoUrl,
        replyPhotoUrl: m.replyPhotoUrl,
        resolutionDuration: m.finishedDate? differenceInMinutes(m.finishedDate, m.createdDate) : '',
        serviceDuration: m.finishedDate && m.startedDate? differenceInMinutes(m.finishedDate, m.startedDate) : '',
      };
    });

    worksheet.addRows(rows);

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    downloadFile(blob, 'Manutenções ' + this.hotelInfo.name + ' (' + this.filters.startDate + ' a ' + this.filters.endDate + ')');
  }

  downloadChartImage(id: string, chartData: any) {
    /*Get image of canvas element*/
    const prevLegend = chartData.legend;
    chartData.legend = true;
    (document.getElementById(id) as HTMLCanvasElement).toBlob((blob) => {
      downloadFile(blob, id);
      chartData.legend = prevLegend;
    });
  }

}

export function getGroupedByKey(arr: any[], key: string) {
  return arr.reduce((r, a) => {
    r[a[key]] = r[a[key]] || [];
    r[a[key]].push(a);
    return r;
  }, Object.create(null));
}


export function getGroupedByFunction(arr: any[], fn: (item: any) => string) {
  return arr.reduce((r, a) => {
    const key = fn(a);
    console.log('DEBUG: getGroupedByFunction -> key', key);
    r[key] = r[key] || [];
    r[key].push(a);
    return r;
  }, Object.create(null));
}

export function getIntervalLabel(date: Date | string, interval: ('HOUR' | 'DAY' | 'WEEK' | 'MONTH')) {
  date = new Date(date);

  switch (interval) {
    case 'HOUR':
      return formatDate(date, 'HH', 'pt-PT');
    case 'DAY':
      return formatDate(date, 'd MMM', 'pt-PT');
    case 'WEEK':
      return formatDate(startOfWeek(date), 'd MMM', 'pt-PT');
    case 'MONTH':
      return formatDate(date, 'MMM', 'pt-PT');
    default:
      return formatDate(date, 'd MMM', 'pt-PT');
  }
}

export function getIntervalPeriods(minDate: Date, maxDate: Date, interval: ('HOUR' | 'DAY' | 'WEEK' | 'MONTH')) {
  const periods: string[] = [];
  switch (interval) {
    case 'HOUR':
      for (let dt = minDate; dt <= maxDate; dt = addHours(dt, 1)) {
        periods.push(getIntervalLabel(dt, interval));
      }
    break;
    case 'DAY':
      for (let dt = minDate; dt <= maxDate; dt = addDays(dt, 1)) {
        periods.push(getIntervalLabel(dt, interval));
      }
    break;
    case 'WEEK':
      for (let dt = minDate; dt <= maxDate; dt = addWeeks(dt, 1)) {
        periods.push(getIntervalLabel(dt, interval));
      }
    break;
    case 'MONTH':
      for (let dt = minDate; dt <= maxDate; dt = addMonths(dt, 1)) {
        periods.push(getIntervalLabel(dt, interval));
      }
    break;
    default:
      for (let dt = minDate; dt <= maxDate; dt = addDays(dt, 1)) {
        periods.push(getIntervalLabel(dt, interval));
      }
    break;
  }

  return periods;
}

export function getMean(data: any[]) {
    return data.reduce((a, b) => {
        return Number(a) + Number(b);
    }) / data.length;
}

export function getStandartDeviation(data: any[]) {
  const m = getMean(data);
  return Math.sqrt(data.reduce(function (sq, n) {
          return sq + Math.pow(n - m, 2);
      }, 0) / (data.length - 1));
}

export function downloadFile(blob: Blob, fileName: string) {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.setAttribute('style', 'display: none');
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
}
