import { Component, OnInit } from '@angular/core';
import { Unit } from 'src/app/entities/units/unit.entity';
import { AuthService } from 'src/app/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NavService } from 'src/app/shared/service/nav.service';
import { CleaningService, CleaningQR } from '../cleaning.service';

@Component({
  selector: 'app-qr-code-cleaning',
  templateUrl: './qr-code-cleaning.component.html',
  styleUrls: ['./qr-code-cleaning.component.scss']
})
export class QrCodeCleaningComponent implements OnInit {

  hotelInfo: Unit;
  loading = false;


  cleaningQR: CleaningQR = {
    type: 'room',
    hotelCode: null,
  };

  codeQRencoded = '';
  codeSRC = '';
  constructor(
    public authService: AuthService,
    public router: Router,
    public route: ActivatedRoute,
    public navService: NavService,
    public cleaningService: CleaningService) { }

  async ngOnInit() {
    this.hotelInfo = this.navService.hotelInfo;

    this.cleaningQR.hotelCode = this.hotelInfo.hotelCode;

  }

  calcCode() {
    console.log('DEBUG: QrCodeCleaningComponent -> calcCode -> this.cleaningQR', this.cleaningQR);
    if (this.cleaningQR.type === 'area') {
      delete this.cleaningQR.roomId;
    } else if (this.cleaningQR.type === 'room') {
      delete this.cleaningQR.areaId;
    }
    console.log('DEBUG: QrCodeCleaningComponent -> calcCode -> this.cleaningQR', this.cleaningQR);
    this.loading = true;
    this.cleaningService.generateQR(this.cleaningQR).then((resp) => {
      this.codeQRencoded = resp.QR;
      setTimeout(() => {
        this.codeSRC = document.querySelector('#QR img').getAttribute('src');
      }, 100);
      this.loading = false;
    });
  }

  printQR() {
    const img = document.querySelector('#QR img');
    img.setAttribute('style', 'display: initial;');

    const popup = window.open();
    popup.document.write(`<div style='display: table-cell;
                                      vertical-align: middle;
                                      height: 100vh;
                                      text-align: center;
                                      width: 100vw;
                                      color: rgb(241, 128, 65);'>`);
    popup.document.write(img.outerHTML);
    // tslint:disable-next-line: max-line-length
    popup.document.write(`<p><img src="https://i.imgur.com/F0zipA9.png" height="20" style="position: relative;top: 5px;"/> <b>Uliving</b> - `);
    popup.document.write(
      this.cleaningQR.type === 'room' ?
        ('Quarto ' + this.cleaningQR.roomId) :
        ('Área: ' + this.cleaningQR.areaId)
    );
    popup.document.write(`</p></div>`);
    popup.focus();
    setTimeout(() => {
      popup.print();
    }, 500);
  }

}
