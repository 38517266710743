import { Component, OnInit } from '@angular/core';
import { Unit } from '../../entities/units/unit.entity';
import { VisitsService, Visits } from '../visitor.service';
import { ActivatedRoute } from '@angular/router';
import { NavService } from '../../shared/service/nav.service';
import { Room } from 'src/app/entities/rooms/room.entity';
import { Pipe, PipeTransform } from '@angular/core';
import { Visitor } from '../visitor.service';

@Component({
  selector: 'app-list-visitor',
  templateUrl: './list-visitor.component.html',
  styleUrls: ['./list-visitor.component.scss'],
})
export class ListVisitorsComponent implements OnInit {
  visitors: Visitor[] = [];
  loading = true;
  hotelInfo: Unit;

  pagination = {
    page: 1,
    limit: 15,
    pages: 5,
    collectionSize: 75,
  };

  constructor(public route: ActivatedRoute, public navService: NavService, public service: VisitsService) {}

  ngOnInit() {
    this.navService.currentUnit$.subscribe(unit => {
      this.hotelInfo = unit;

      this.getAll();
    });
  }

  async getAll() {
    this.loading = true;
    if (!this.hotelInfo) {
      this.loading = false;
      return;
    }
    const res = await this.service.getAllVisitors(this.hotelInfo.hotelCode, this.pagination.page, this.pagination.limit).catch((e): any => {
      alert('Erro ao buscar todos os quartos');
      this.loading = false;
      console.error(e);
      return {};
    });
    console.log(res);
    this.visitors = res.data;
    this.pagination.pages = res.totalPages;
    this.pagination.collectionSize = res.totalPages * this.pagination.limit;
    this.loading = false;
  }
}
