import { Statement } from '@angular/compiler';
import { DeepPartial } from '../rest.entity';

export enum ItemKind {
  MONTHLY_PAYMENT = 'MONTHLY_PAYMENT',
  DISCOUNT = 'DISCOUNT',
  CLEANING = 'CLEANING',
  FINE = 'FINE',
  KEY_LOST = 'KEY_LOST',
  EXTRA_RESIDENT = 'EXTRA_RESIDENT',
  TV_INSTALLATION = 'TV_INSTALLATION',
  OTHERS = 'OTHERS',
  ENERGY = 'ENERGY',
  EXTRA_PAYPAL = 'EXTRA_PAYPAL',
}

export function translateItemKind(itemKind: ItemKind) {
  switch (itemKind) {
    case ItemKind.MONTHLY_PAYMENT:
      return 'Mensalidade';
    case ItemKind.DISCOUNT:
      return 'Descontos';
    case ItemKind.CLEANING:
      return 'Limpeza';
    case ItemKind.FINE:
      return 'Multa';
    case ItemKind.KEY_LOST:
      return 'Perda de Chave';
    case ItemKind.EXTRA_RESIDENT:
      return 'Morador Extra';
    case ItemKind.TV_INSTALLATION:
      return 'Instalação TV';
    case ItemKind.OTHERS:
      return 'Diversos';
    case ItemKind.ENERGY:
      return 'Energia';
    case ItemKind.EXTRA_PAYPAL:
      return 'Extra PayPal';
  }
}

export class StatementItem {
  constructor(obj?: DeepPartial<StatementItem>) {
    Object.assign(this, obj);
  }

  itemKind: ItemKind;

  value: number;

  amount: number;

  statement: Statement;

  id?: number;

  createdDate?: Date;

  updatedDate?: Date;
}
