import { Injectable } from '@angular/core';
import { APIService } from '../api.service';
import { Schedule, Attendance } from '../entities/spaces/schedule.entity';
import { User } from '../entities/residents/user.entity';

@Injectable({
  providedIn: 'root'
})
export class SchedulesService {

  constructor(
    public API: APIService
  ) { }

  async getAllFromUnit(hotelCode: number, page = 1, startDate) {
    const schedules = await this.API.get<Schedule[]>(`/schedules/all/${hotelCode}`, {
      page,
      startDate: new Date(startDate).toISOString(),
    }, {tokenRequired: true});
    return schedules.map(s => new Schedule(s));
  }

  async getFutureFromUnit(hotelCode: number, page: number = 1, excludeWithEvent = true) {
    const schedules = await this.API.get<Schedule[]>(`/schedules/future/${hotelCode}`, {page, excludeWithEvent}, {tokenRequired: true});
    return schedules.map(s => new Schedule(s));
  }

  create(schedule: Schedule) {
    return this.API.post<Schedule, Schedule>('/schedules', schedule, {tokenRequired: true});
  }

  update(schedule: Schedule, id: Number) {
    return this.API.patch<Schedule, Schedule>(`/schedules/${id}`, schedule, {tokenRequired: true});
  }

  async read(scheduleId: number) {
    const [schedule, attendances] = await Promise.all([
      this.API.get<Schedule>(`/schedules/${scheduleId}`, {}, {tokenRequired: true}),
      this.getAttendances(scheduleId),
    ]);
    schedule.attendances = attendances.map(a => {
      a.user = new User(a.user);
      return a;
    });

    return schedule;

  }

  delete(scheduleId: number) {
    return this.API.delete<Schedule>(`/schedules/${scheduleId}`, {}, {tokenRequired: true});
  }

  getAttendances(scheduleId: number) {
    return this.API.get<Attendance[]>(`/schedules/${scheduleId}/attendances`, {}, {tokenRequired: true});
  }
}
