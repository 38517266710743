import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { CookieService } from 'ngx-cookie-service';
import { User } from './entities/residents/user.entity';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment';

const TOKEN_KEY = 'ULIV_DASH_ACCESS_TOKEN';
export interface APISuccessResponse<R = any> {
  success: boolean;
  data: R;
}

export interface APIPaginationdResponse<R = any> {
  totalPages: number;
  data: R[];
  resultsCount?: number;
}

export interface APIGetManyResponse<R = any> {
  data: {
    topics: R[];
  };
  pageInfo: {
    hasNextPage: boolean;
    totalCount: number;
    totalPages: {
      numberOfPages: number;
    };
  };
}

export interface APIError {
  statusCode: number;
  error: string;
  message: string;
}

export interface APIRequestOptions {
  tokenRequired?: boolean;
  hideLoading?: boolean;
}

export interface APIHeaders {
  [header: string]: string;
}

@Injectable({
  providedIn: 'root',
})
export class APIService {
  private accessToken: string = undefined;
  public loggedUser$: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);
  constructor(private http: HttpClient, private cookies: CookieService, public router: Router, public toast: ToastrService) {}

  async post<T = object, R = APISuccessResponse<T>, E = APIError>(endpoint: string, body: T, options: APIRequestOptions = { tokenRequired: false }) {
    if (!options.hideLoading) {
      this.showLoading();
    }

    const headers: APIHeaders = {};
    this.accessToken = this.cookies.get(TOKEN_KEY);
    if (options.tokenRequired) {
      if (this.accessToken) {
        headers.Authorization = 'Bearer ' + this.getAccessToken();
      } else {
        this.router.navigateByUrl('login');
        console.error('Unauthorized post', endpoint, body);
        return;
      }
    }
    return new Promise<R>((resolve, reject: (e: E) => void) => {
      this.http.post<R>(this.BASE_URL + endpoint, body, { headers }).subscribe(
        resp => {
          if (!options.hideLoading) {
            this.hideLoading();
          }
          resolve(resp);
        },
        (error: HttpErrorResponse) => {
          if (!options.hideLoading) {
            this.hideLoading();
          }
          if (error.status == 401 || error.error.statusCode == 401) {
            return this.router.navigate(['/login']);
          }
          reject(error.error);
        },
      );
    });
  }

  async get<R = APISuccessResponse, E = APIError>(
    endpoint: string,
    params?: { [param: string]: string | string[] | number | boolean },
    options: APIRequestOptions = { tokenRequired: false, hideLoading: false },
  ) {
    if (!options.hideLoading) {
      this.showLoading();
    }

    const headers: APIHeaders = {};
    this.accessToken = this.cookies.get(TOKEN_KEY);
    if (options.tokenRequired) {
      if (this.accessToken) {
        headers.Authorization = 'Bearer ' + this.getAccessToken();
      } else {
        this.router.navigateByUrl('login');
        console.error('No access token', endpoint, params);
        return;
      }
    }

    return new Promise<R>((resolve, reject) => {
      this.http.get<R>(this.BASE_URL + endpoint, { params: params as any, headers }).subscribe(
        resp => {
          if (!options.hideLoading) {
            this.hideLoading();
          }
          resolve(resp);
        },
        (error: HttpErrorResponse) => {
          if (!options.hideLoading) {
            this.hideLoading();
          }
          if (error.status == 401 || error.error.statusCode == 401) {
            return this.router.navigate(['/login']);
          }
          reject(error.error);
        },
      );
    });
  }

  async delete<R = APISuccessResponse, E = APIError>(
    endpoint: string,
    params?: { [param: string]: string | string[] | number | boolean },
    options: APIRequestOptions = { tokenRequired: false, hideLoading: false },
  ) {
    if (!options.hideLoading) {
      this.showLoading();
    }

    const headers: APIHeaders = {};
    this.accessToken = this.cookies.get(TOKEN_KEY);
    if (options.tokenRequired) {
      if (this.accessToken) {
        headers.Authorization = 'Bearer ' + this.getAccessToken();
      } else {
        this.router.navigateByUrl('login');
        console.error('Unauthorized action', endpoint, params);
        return;
      }
    }

    return new Promise<R>((resolve, reject) => {
      this.http.delete<R>(this.BASE_URL + endpoint, { params: params as any, headers }).subscribe(
        resp => {
          if (!options.hideLoading) {
            this.hideLoading();
          }
          resolve(resp);
        },
        (error: HttpErrorResponse) => {
          if (!options.hideLoading) {
            this.hideLoading();
          }
          reject(error.error);
        },
      );
    });
  }

  async patch<T = object, R = APISuccessResponse, E = APIError>(endpoint: string, body: T, options: APIRequestOptions = { tokenRequired: false, hideLoading: false }) {
    if (!options.hideLoading) {
      this.showLoading();
    }

    const headers: APIHeaders = {};
    this.accessToken = this.cookies.get(TOKEN_KEY);
    if (options.tokenRequired) {
      if (this.accessToken) {
        headers.Authorization = 'Bearer ' + this.getAccessToken();
      } else {
        this.router.navigateByUrl('login');
        console.error('Unauthorized action', endpoint, body);
        return;
      }
    }
    return new Promise<R>((resolve, reject) => {
      this.http.patch<R>(this.BASE_URL + endpoint, body, { headers }).subscribe(
        resp => {
          if (!options.hideLoading) {
            this.hideLoading();
          }
          resolve(resp);
        },
        (error: HttpErrorResponse) => {
          if (!options.hideLoading) {
            this.hideLoading();
          }
          if (error.status == 401 || error.error.statusCode == 401) {
            return this.router.navigate(['/login']);
          }
          reject(error.error);
        },
      );
    });
  }

  async put<T = object, R = APISuccessResponse, E = APIError>(endpoint: string, body: T, options: APIRequestOptions = { tokenRequired: false, hideLoading: false }) {
    if (!options.hideLoading) {
      this.showLoading();
    }
    const headers: APIHeaders = {};
    this.accessToken = this.cookies.get(TOKEN_KEY);
    if (options.tokenRequired) {
      if (this.accessToken) {
        headers.Authorization = 'Bearer ' + this.getAccessToken();
      } else {
        this.router.navigateByUrl('login');
        console.error('Unauthorized action', endpoint, body);
        return;
      }
    }
    return new Promise<R>((resolve, reject) => {
      this.http.put<R>(this.BASE_URL + endpoint, body, { headers }).subscribe(
        resp => {
          if (!options.hideLoading) {
            this.hideLoading();
          }
          resolve(resp);
        },
        (error: HttpErrorResponse) => {
          if (!options.hideLoading) {
            this.hideLoading();
          }
          if (error.status == 401 || error.error.statusCode == 401) {
            return this.router.navigate(['/login']);
          }
          reject(error.error);
        },
      );
    });
  }

  async uploadFile<R = any>(endpoint: string, formData: FormData, options: APIRequestOptions = { tokenRequired: true, hideLoading: false }) {
    if (!options.hideLoading) {
      this.showLoading();
    }
    const headers: APIHeaders = {};
    this.accessToken = this.cookies.get(TOKEN_KEY);
    if (options.tokenRequired) {
      if (this.accessToken) {
        headers.Authorization = 'Bearer ' + this.getAccessToken();
      } else {
        this.router.navigateByUrl('login');
        console.error('Unauthorized action', endpoint);
        return;
      }
    }
    return new Promise<R>((resolve, reject) => {
      this.http.post<R>(this.BASE_URL + endpoint, formData, { headers }).subscribe(
        resp => {
          if (!options.hideLoading) {
            this.hideLoading();
          }
          resolve(resp);
        },
        (error: HttpErrorResponse) => {
          if (!options.hideLoading) {
            this.hideLoading();
          }
          if (error.status == 401 || error.error.statusCode == 401) {
            return this.router.navigate(['/login']);
          }
          reject(error.error);
        },
      );
    });
  }

  showLoading() {
    Swal.fire({
      titleText: 'Carregando...',
      html: '<div class="rotate dotted" style="margin: 25px auto; float: none"></div>',
      backdrop: true,
      showCloseButton: false,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      showCancelButton: false,
    });
  }

  hideLoading() {
    Swal.close();
  }

  setAccessToken(token: string) {
    this.accessToken = token;
    this.cookies.set(TOKEN_KEY, token, 1);
  }

  saveUser(user: User) {
    localStorage.setItem('ULIV_USER', JSON.stringify(user));
  }

  getSavedUser() {
    const userString = localStorage.getItem('ULIV_USER');
    if (userString) {
      const user = JSON.parse(userString);
      this.setLoggedUser(user);
    }
  }

  setLoggedUser(user: User) {
    this.loggedUser$.next(new User(user));
    this.saveUser(user);
  }

  getLoggedUser() {
    return this.loggedUser$.asObservable();
  }

  getAccessToken() {
    return this.accessToken;
  }

  hasSession() {
    return this.accessToken !== undefined && this.accessToken !== null && this.accessToken !== 'undefined';
  }

  clearSession() {
    return new Promise((resolve, reject) => {
      this.cookies.deleteAll();
      localStorage.clear();
      this.accessToken = undefined;
      this.loggedUser$.next(undefined);

      setTimeout(() => {
        resolve();
      }, 350);
    });
  }

  loadSession() {
    if (!this.cookies.check(TOKEN_KEY)) {
      this.setAccessToken(undefined);
      this.saveUser(null);
      return undefined;
    }

    this.accessToken = this.cookies.get(TOKEN_KEY);
    if (!this.accessToken) {
      this.setAccessToken(undefined);
      this.saveUser(null);
    } else {
      this.getSavedUser();
    }
    return this.accessToken;
  }

  /** @TODO switch by env */
  get BASE_URL() {
    return environment.BASE_API_URL;
  }
}

export function showDefaultErrorAlert(error: APIError) {
  const alert: SweetAlertOptions = {
    titleText: 'Erro',
    type: 'error',
    showConfirmButton: true,
    confirmButtonText: 'Ok',
    showCloseButton: true,
    onClose: () => {},
  };
  if (error.statusCode === 500) {
    alert.titleText = 'Falha no servidor';
    alert.text = 'Tente novamente ou contate a equipe de desenvolvimento. - (' + error.error + ')';
  } else if (error.statusCode === 401) {
    alert.titleText = 'Não autorizado';
    alert.text = 'Faça login para executar essa ação - (' + error.error + ')';
  } else if (error.statusCode === 403) {
    alert.titleText = 'Operação não permitida.';
    alert.text = 'Você não tem permissão para executar essa tarefa - (' + error.error + ')';
  } else if (error.statusCode === 404) {
    alert.titleText = 'Não encontrado';
    alert.text = 'Página ou dado não encontrado - (' + error.error + ')';
  } else if (error.statusCode === 400) {
    alert.titleText = 'Ops! Há dados incorretos';
    if (error.message && Array.isArray(error.message)) {
      const messages = (error.message as Array<any>)
        .map(e => e.constraints)
        .map(c => Object.values(c))
        .reduce((a, b) => a.concat(b));
      alert.html = `
        <b>${error.error}</b>
        <ul style="text-align: left; list-style: circle; padding: 10px 30px;">
          ${messages.map(m => '<li>' + m + '</li>').join('')}
        </ul>
      `;
    } else {
      alert.html = `
        <b>${error.error}</b>
        <ul style="text-align: left; list-style: circle; padding: 10px 30px;">
          <li>${error.message}</li>
        </ul>
      `;
    }
  }
  return Swal.fire(alert);
}

export function showCustomErrorAlert(info: { title?: string; text?: string }) {
  const alert: SweetAlertOptions = {
    titleText: info.title || 'Erro',
    type: 'error',
    showConfirmButton: true,
    confirmButtonText: 'Ok',
    showCloseButton: true,
    text: info.text || 'Ocorreu um problema ao executar essa ação. Por favor, tente novamente ou contate a equipe de desenvolvimento.',
    onClose: () => {},
  };

  return Swal.fire(alert);
}

export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}

export function srcToFile(src: string, fileName: string, mimeType: string) {
  return fetch(src)
    .then(function(res) {
      return res.arrayBuffer();
    })
    .then(function(buf) {
      return new File([buf], fileName, { type: mimeType });
    });
}
