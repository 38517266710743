import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Guest, Gender } from '../../entities/residents/guest.entity';
import { ResidentsService } from '../residents.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { NavService } from '../../shared/service/nav.service';
import { Unit } from '../../entities/units/unit.entity';
import { UserRole, User } from '../../entities/residents/user.entity';
import { DocumentType, ReservationStatus } from '../../entities/residents/totvs.interfaces';
import { RoomsService } from '../../rooms/rooms.service';
import { Room } from '../../entities/rooms/room.entity';

@Component({
  selector: 'app-residents-form',
  templateUrl: './residents-form.component.html',
  styleUrls: ['./residents-form.component.scss'],
})
export class ResidentsFormComponent implements OnInit {
  form: FormGroup;
  resident: Guest = new Guest();
  loading = false;
  errorMessage: string;
  errorCode: number;
  hotelInfo: Unit;
  GuestCode: number;
  availableRooms: Room[] = [];
  roles = [{ key: UserRole.Guest, label: 'Morador' }];
  gender = [{ key: Gender.MALE, label: 'Masculino' }, { key: Gender.FEMALE, label: 'Feminino' }];
  documentType = [
    { key: DocumentType.None, label: 'Nenhum' },
    { key: DocumentType.CPF, label: 'CPF' },
    { key: DocumentType.CivilIdentification, label: 'RG' },
    { key: DocumentType.BirthCertificate, label: 'Cert. Nascimento' },
    { key: DocumentType.Passport, label: 'Passaporte' },
  ];
  reservationStatus = [
    { key: ReservationStatus.Confirmed, label: 'Confirmado' },
    { key: ReservationStatus.ConfirmedWithChanges, label: 'Confirmado com mudanças' },
    { key: ReservationStatus.Cancelled, label: 'Cancelado' },
    { key: ReservationStatus.CheckedIn, label: 'Checkin' },
    { key: ReservationStatus.CheckInConfirmed, label: 'Checkin confirmado' },
    { key: ReservationStatus.CheckInNotAccepted, label: 'Checkin rejeitado' },
    { key: ReservationStatus.CheckedOut, label: 'Checkout' },
    { key: ReservationStatus.CheckOutConfirmed, label: 'Checkout confirmado' },
    { key: ReservationStatus.CheckOutNotAccepted, label: 'Checkout rejeitado' },
    { key: ReservationStatus.NoShow, label: 'Ausente' },
  ];
  mainGuest = [{ key: 1, label: 'Sim' }, { key: 0, label: 'Não' }];
  constructor(
    private formBuilder: FormBuilder,
    public service: ResidentsService,
    public router: Router,
    public route: ActivatedRoute,
    public navService: NavService,
    private roomsSrvc: RoomsService,
  ) {}

  ngOnInit() {
    this.hotelInfo = this.navService.hotelInfo;
    this.route.params.subscribe(params => {
      this.GuestCode = params.residentId;

      if (params.residentId) {
        this.initCreateForm();
        this.initUpdateForm();
      } else {
        this.initCreateForm();
      }
    });

    this.loadAvailableRooms();
  }

  async loadAvailableRooms() {
    const rooms: Room[] = await this.roomsSrvc.getAllFromUnit(this.hotelInfo.hotelCode);

    if (this.GuestCode) {
      this.availableRooms = rooms.filter(room => !room.guest || room.guest.GuestCode.toString() === this.GuestCode.toString());
    } else {
      this.availableRooms = rooms;
    }
  }

  initCreateForm() {
    this.form = this.formBuilder.group({
      user: new FormGroup({
        role: new FormControl(null, Validators.required),
      }),
      GivenName: [null, [Validators.required, Validators.minLength(2)]],
      SurName: [null, [Validators.required, Validators.minLength(3)]],
      PhoneNumber: [null, Validators.minLength(8)],
      CelPhoneNumber: [null, Validators.minLength(8)],
      MainGuest: [null, Validators.required],
      Profession: [null, []],
      BirthDate: [null, [Validators.required]],
      DocumentType: [null, [Validators.required]],
      DocumentNumber: [null, [Validators.required]],
      Nationality: [null, [Validators.required, Validators.minLength(2)]],
      Checkin: [null, [Validators.required]],
      Checkout: [null, [Validators.required]],
      organization: [null, []],
      course: [null, []],
      Email: ['@uliving.com.br', [Validators.required, Validators.email]],
      Gender: [null, [Validators.required]],
      ReservationStatus: [null, [Validators.required]],
      roomNumber: [null, [Validators.required]],
      GuestAddress: new FormGroup({
        StreetName: new FormControl(null),
        AddressNumber: new FormControl(null),
        Complement: new FormControl(null),
        PostalCode: new FormControl(null),
        District: new FormControl(null),
        CityName: new FormControl(null),
        StateName: new FormControl(null),
        StateCode: new FormControl(null),
        CountryName: new FormControl(null),
      }),
      isUleader: [false, []],
    });
  }

  initUpdateForm() {
    this.loading = true;
    this.service.read(this.GuestCode).then(
      res => {
        this.loading = false;
        this.errorMessage = null;

        this.resident = res;

        console.log(res);

        this.form.patchValue({
          ['user']: {
            role: res.user ? res.user.role : UserRole.Guest,
          },
          ['GuestAddress']: {
            StreetName: res.GuestAddress.StreetName,
            AddressNumber: res.GuestAddress.AddressNumber,
            Complement: res.GuestAddress.Complement,
            PostalCode: res.GuestAddress.PostalCode,
            District: res.GuestAddress.District,
            CityName: res.GuestAddress.CityName,
            StateName: res.GuestAddress.StateName,
            StateCode: res.GuestAddress.StateCode,
            CountryName: res.GuestAddress.CountryName,
          },
          ['GivenName']: res.GivenName,
          ['SurName']: res.SurName,
          ['PhoneNumber']: res.PhoneNumber,
          ['CelPhoneNumber']: res.CelPhoneNumber,
          ['MainGuest']: res.MainGuest,
          ['Profession']: res.Profession,
          ['BirthDate']: res.BirthDate,
          ['DocumentType']: res.DocumentType,
          ['DocumentNumber']: res.DocumentNumber,
          ['Nationality']: res.Nationality,
          ['Checkin']: res.Checkin ? res.Checkin.substr(0, 10) : null,
          ['Checkout']: res.Checkout ? res.Checkout.substr(0, 10) : null,
          ['organization']: res.organization,
          ['course']: res.course,
          ['Email']: res.Email,
          ['Gender']: res.Gender,
          ['ReservationStatus']: res.ReservationStatus,
          ['roomNumber']: res.rooms && res.rooms[0] ? res.rooms[0].roomNumber : null,
        });
      },
      err => {
        this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
      },
    );
  }

  submit() {
    console.log(this.form);
    if (this.GuestCode) {
      this.update();
    } else {
      this.create();
    }
  }

  update() {
    this.loading = true;
    const guest = new Guest({
      HotelCode: this.hotelInfo.hotelCode,
      GivenName: this.form.value.GivenName,
      SurName: this.form.value.SurName,
      PhoneNumber: this.form.value.PhoneNumber,
      CelPhoneNumber: this.form.value.CelPhoneNumber,
      MainGuest: this.form.value.MainGuest,
      Profession: this.form.value.Profession,
      BirthDate: this.form.value.BirthDate,
      DocumentType: this.form.value.DocumentType,
      DocumentNumber: this.form.value.DocumentNumber,
      Nationality: this.form.value.Nationality,
      Checkin: this.form.value.Checkin,
      Checkout: this.form.value.Checkout,
      organization: this.form.value.organization,
      course: this.form.value.course,
      Email: this.form.value.Email,
      Gender: this.form.value.Gender,
      ReservationStatus: this.form.value.ReservationStatus,
      GuestCode: this.resident.GuestCode,
      GuestAddress: {
        StreetName: this.form.value.GuestAddress.StreetName,
        AddressNumber: this.form.value.GuestAddress.AddressNumber,
        Complement: this.form.value.GuestAddress.Complement,
        PostalCode: this.form.value.GuestAddress.PostalCode,
        District: this.form.value.GuestAddress.District,
        CityName: this.form.value.GuestAddress.CityName,
        StateName: this.form.value.GuestAddress.StateName,
        StateCode: this.form.value.GuestAddress.StateCode,
        CountryName: this.form.value.GuestAddress.CountryName,
      },
    });

    if (this.resident.user && !!this.resident.user.id) {
      guest.user = new User({
        name: this.form.value.Name,
        email: this.form.value.Email,
        role: this.form.value.user.role,
        id: this.resident.user.id,
      });
    }

    Object.assign(guest, { RoomNumber: this.form.value.roomNumber });

    console.log('TCL: ResidentsFormComponent -> update -> this.form.value.roomNumber', this.form.value.roomNumber);
    if (this.form.value.roomNumber === 'null') {
      this.loading = false;
      this.form.setErrors({
        roomNumber: true,
      });
      this.errorMessage = 'Um quarto deve ser escolhido';
      return;
    }

    if (this.form.value.MainGuest === 'null') {
      this.loading = false;
      this.form.setErrors({
        mainGuest: true,
      });
      this.errorMessage = 'É necessário selecionar uma opção no campo "Convidado Principal"';
      return;
    }

    this.service.update(guest, this.GuestCode).then(
      () => {
        this.successEdit();
        this.loading = false;
        this.errorMessage = null;
      },
      err => {
        console.error('Error in update employee component', err);
        this.errorAlert(err.message, err.statusCode);
        this.loading = false;
        this.errorCode = err.statusCode;
        if (err.statusCode === 400) {
          this.errorMessage = 'Ops! Verifique os campos abaixo';
        } else if (err.statusCode === 409) {
          this.errorMessage = 'Este email já está registrado para outro usuário. Por favor, escolha outro.';
        } else {
          this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
        }
      },
    );
  }

  create() {
    this.loading = true;
    const guest = new Guest({
      HotelCode: this.hotelInfo.hotelCode,
      GivenName: this.form.value.GivenName,
      SurName: this.form.value.SurName,
      PhoneNumber: this.form.value.PhoneNumber,
      CelPhoneNumber: this.form.value.CelPhoneNumber,
      MainGuest: this.form.value.MainGuest,
      Profession: this.form.value.Profession,
      BirthDate: this.form.value.BirthDate,
      DocumentType: this.form.value.DocumentType,
      DocumentNumber: this.form.value.DocumentNumber,
      Nationality: this.form.value.Nationality,
      Checkin: this.form.value.Checkin,
      Checkout: this.form.value.Checkout,
      organization: this.form.value.organization,
      course: this.form.value.course,
      Email: this.form.value.Email,
      Gender: this.form.value.Gender,
      ReservationStatus: this.form.value.ReservationStatus,
      GuestAddress: {
        StreetName: this.form.value.GuestAddress.StreetName,
        AddressNumber: this.form.value.GuestAddress.AddressNumber,
        Complement: this.form.value.GuestAddress.Complement,
        PostalCode: this.form.value.GuestAddress.PostalCode,
        District: this.form.value.GuestAddress.District,
        CityName: this.form.value.GuestAddress.CityName,
        StateName: this.form.value.GuestAddress.StateName,
        StateCode: this.form.value.GuestAddress.StateCode,
        CountryName: this.form.value.GuestAddress.CountryName,
      },
      user: {
        name: this.form.value.GivenName + ' ' + this.form.value.SurName,
        email: this.form.value.Email,
        role: this.form.value.user.role,
      },
    });

    Object.assign(guest, { RoomNumber: this.form.value.roomNumber });
    console.log('TCL: ResidentsFormComponent -> update -> this.form.value.roomNumber', this.form.value.roomNumber);

    if (this.form.value.roomNumber === 'null') {
      this.loading = false;
      this.form.setErrors({
        roomNumber: true,
      });
      this.errorMessage = 'Um quarto deve ser escolhido';
      return;
    }

    if (this.form.value.MainGuest === 'null') {
      this.loading = false;
      this.form.setErrors({
        mainGuest: true,
      });
      this.errorMessage = 'É necessário selecionar uma opção no campo "Convidado Principal"';
      return;
    }

    this.service.create(guest).then(
      res => {
        this.successNew(res);
        this.loading = false;
        this.errorMessage = null;
      },
      err => {
        console.error('Error in create employee component', err);
        this.loading = false;
        this.errorAlert(err.message, err.statusCode);
        this.errorCode = err.statusCode;
        if (err.statusCode === 400) {
          this.errorMessage = 'Ops! Verifique os campos abaixo';
        } else if (err.statusCode === 409) {
          this.errorMessage = 'Este email já está registrado para outro usuário. Por favor, escolha outro.';
        } else {
          this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
        }
        if (err.message && err.message.length > 0) {
          for (const e of err.message) {
            this.form.get(e.property).setErrors({
              error: true
            });
          }
        }
      },
    );
  }

  showError(field?: string) {
    if (!this.form.get(field)) {
      return false;
    }
    return (
      (this.form.get(field).invalid && this.form.get(field).touched) ||
      (!this.form.get(field).touched && this.errorMessage && this.errorMessage.length > 0 && this.errorCode === 400)
    );
  }

  async successNew(res: Guest) {
    return Swal.fire({
      title: 'Usuário criado!',
      type: 'success',
      showConfirmButton: true,
      confirmButtonText: 'Ir para usuário',
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary',
      cancelButtonText: 'Cadastrar outro',
      showCloseButton: true,
      onClose: () => {
        this.goToNew();
      },
    }).then(resp => {
      if (resp.value) {
        this.goToDetails(res.GuestCode);
      } else {
        this.goToNew();
      }
    });
  }

  successEdit() {
    return Swal.fire({
      title: 'Usuário editado!',
      type: 'success',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary',
      cancelButtonText: 'Voltar',
      showCloseButton: true,
      onClose: () => {},
    });
  }

  errorAlert(text?: string, code?: number | string) {
    return Swal.fire({
      titleText: 'Erro - ' + code,
      type: 'error',
      text: text,
    });
  }

  goToList() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'residents']);
  }
  goToDetails(residentId: number) {
    this.router.navigate(['/unit', this.hotelInfo.code, 'residents', residentId]);
  }
  goToNew() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'residents', 'create']);
  }
}
