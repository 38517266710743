import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { CalendarView, CalendarEventAction, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { CalendarEvent } from 'calendar-utils';
import { Subject } from 'rxjs';
import { startOfDay, isSameMonth, isSameDay, endOfDay, getMonth } from 'date-fns';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Unit } from '../../entities/units/unit.entity';
import { NavService } from '../../shared/service/nav.service';
import { SpacesService } from '../spaces.service';
import { Schedule } from 'src/app/entities/spaces/schedule.entity';
import { SchedulesService } from '../schedules.service';
import { ScheduleFormComponent } from '../schedule-form/schedule-form.component';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};
@Component({
  selector: 'app-space-schedule',
  templateUrl: './space-schedule.component.html',
  styleUrls: ['./space-schedule.component.scss']
})
export class SpaceScheduleComponent implements OnInit {

  loading = true;
  hotelInfo: Unit;

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Week;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  lastViewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil event-title-margin text-white float-right"></i>',
      a11yLabel: 'Edit',
      cssClass: 'white',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Clicked', event);
      }
    },
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent<Schedule>[] = [];

  activeDayIsOpen = true;

  constructor(
    private modal: NgbModal,
    public navService: NavService,
    public spacesService: SpacesService,
    public schedulesService: SchedulesService,
  ) {}

  ngOnInit() {
    this.navService.currentUnit$.subscribe((unit) => {
      this.hotelInfo = unit;
      this.loadSchedule();
    });
  }

  loadSchedule() {
    if (!this.hotelInfo) return;
    this.lastViewDate = this.viewDate;
    this.schedulesService.getAllFromUnit(this.hotelInfo.hotelCode, 1, this.viewDate).then((schedules) => {
      this.events = schedules.map(s => this.mapToCalendar(s));
    });
  }

  getColorBySpace(schedule: Schedule) {
    const colors = [
      '#056E38',
      '#087C7C',
      '#0B588B',
      '#0F1599',
      '#5E14A8',
      '#B619B6',
      '#C51F74',
      '#D22625',
      '#DC862C',
      '#E2E633',
      '#8FEF3B',
      '#E9C441',
      '#E24D48',
      '#DC4EB4',
      '#9954D6',
      '#5B74D0',
      '#61C6C9'
    ];

    const id = ((schedule.space.id * 15) % colors.length);
    return colors[id];
  }

  mapToCalendar(schedule: Schedule): CalendarEvent<Schedule> {
    schedule = new Schedule(schedule);
    const editPermit = this.navService.checkPermission('schedule', 'update');
    return {
      start: new Date(schedule.startDate),
      end: new Date(schedule.endDate),
      title: schedule.space.name,
      cssClass: 'schedule',
      meta: schedule,
      actions: editPermit ? this.actions : undefined,
      draggable: false,
      resizable: {
        beforeStart: false,
        afterEnd: false,
      },
      color: {
        secondary: this.getColorBySpace(schedule),
        primary: this.getColorBySpace(schedule),
      },
    };
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map(iEvent => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    const scheduleModal = this.modal.open(ScheduleFormComponent, { size: 'lg', centered: true });
    scheduleModal.componentInstance.isModal = true;
    scheduleModal.componentInstance.id = event.meta.id;
    scheduleModal.result.then((res) => {
      if (res.updated) {
        this.loadSchedule();
      }
    });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true
        }
      }
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter(event => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    if (getMonth(this.lastViewDate) !== getMonth(this.viewDate)) {
      this.loadSchedule();
    }
    this.activeDayIsOpen = false;
  }

}
