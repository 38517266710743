import { Component, OnInit, Input } from '@angular/core';
import * as chartJs from 'chart.js';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ChartColors } from '../chart-colors';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {
  constructor(
    public router: Router
  ) { }
  @Input() public height = undefined;
  @Input() public stacked: boolean;
  @Input() public type = 'bar';
  @Input() public maintainAspectRatio = false;
  @Input() public aspectRatio = 2;
  @Input() public stepSize = undefined;

  @Input() public title = '';
  @Input() public canvasId: string;
  @Input() public groups = [];
  @Input() public labels = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  @Input() public legend = true;
  @Input() public legendPosition: chartJs.PositionType = 'bottom';
  @Input() public datasets = [
    {data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A'},
    {data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B'}
  ];

  @Input() public colors = [
    ChartColors.yellow,
    ChartColors.purple,
    ChartColors.blue,
    ChartColors.pink,
    ChartColors.green,
  ];
  @Input() public options: chartJs.ChartOptions = {
    responsive: true,
    aspectRatio: this.aspectRatio,
    maintainAspectRatio: this.maintainAspectRatio,
    onClick: (event, activeElements: any) => {
      console.log('EVENTO', event);
      console.log('ELEMTNOS ATIVOS', activeElements);
      if (!activeElements || activeElements.length === 0) {
        return;
      }
      const groupIndex = activeElements[0]._index;
      const group = this.groups[groupIndex];
      const textValue = activeElements[0]._model.label;
    },
    hover: {
      onHover: function(e: any) {
         const point = this.getElementAtEvent(e);
         if (point.length) {
           e.target.style.cursor = 'pointer';
         } else {
           e.target.style.cursor = 'default';
         }
      }
    },
    legend: {
      display: this.legend,
      position: this.legendPosition,
    },
    scales: {
      xAxes: [{
        stacked: this.stacked,
        barPercentage: 0.9,
        categoryPercentage: 1,
      }],
      yAxes: [{
        stacked: this.stacked,
        ticks: {
          stepSize: this.stepSize,
        }
      }]
    },
  };

  ngOnInit() {
  }
}
