import { Guest } from '../residents/guest.entity';
import { DeepPartial } from '../rest.entity';
import { StatementItem } from './statement-item.entity';

export enum StatementStatus {
  PAID = 'PAID',
  PENDING = 'PENDING',
}

export function translateStatementStatus(status: StatementStatus) {
  switch (status) {
    case StatementStatus.PAID:
      return 'Pago';
    case StatementStatus.PENDING:
      return 'A vencer';
  }
}

export class Statement {
  constructor(obj?: DeepPartial<Statement>) {
    Object.assign(this, obj);
  }

  period: Date | string;

  status: StatementStatus;

  observations?: string;

  guest: Partial<Guest>;

  items?: StatementItem[];

  id?: number;

  createdDate?: Date;

  updatedDate?: Date;
}
