import { NgModule, LOCALE_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule, MASK_OPTIONS } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { FullWidthComponent } from './layouts/full-width/full-width.component';
import { ContentComponent } from './layouts/content/content.component';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {NgxMaskModule, IConfig} from 'ngx-mask';
import { SecureInnerPagesGuard } from './shared/guard/SecureInnerPagesGuard.guard';
import { RoleGuard } from './shared/guard/RoleGuard.guard';
import { CookieService } from 'ngx-cookie-service';
import { LoginComponent } from './auth/login/login.component';
import { EnterpriseComponent } from './layouts/enterprise/enterprise.component';
import { ProfessionalsComponent } from './professionals/professionals.component';
import { EvaluationsComponent } from './evaluations/evaluations.component';
import { ProfessionalComponent } from './professional/professional.component';
import { NewEvaluationComponent } from './new-evaluation/new-evaluation.component';
import { NewProfessionalComponent } from './new-professional/new-professional.component';
import { NgbModule, NgbAlertModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ResidentsModule } from './residents/residents.module';
import { APIService } from './api.service';
import { EmployeesModule } from './employees/employees.module';
import { SuggestionsModule } from './suggestions/suggestions.module';
import { VisitorModule } from './visitor/visitor.module';
import { ErrorPagesModule } from './pages/error-pages/error-pages.module';
import { UnitModule } from './unit/unit.module';
import { ChatModule } from './apps/chat/chat.module';
import { AuthService } from './auth.service';
import localePT from '@angular/common/locales/pt-PT';
import { registerLocaleData } from '@angular/common';
import { EventsModule } from './events/events.module';
import { CleaningModule } from './cleaning/cleaning.module';
import { RoomsModule } from './rooms/rooms.module';
import { AreasModule } from './areas/areas.module';
import { CalendarModule } from 'angular-calendar';
import { ListMaintenancesComponent } from './maintenances/list-maintenances/list-maintenances.component';
import { MaintenanceDetailComponent } from './maintenances/maintenance-detail/maintenance-detail.component';
import { VouchersListComponent } from './orange-club/vouchers-list.component';
import { VoucherFormComponent } from './orange-club/vouchers-form/vouchers-form.component';
import { DragDropDirective } from './rooms/drag-and-drop.directive';
import { VisitorFormComponent } from './visitor/visitor-form/visitor-form.component';
import { RoleRedirect } from './shared/guard/RoleRedirect.guard';
import { BillingModule } from './billing/billing.module';
import { IssueCategoriesModule } from './issue-categories/issue-categories.module';
import { MaintenanceFormComponent } from './maintenances/maitenance-form/maitenance-form.component';
import { MaintenancesReportsComponent } from './maintenances/maintenances-reports/maintenances-reports.component';
import { ChartsComponentsModule } from './shared/charts-components/charts-components.module';
import { ImageToDataUrlModule } from 'ngx-image2dataurl';
import { ListOccurrencesComponent } from './occorrences/list-occurrences/list-occurrences.component';
import { OccurrenceFormComponent } from './occorrences/occurrence-form/occurrence-form.component';
import { OccurrenceDetailComponent } from './occorrences/occurrence-detail/occurrence-detail.component';
import { OccurrenceCategoryModule } from './occurrence-categories/occurrence-categories.module';
import { StatementModule } from './statement/statement.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localePT, 'pt-PT');
@NgModule({
  declarations: [
    AppComponent,
    FullWidthComponent,
    ContentComponent,
    LoginComponent,
    EnterpriseComponent,
    ProfessionalsComponent,
    EvaluationsComponent,
    ProfessionalComponent,
    NewEvaluationComponent,
    NewProfessionalComponent,
    ListMaintenancesComponent,
    MaintenanceDetailComponent,
    MaintenanceFormComponent,
    VouchersListComponent,
    DragDropDirective,
    VoucherFormComponent,
    VisitorFormComponent,
    MaintenancesReportsComponent,
    ListOccurrencesComponent,
    OccurrenceFormComponent,
    OccurrenceDetailComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot(),
    NgbModule,
    ImageToDataUrlModule,
    ResidentsModule,
    EmployeesModule,
    SuggestionsModule,
    VisitorModule,
    BillingModule,
    UnitModule,
    ChatModule,
    NgxMaskModule.forRoot(MASK_OPTIONS),
    ErrorPagesModule,
    NgbAlertModule,
    EventsModule,
    CleaningModule,
    AreasModule,
    RoomsModule,
    IssueCategoriesModule,
    ChartsComponentsModule,
    OccurrenceCategoryModule,
    StatementModule,
  ],
  providers: [
    SecureInnerPagesGuard,
    RoleGuard,
    RoleRedirect,
    CookieService,
    APIService,
    AuthService,
    NgbActiveModal,
    { provide: LOCALE_ID, useValue: 'pt-PT' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
// required for AOT compilation
