
export const ChartColors = {
  yellow: {
    backgroundColor: 'rgba(229, 197, 19, .2)',
    borderColor: 'rgba(229, 197, 19, 1)',
    pointBackgroundColor: 'rgba(229, 197, 19, 1)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(229, 197, 19, 0.5)'
  },
  purple: {
    backgroundColor: 'rgba(56, 7, 102, .2)',
    borderColor: 'rgba(56, 7, 102, 1)',
    pointBackgroundColor: 'rgba(56, 7, 102, 1)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(56, 7, 102, 0.5)'
  },
  green: {
    backgroundColor: 'rgba(44, 96, 54, .2)',
    borderColor: 'rgba(44, 96, 54, 1)',
    pointBackgroundColor: 'rgba(44, 96, 54, 1)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(44, 96, 54, 0.5)'
  },
  blue: {
    backgroundColor: 'rgba(9, 162, 196,, .2)',
    borderColor: 'rgba(9, 162, 196, 1)',
    pointBackgroundColor: 'rgba(9, 162, 196,, 1)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(9, 162, 196,, 0.5)'
  },
  pink: {
    backgroundColor: 'rgba(191, 15, 100,, .2)',
    borderColor: 'rgba(191, 15, 100, 1)',
    pointBackgroundColor: 'rgba(191, 15, 100,, 1)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(191, 15, 100,, 0.5)'
  },
  light: {
    backgroundColor: 'rgba(195, 201, 206, 0.4)',
    hoverBackgroundColor: 'rgba(195, 201, 206, 0.8)',
    borderColor: 'rgba(195, 201, 206, 1)',
    hoverBorderColor: 'rgba(195, 201, 206, 1)',
    pointBackgroundColor: 'rgba(195, 201, 206, 1)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(195, 201, 206, 0.5)'
  },
  info: {
    backgroundColor: 'rgba(64, 153, 255, 0.4)',
    hoverBackgroundColor: 'rgba(64, 153, 255, 0.8)',
    borderColor: 'rgba(64, 153, 255, 1)',
    hoverBorderColor: 'rgba(64, 153, 255, 1)',
    pointBackgroundColor: 'rgba(64, 153, 255, 1)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(64, 153, 255, 0.5)'
  },
  warning: {
    backgroundColor: 'rgba(243, 216, 0, 0.4)',
    hoverBackgroundColor: 'rgba(243, 216, 0, 0.8)',
    borderColor: 'rgba(243, 216, 0, 1)',
    hoverBorderColor: 'rgba(243, 216, 0, 1)',
    pointBackgroundColor: 'rgba(243, 216, 0, 1)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(243, 216, 0, 0.5)'
  },
  muted: {
    backgroundColor: 'rgba(161, 169, 179, 0.4)',
    hoverBackgroundColor: 'rgba(161, 169, 179, 0.8)',
    borderColor: 'rgba(161, 169, 179, 1)',
    hoverBorderColor: 'rgba(161, 169, 179, 1)',
    pointBackgroundColor: 'rgba(161, 169, 179, 1)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(161, 169, 179, 0.5)'
  },
  success: {
    backgroundColor: 'rgba(0, 194, 146, 0.4)',
    hoverBackgroundColor: 'rgba(0, 194, 146, 0.8)',
    borderColor: 'rgba(0, 194, 146, 1)',
    hoverBorderColor: 'rgba(0, 194, 146, 1)',
    pointBackgroundColor: 'rgba(0, 194, 146, 1)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(0, 194, 146, 0.5)'
  },
  danger: {
    backgroundColor: 'rgba(216, 10, 45, 0.4)',
    hoverBackgroundColor: 'rgba(216, 10, 45, 0.8)',
    borderColor: 'rgba(216, 10, 45, 1)',
    hoverBorderColor: 'rgba(216, 10, 45, 1)',
    pointBackgroundColor: 'rgba(216, 10, 45, 1)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(216, 10, 45, 0.5)'
  },
};
