import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QrCodeCleaningComponent } from './qr-code-cleaning/qr-code-cleaning.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from '../app-routing.module';
import { NgbAlertModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxMaskModule } from 'ngx-mask';
import { CleaningService } from './cleaning.service';
import { ListCleaningTypeComponent } from './list-cleaning-type/list-cleaning-type.component';
import { DetailsCleaningTypeComponent } from './details-cleaning-type/details-cleaning-type.component';
import { CleaningTypeFormComponent } from './cleaning-type-form/cleaning-type-form.component';
import { CalendarPageComponent } from './calendar/calendar.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DayViewSchedulerComponent } from './calendar/vendor/day-view-scheduler.component';
import { ExtraServiceFormComponent } from './extra-service-form/extra-service-form.component';
import { ListExtraServiceComponent } from './list-extra-service/list-extra-service.component';
import { CleaningFormComponent } from './cleaning-form/cleaning-form.component';
import { CalendarFormComponent } from './calendar-form/calendar-form.component';
import { CleaningLimitsService } from './cleaning-limit.service';

@NgModule({
  declarations: [
    QrCodeCleaningComponent,
    ListCleaningTypeComponent,
    DetailsCleaningTypeComponent,
    CleaningTypeFormComponent,
    CalendarPageComponent,
    DayViewSchedulerComponent,
    ExtraServiceFormComponent,
    ListExtraServiceComponent,
    CleaningFormComponent,
    CalendarFormComponent,
  ],
  imports: [
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    CommonModule,
    QRCodeModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AppRoutingModule,
    NgbAlertModule,
    NgbModalModule,
    NgxMaskModule,
  ],
  providers: [CleaningService, CleaningLimitsService],
  exports: [ListCleaningTypeComponent, CleaningTypeFormComponent, DetailsCleaningTypeComponent, CalendarPageComponent],
})
export class CleaningModule {}
