import { Component, OnInit } from '@angular/core';
import { CleaningService } from '../cleaning.service';
import { CleaningType } from 'src/app/entities/cleaning/cleaning-type.entity';
import { Unit } from 'src/app/entities/units/unit.entity';
import { ActivatedRoute } from '@angular/router';
import { NavService } from 'src/app/shared/service/nav.service';

@Component({
  selector: 'app-list-cleaning-type',
  templateUrl: './list-cleaning-type.component.html',
  styleUrls: ['./list-cleaning-type.component.scss'],
})
export class ListCleaningTypeComponent implements OnInit {
  types: CleaningType[] = [];

  loading = true;
  hotelInfo: Unit;
  constructor(public service: CleaningService, public route: ActivatedRoute, public navService: NavService) {}

  ngOnInit() {
    this.navService.currentUnit$.subscribe((unit) => {
      this.hotelInfo = unit;

      this.getAll();
    });
  }

  async getAll() {
    this.loading = true;
    console.log(this.hotelInfo);
    this.types = await this.service.getAllCleaningTypes(this.hotelInfo.hotelCode).catch((e) => {
      alert('Erro ao buscar tipos de limpeza');
      this.loading = false;
      console.error(e);
      return [];
    });
    this.loading = false;
  }

  get instantiatedCleaningTypes() {
    return this.types.map((cleaningType) => new CleaningType(cleaningType));
  }
}
