import { Injectable } from '@angular/core';
import { ChatDB } from '../../shared/data/chat/chat';
import { Observable, Subscriber, BehaviorSubject } from 'rxjs';

//////////

import Client from 'twilio-chat';
import { User } from 'src/app/entities/residents/user.entity';
import { APIService } from 'src/app/api.service';
import { ChatRoom } from 'src/app/entities/units/chatRoom.entity';

//////////

const today = new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  public observer: Subscriber<{}>;
  public chat: any[] = [];
  public users: any[] = [];

  //////////

  client: Client;
  user: User;
  chatRooms: BehaviorSubject<ChatRoom[]> = new BehaviorSubject<ChatRoom[]>([]);

  //////////

  constructor(
    public API: APIService
  ) {
    this.chat = ChatDB.chat;
    this.users = ChatDB.chatUser;

    //////////

    this.API.getLoggedUser().subscribe((usr) => {
      this.user = usr;
      this.getClient();
      this.getChats();
    });
  }

  //////////

  async getTwilioAccessToken() {
    return (await this.API.get<any>('/chat/token', { id: this.user.id.toString() }, { tokenRequired: true, hideLoading: true })).token;
  }

  async getClient() {
    if (!this.client && this.user && this.user.id) {
      const token = await this.getTwilioAccessToken();
      this.client = await Client.create(token);
    }
    return this.client;
  }

  async getChats() {
    const res = await this.API.get<ChatRoom[]>('/chat/chatRoomsByUser', {}, { tokenRequired: true, hideLoading: true  });
    const targetedChat = this.setTargetUserChannel(res);
    this.chatRooms.next(targetedChat);
  }

  async getRooms() {
    return this.chatRooms.asObservable();
  }

  getRoom(id: number) {
    return this.chatRooms.getValue().find(room => room.id === id);
  }

  setTargetUserChannel(response): ChatRoom[] {
    if(!response) {
      return [];
    }
    return response.map(channel => {
      if (channel.users[0].id !== this.user.id) {
        channel.userTarget = new User(channel.users[0]);
        channel.currentUser = new User(channel.users[1]);
      } else {
        channel.userTarget = new User(channel.users[1]);
        channel.currentUser = new User(channel.users[0]);
      }
      return channel;
    });
  }

  async create(userTarget) {
    const newChat = await this.API.post(`/chat`, { users: [{id: this.user.id}, {id: userTarget}] }, { tokenRequired: true, hideLoading: true  }) as ChatRoom;
    const rooms = this.chatRooms.getValue();
    rooms.push(newChat);
    this.chatRooms.next(rooms);
    return newChat;
  }

  //////////

  // TODO Send Message to user
  // public sendMessage(chat) {
  //   this.chat.filter(chats => {
  //     if (chats.id == chat.receiver) {
  //       chats.message.push({ sender: chat.sender, time: today.toLowerCase(), text: chat.message })
  //       setTimeout(function () {
  //         document.querySelector(".chat-history").scrollBy({ top: 200, behavior: 'smooth' });
  //       }, 310)
  //       this.responseMessage(chat)
  //     }
  //   })
  // }

  // public responseMessage(chat) {
  //   this.chat.filter(chats => {
  //     if (chats.id == chat.receiver) {
  //       setTimeout(() => {
  //         chats.message.push({ sender: chat.receiver, time: today.toLowerCase(), text: 'Hey This is ' + chat.receiver_name + ', Sorry I busy right now, I will text you later' })
  //       }, 2000);
  //       setTimeout(function () {
  //         document.querySelector(".chat-history").scrollBy({ top: 200, behavior: 'smooth' });
  //       }, 2310)
  //     }
  //   })
  // }

}
