import { Injectable } from '@angular/core';
import { APIService } from '../api.service';
import { GuestBilling } from '../entities/residents/guestBilling.entity';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  constructor(public API: APIService) {}

  async getAllFromUnit(hotelCode: number): Promise<GuestBilling[]> {
    const billing = await this.API.get<GuestBilling[]>(
      '/billing',
      {
        filter: 'HotelCode||eq||' + hotelCode,
      },
      { tokenRequired: true },
    );
    if (billing && billing.length) {
      return billing.map(s => new GuestBilling(s));
    }
    return [];
  }

  create(billing: GuestBilling) {
    return this.API.post<GuestBilling | GuestBilling>('/billing', billing, { tokenRequired: true });
  }

  read(id: number) {
    return this.API.get<GuestBilling>(`/billing/${id}`, {}, { tokenRequired: true });
  }

  update(billing: GuestBilling, id: number) {
    return this.API.patch<GuestBilling | GuestBilling>(`/billing/${id}`, billing, { tokenRequired: true });
  }

  delete(id: number) {
    return this.API.delete<GuestBilling>(`/billing/${id}/reverse`, {}, { tokenRequired: true });
  }
}
