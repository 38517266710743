import { Component, OnInit } from '@angular/core';
import { Space } from 'src/app/entities/spaces/space.entity';
import { Unit } from '../../entities/units/unit.entity';
import { SpacesService } from '../spaces.service';
import { ActivatedRoute } from '@angular/router';
import { NavService } from '../../shared/service/nav.service';

@Component({
  selector: 'app-list-spaces',
  templateUrl: './list-spaces.component.html',
  styleUrls: ['./list-spaces.component.scss']
})
export class ListSpacesComponent implements OnInit {

  spaces: Space[] = [];
  searchTerm = '';
  loading = true;
  hotelInfo: Unit;
  constructor(
    public spacesService: SpacesService,
    public route: ActivatedRoute,
    public navService: NavService,
  ) {
  }

  ngOnInit() {
    this.navService.currentUnit$.subscribe((unit) => {
      this.hotelInfo = unit;

      this.getAll();
    });
  }

  async getAll() {
    this.loading = true;
    this.spaces = await this.spacesService.getAllFromUnit(this.hotelInfo.hotelCode)
    .catch((e) => {
      alert('Erro ao buscar todos locais');
      this.loading = false;
      console.error(e);
      return [];
    });
    this.loading = false;
  }

}
