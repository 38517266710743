import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbAlertModule, NgbTooltipModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from '../app-routing.module';
import { ListSuggestionsComponent } from './list-suggestions/list-suggestions.component';
import { DetailsSuggestionsComponent } from './details-suggestions/details-suggestions.component';
import { SuggestionsService } from './suggestions.service';

@NgModule({
  declarations: [ListSuggestionsComponent, DetailsSuggestionsComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbPaginationModule,
    ReactiveFormsModule,
    SharedModule,
    AppRoutingModule,
    NgbAlertModule,
    NgxMaskModule,
    NgbTooltipModule,
    ToastrModule
  ],
  providers: [SuggestionsService],
  exports: [ListSuggestionsComponent],
})
export class SuggestionsModule { }
