import { Injectable } from '@angular/core';
import { User } from './entities/residents/user.entity';
import { APIService } from './api.service';
import { Notification as NotificationEntity } from './entities/notification/notification.entity';

export enum NotificationKind {
  STR = 'STR',
  FUN = 'FUN',
  PAR = 'PAR',
  FRIENDSHIP = 'FRIENDSHIP',
  FRIENDSHIP_ACCEPT = 'FRIENDSHIP_ACCEPT',
  EVENT_INVITE = 'EVENT_INVITE',
  EVENT_ACCEPT = 'EVENT_ACCEPT',
  EVENT_JOIN = 'EVENT_JOIN',
  MAINTENANCE_ASSIGN = 'MAINTENANCE_ASSIGN',
  MAINTENANCE_COMMENT = 'MAINTENANCE_COMMENT',
  MAINTENANCE_FINISHED = 'MAINTENANCE_FINISHED',
  UNIT_ANNOUNCEMENT = 'UNIT_ANNOUNCEMENT',
}

export interface Notification {
  id: number;
  createdDate: string | Date;
  updatedDate: string | Date;
  kind: NotificationKind;
  status: string;
  title: string;
  message: string;
  toUser: User;
  fromUser: User;
  opened: boolean;
  linkedInfo: number;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private API: APIService) {}

  async sendNewNotification(toUserId: number, kind: NotificationKind, additionalData?: object) {
    let requestBody = { kind, toUserId };
    if (additionalData) {
      requestBody = { ...requestBody, ...additionalData };
    }

    return await this.API.post('/notifications/alertUser', requestBody, { tokenRequired: true, hideLoading: true });
  }

  async notifyAllFromUnit(notification: NotificationEntity, unitId: number, file?: any) {
    let notificationData: NotificationEntity | FormData;

    if (file) {
      const form = new FormData();
      form.append('file', file);
      form.append('title', notification.title);
      form.append('message', notification.message);

      notificationData = form;
    } else {
      notificationData = notification;
    }

    return await this.API.post<NotificationEntity | FormData, NotificationEntity>(`/notifications/unit/${unitId}/notifyAll`, notificationData, {
      tokenRequired: true,
      hideLoading: true,
    });
  }

  async notifyOneResident(notification: NotificationEntity, hotelCode, guestCode, file?: any) {
    let notificationData: NotificationEntity | FormData;

    if (file) {
      const form = new FormData();
      form.append('file', file);
      form.append('title', notification.title);
      form.append('message', notification.message);
      form.append('hotelCode', hotelCode);

      notificationData = form;
    } else {
      Object.assign(notification, { hotelCode: hotelCode });
      notificationData = notification;
    }

    return await this.API.post<NotificationEntity | FormData, NotificationEntity>(`/notifications/notifyUser/${guestCode}`, notificationData, {
      tokenRequired: true,
      hideLoading: true,
    });
  }
}
