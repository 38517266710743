export class BaseEntity {

  constructor(values?: object) {
    if (values) {
      Object.assign(this, values);
    }
  }
  public id: number;
  public createdDate: string;
  public updatedDate: string;
}
