import { NotificationKind } from 'src/app/notification.service';
import { User } from 'twilio-chat';
import { RestEntity } from '../rest.entity';

export class Notification extends RestEntity<Notification> {
  kind?: NotificationKind;
  status?: string;
  title: string;
  message: string;
  toUser: User;
  fromUser?: User;
  opened?: boolean;
  linkedInfo?: number;
  imageUrl?: string | ArrayBuffer;
}
