import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListResidentsComponent } from './list-residents/list-residents.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule, MASK_OPTIONS } from '../shared/shared.module';
import { AppRoutingModule } from '../app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { ResidentsService } from './residents.service';
import { NgxMaskModule } from 'ngx-mask';
import { ResidentsFormComponent } from 'src/app/residents/residents-form/residents-form.component';
import { DetailsResidentComponent } from './details-resident/details-resident.component';
import { NgbAlertModule, NgbPaginationModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SyncResidentsComponent } from './sync-residents/sync-residents.component';
import { NotifyResidentsFormComponent } from './notify-residents-form/notify-residents-form.component';

@NgModule({
  declarations: [ListResidentsComponent, ResidentsFormComponent, DetailsResidentComponent, SyncResidentsComponent, NotifyResidentsFormComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbAlertModule,
    NgbPaginationModule,
    NgbTooltipModule,
    ToastrModule.forRoot(), // ToastrModule added
    NgxMaskModule.forRoot(MASK_OPTIONS)
  ],
  providers: [ResidentsService],
  exports: [],
})
export class ResidentsModule { }
