import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { ListVisitsComponent } from './visit-list/list-visit.component';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import ptBr from '@angular/common/locales/pt';
import { ListVisitorsComponent } from './visitor-list/list-visitor.component';
import { DetailsVisitComponent } from './details-visit/details-visit.component';
import { DetailsVisitorComponent } from './details-visitor/details-visitor.component';
import { QRCodeModule } from 'angularx-qrcode';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VisitFormComponent } from './visit-form/visit-form.component';
registerLocaleData(ptBr);

@NgModule({
  declarations: [ListVisitsComponent, ListVisitorsComponent, DetailsVisitComponent, DetailsVisitorComponent, VisitFormComponent],
  imports: [CommonModule, NgbPaginationModule, RouterModule, FormsModule, ReactiveFormsModule, QRCodeModule, NgbModule],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-PT' }],
})
export class VisitorModule {}
