import { RestEntity } from '../rest.entity';
import { User, UserStatus, translateRole, DEFAULT_AVATAR } from '../residents/user.entity';
import { Unit } from '../units/unit.entity';
export class Employee extends RestEntity<Employee> {

  user?: User;

  unit?: Unit;

  firstName?: string;

  lastName?: string;

  cellphone?: string;

  phone?: string;

  workingHours?: string;

  isUleader?: boolean;

  roleDescription?: string;

  get roleName(): string {
    if (this.user) {
      return translateRole(this.user.role);
    } else {
      return 'User';
    }
  }
  get fullName(): string {
    return this.firstName + ' ' + this.lastName;
  }

  get statusText() {
    switch (this.user.status) {
      case UserStatus.APPROVED:
        return 'Ativo';
      case UserStatus.BLOCKED:
        return 'Bloqueado';
      case UserStatus.PRECREATED:
        return 'Pré-criado';
    }
  }

  get statusColor() {
    switch (this.user.status) {
      case UserStatus.APPROVED:
        return 'success';
      case UserStatus.BLOCKED:
        return 'danger';
      case UserStatus.PRECREATED:
        return 'info';
      default:
        return 'Não Confirmada';
    }
  }

  get avatarUrl() {
    if (this.user && this.user.avatarUrl) {
      return this.user.avatarUrl;
    } else {
      return DEFAULT_AVATAR;
    }
  }

  hasAvatar() {
    return (this.user && this.user.avatarUrl);
  }

}
