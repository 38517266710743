import { Component, OnInit } from '@angular/core';
import { Unit } from '../../entities/units/unit.entity';
import { BillingService } from '../billing.service';
import { ActivatedRoute } from '@angular/router';
import { NavService } from '../../shared/service/nav.service';
import { GuestBilling } from '../../entities/residents/guestBilling.entity';

@Component({
  selector: 'app-list-billing',
  templateUrl: './list-billing.component.html',
  styleUrls: ['./list-billing.component.scss']
})
export class ListBillingComponent implements OnInit {

  billings: GuestBilling[] = [];
  searchTerm = '';
  loading = true;
  hotelInfo: Unit;

  files: any = [];

  constructor(
    public service: BillingService,
    public route: ActivatedRoute,
    public navService: NavService,
  ) {
  }

  ngOnInit() {
    this.route.parent.params.subscribe((_) => {
      this.hotelInfo = this.navService.hotelInfo;

      this.getAll();
    });
    this.navService.currentUnit$.subscribe((unit) => {
      this.hotelInfo = unit;

      this.getAll();
    });
  }

  async getAll() {
    this.loading = true;
    this.billings = await this.service.getAllFromUnit(this.hotelInfo.hotelCode)
    .catch((e) => {
      alert('Erro ao buscar todos os pagamentos');
      this.loading = false;
      console.error(e);
      return [];
    });
    this.loading = false;
  }

}
