import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { showCustomErrorAlert, showDefaultErrorAlert } from 'src/app/api.service';
import { Notification } from 'src/app/entities/notification/notification.entity';
import { Guest } from 'src/app/entities/residents/guest.entity';
import { Unit } from 'src/app/entities/units/unit.entity';
import { NotificationService } from 'src/app/notification.service';
import Swal from 'sweetalert2';
import { NavService } from '../../shared/service/nav.service';
import { ResidentsService } from '../residents.service';

@Component({
  selector: 'app-notify-residents-form',
  templateUrl: './notify-residents-form.component.html',
  styleUrls: ['./notify-residents-form.component.scss'],
})
export class NotifyResidentsFormComponent implements OnInit {
  form: FormGroup;
  loading: boolean = false;
  errorMessage: string;
  errorCode: number;
  hotelInfo: Unit;
  imageUrl: string | ArrayBuffer;
  file: any;
  residents: Guest[];

  constructor(public navService: NavService, public formBuilder: FormBuilder, public notificationService: NotificationService, public router: Router, public residentsService: ResidentsService) {}

  ngOnInit() {
    this.navService.currentUnit$.subscribe((unit) => {
      this.hotelInfo = unit;
      this.loadResidents();
      this.initCreateForm();
    });
  }

  async submit() {
    this.loading = true;

    const notification = new Notification({
      title: this.form.value.title,
      message: this.form.value.message,
      imageUrl: this.imageUrl,
    });

    try {
      if (this.form.value.resident == "all") {
        await this.notificationService.notifyAllFromUnit(notification, this.hotelInfo.hotelCode, this.file);
      } else {
        await this.notificationService.notifyOneResident(notification, this.hotelInfo.hotelCode, this.form.value.resident, this.file);
      }

      this.successAlert();
      this.form.reset();
      this.imageUrl = null;
      this.loading = false;
      this.errorMessage = null;
    } catch (error) {
      this.errorAlert(error);
      console.log(error);
      this.loading = false;
    }
  }

  successAlert() {
    return Swal.fire({
      title: 'Notificação enviada!',
      type: 'success',
      showConfirmButton: true,
      confirmButtonText: 'Voltar',
      onClose: () => {},
    });
  }

  errorAlert(err) {
    if (err.error === 'INVALID_FILE_FORMAT') {
      showCustomErrorAlert({ text: 'Formato não permitido.' });
    } else {
      showDefaultErrorAlert(err);
    }
  }

  initCreateForm() {
    this.form = this.formBuilder.group({
      title: [null, [Validators.required, Validators.maxLength(100)]],
      message: [null, [Validators.required]],
      resident: [null, [Validators.required]],
    });
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.file = file;
      const mimeType = file.type;
      if (mimeType.match(/image\/*/) === null) {
        alert('Only images (.jpg, .jpeg, .png) are supported.');
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.imageUrl = reader.result;
      };
    }
  }

  async loadResidents() {
    this.residents = (await this.residentsService.getAllGuests(this.hotelInfo.hotelCode)).data;
  }

  showError(field?: string): boolean {
    if (!this.form.get(field)) {
      return false;
    }
    return (
      (this.form.get(field).invalid && this.form.get(field).touched) ||
      (!this.form.get(field).touched && this.errorMessage && this.errorMessage.length > 0 && this.errorCode === 400)
    );
  }
}
