import { Component, OnInit } from '@angular/core';
import { Guest, GuestQuantitativeReport } from 'src/app/entities/residents/guest.entity';
import { ResidentsService } from '../residents.service';
import { ActivatedRoute } from '@angular/router';
import { Unit } from '../../entities/units/unit.entity';
import { NavService } from '../../shared/service/nav.service';
import Swal from 'sweetalert2';
import { ReservationStatus } from '../../entities/residents/totvs.interfaces';
import { UserStatus } from '../../entities/residents/user.entity';

@Component({
  selector: 'app-list-residents',
  templateUrl: './list-residents.component.html',
  styleUrls: ['./list-residents.component.scss'],
})
export class ListResidentsComponent implements OnInit {
  residents: Guest[] = [];
  originalListOfResidents: Guest[];
  searchTerm = '';
  loading = true;
  hotelInfo: Unit;
  files: any = [];
  report: GuestQuantitativeReport;
  loadingReport: boolean;
  appStatusReport: { active: number; blocked: number; pendingAccess: number };

  userStatus = UserStatus;
  userStatusFilterValue: UserStatus | 'PENDING';

  reservationStatus = ReservationStatus;
  reservationStatusFilterValue: ReservationStatus | ReservationStatus[];

  pagination = {
    page: 1,
    limit: 25,
    pages: 5,
    collectionSize: 100,
  };

  constructor(public residentsSrvc: ResidentsService, public route: ActivatedRoute, public navService: NavService) {
    this.originalListOfResidents = [];
  }

  ngOnInit() {
    this.navService.currentUnit$.subscribe(unit => {
      this.hotelInfo = unit;

      if (this.hotelInfo) {
        this.getAll();
        this.getReports();
      }
    });
  }

  async getReports() {
    this.loadingReport = true;
    this.report = await this.residentsSrvc.getReports(this.hotelInfo.hotelCode);
    this.appStatusReport = this.report.app;
    console.log({ report: this.report });
    this.loadingReport = false;
  }

  async getAll() {
    this.searchTerm = '';
    this.loading = true;
    const resp = await this.residentsSrvc
      .getAllGuests(this.hotelInfo.hotelCode, this.pagination.page, this.pagination.limit, this.reservationStatusFilterValue, this.userStatusFilterValue)
      .catch(e => {
        alert('Erro ao buscar moradores');
        this.loading = false;
        console.error(e);
        return { data: [], totalPages: 0 };
      });
    console.log('DEBUG: ListResidentsComponent -> getAll -> resp', resp);
    this.residents = resp.data;
    if (!this.reservationStatusFilterValue && !this.userStatusFilterValue) {
      this.originalListOfResidents = [...this.residents];
    }
    console.log('checked in', this.residents.filter(resident => resident.ReservationStatus === ReservationStatus.CheckedIn));
    // if (this.originalListOfResidents && this.originalListOfResidents.length > 0) {
    //   this.countGuestsByAppStatus();
    // }
    this.pagination.pages = resp.totalPages;
    this.pagination.collectionSize = resp.totalPages * this.pagination.limit;
    this.loading = false;
  }

  // countGuestsByAppStatus() {
  //   this.appStatusReport = {
  //     active: this.originalListOfResidents.filter(resident => resident.user && resident.user.status === UserStatus.APPROVED).length,
  //     preCreated: this.originalListOfResidents.filter(resident => resident.user && resident.user.status === UserStatus.PRECREATED).length,
  //     blocked: this.originalListOfResidents.filter(resident => resident.user && resident.user.status === UserStatus.BLOCKED).length,
  //     pendingAccess: this.originalListOfResidents.filter(resident => !resident.user).length,
  //   };
  //
  //   console.log({ appStatusReport: this.appStatusReport });
  // }

  async search() {
    if (!this.searchTerm) {
      return this.getAll();
    }
    this.loading = true;
    this.residents = await this.residentsSrvc.searchGuests(this.hotelInfo.hotelCode, this.searchTerm).catch(e => {
      alert('Erro ao buscar moradores');
      this.loading = false;
      console.error(e);
      return [];
    });
    this.pagination.collectionSize = 0;
    this.loading = false;
  }

  // async syncGuests() {
  //   const sync = await this.residentsSrvc.syncGuests();
  //   if (sync) {
  //     Swal.fire({
  //       type: 'success',
  //       titleText: 'Pronto!',
  //       text: 'Moradores sincronizados com Totvs'
  //     });
  //     this.getAll();
  //   }
  // }

  uploadFile(event) {
    console.log('DEBUG: ListRoomsComponent -> uploadFile -> event', event);
    let name = '';
    for (let index = 0; index < event.length; index++) {
      const el = event[index];
      name = el.name;
      this.files.push(el.name);
    }

    Swal.fire({
      title: 'Deseja importar os quartos contidos na planilha ' + name + '?',
      text: 'Essa ação não poderá ser desfeita.',
      type: 'warning',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Importar',
    }).then(resp => {
      if (resp.value) {
        this.importSheet(event[0]);
      } else {
        this.files = [];
      }
    });
  }

  deleteAttachment(index) {
    this.files.splice(index, 1);
  }

  importSheet(file) {
    this.residentsSrvc.uploadSheet(file, this.hotelInfo.hotelCode).then(
      res => {
        console.log('Upload feito', res);
        Swal.fire({
          type: 'success',
          text: 'Moradores atualizados.',
          titleText: 'Feito!',
        }).then(() => {
          this.getAll();
        });
        this.files = [];
      },
      err => {
        console.error('Erro no upload', err);
        if (err.statusCode === 413) {
          Swal.fire({
            titleText: 'Arquivo muito grande!',
            text: 'Por favor, envie arquivos com menos de 50MB',
            type: 'error',
          });
        } else if (err.statusCode === 400) {
          Swal.fire({
            titleText: 'Parece haver algo errado com a planilha!',
            html: `
              <p class="text-center">Por favor, verifique se todas as colunas estão preenchidas corretamente.</p>
              <p class="text-left padding"> Alguns erros comuns:
                <ul>
                  <li>Datas não estão no formato correto. Garanta que a formatação original da planilha
                      se mantenha para TODOS os campos</li>
                  <li>Há letras onde deveriam haver números. Ex: letra O no lugar do número 0 (zero)</li>
                  <li>Há linhas com colunas faltantes. Não deixe linhas com colunas pré-preenchidas sem todas outras colunas</li>
                  <li>Colunas como "Gender" devem ter apenas um dos valores permitidos na formatação da planilha</li>
                  <li>Apenas Administradores e Community Managers podem fazer o upload de planilha</li>
                </ul>
              </p>
            `,
            text: 'Por favor, verifique se todas as colunas estão preenchidas corretamente.',
            type: 'error',
          });
        } else {
          Swal.fire({
            titleText: 'Ops!',
            text: 'Ocorreu um erro. Por favor, tente novamente ou entre em contato com a equipe de desenvolvimento',
            type: 'error',
          });
        }
      },
    );
  }

  removeStatusFilter() {
    this.reservationStatusFilterValue = undefined;
    this.userStatusFilterValue = undefined;
    this.getAll();
  }

  filterByReservationStatus(status: ReservationStatus | ReservationStatus[]) {
    this.reservationStatusFilterValue = status;
    this.userStatusFilterValue = undefined;
    this.getAll();
  }

  filterByAppStatus(appStatus: UserStatus | 'PENDING') {
    this.userStatusFilterValue = appStatus;
    this.reservationStatusFilterValue = undefined;
    this.getAll();
  }
}
