import { Component, OnInit } from '@angular/core';
import { Unit } from '../../entities/units/unit.entity';
import { RoomsService } from '../rooms.service';
import { ActivatedRoute } from '@angular/router';
import { NavService } from '../../shared/service/nav.service';
import { Room } from 'src/app/entities/rooms/room.entity';
import Swal from 'sweetalert2';
import { CleaningService, CleaningQR } from '../../cleaning/cleaning.service';

@Component({
  selector: 'app-list-rooms',
  templateUrl: './list-rooms.component.html',
  styleUrls: ['./list-rooms.component.scss']
})
export class ListRoomsComponent implements OnInit {

  rooms: Room[] = [];
  searchTerm = '';
  loading = true;
  hotelInfo: Unit;

  files: any = [];
  qrList = {};
  hasQrList = false;

  constructor(
    public service: RoomsService,
    public route: ActivatedRoute,
    public navService: NavService,
    public cleaningService: CleaningService,
  ) {
  }

  ngOnInit() {
    this.navService.currentUnit$.subscribe((unit) => {
      this.hotelInfo = unit;
      this.qrList = {};
      this.hasQrList = false;

      this.getAll();
    });
  }

  async getAll() {
    this.loading = true;
    this.rooms = await this.service.getAllFromUnit(this.hotelInfo.hotelCode)
    .catch((e) => {
      alert('Erro ao buscar todos os quartos');
      this.loading = false;
      console.error(e);
      return [];
    });
    this.loading = false;
  }

  uploadFile(event) {
    console.log('DEBUG: ListRoomsComponent -> uploadFile -> event', event);
    let name = '';
    for (let index = 0; index < event.length; index++) {
      const el = event[index];
      name = el.name;
      this.files.push(el.name);
    }

    Swal.fire({
      title: 'Deseja importar os quartos contidos na planilha ' + name + '?',
      text: 'Essa ação não poderá ser desfeita.',
      type: 'warning',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Importar',
    }).then(resp => {
      if (resp.value) {
        this.importSheet(event[0]);
      } else {
        this.files = [];
      }
    });
  }

  deleteAttachment(index) {
    this.files.splice(index, 1);
  }

  importSheet(file) {
    this.service.uploadSheet(file, this.hotelInfo.hotelCode).then(
      res => {
        console.log('Upload feito', res);
        Swal.fire({
          type: 'success',
          text: 'UHs atualizadas.',
          titleText: 'Feito!',
        }).then(() => {
          this.getAll();
        });
        this.files = [];
      },
      err => {
        console.error('Erro no upload', err);
        if (err.statusCode === 413) {
          Swal.fire({
            titleText: 'Arquivo muito grande!',
            text: 'Por favor, envie arquivos com menos de 50MB',
            type: 'error'
          });
        } else {
          Swal.fire({
            titleText: 'Ops!',
            text: 'Ocorreu um erro. Por favor, tente novamente ou entre em contato com a equipe de desenvolvimento',
            type: 'error'
          });
        }
      }
    );
  }

  async getQR() {
    this.loading = true;
    this.qrList = await this.service.getQR(this.hotelInfo.hotelCode);
    this.hasQrList = true;
  }

  async printQR() {
    await this.getQR();
    setTimeout(() => {
      let pagehtml = '';
      for (const key in this.qrList) {
        if (this.qrList.hasOwnProperty(key)) {
          console.log(key, this.qrList[key]);
          const obj = this.qrList[key];
          const img = document.querySelector(`qrcode[title="${obj.QR}"] img`);
          img.setAttribute('style', 'display: initial;');
          pagehtml += `<div style='text-align: center;
                        width: 45%;
                        padding: 8px;
                        float: right;
                        color: rgb(241, 128, 65);'> `;
          pagehtml += '<div style="border: 2px dashed black;padding:8px">';
          pagehtml += img.outerHTML + ' ';
          pagehtml += `<p><img src="https://i.imgur.com/F0zipA9.png" height="20" style="position: relative;top: 5px;"/> <b>Uliving</b> - `;
          pagehtml += `Quarto: ${obj.roomNumber}`;
          pagehtml += '</p></div></div>';
          img.setAttribute('style', 'display: initial;max-height: 20px;');
        }
      }
      try {
        const popup = window.open();
        popup.document.write(pagehtml);
        popup.focus();
        setTimeout(() => {
          popup.print();
        }, 500);
      } catch (e) {
        Swal.fire({
          titleText: 'Erro',
          html: '<p>Favor checar se é possivel abrir pop-up desta página</p>',
          backdrop: true,
          showCloseButton: false,
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: true,
          showCancelButton: false,
        });
      }
      this.loading = false;
    }, 100); // waiting for the angularx-qrcode to load the imgs
  }

}
