import { Component, OnInit } from '@angular/core';
import { Guest } from 'src/app/entities/residents/guest.entity';
import { Unit } from 'src/app/entities/units/unit.entity';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NavService } from 'src/app/shared/service/nav.service';
import Swal from 'sweetalert2';
import { DEFAULT_AVATAR, User, UserRole } from 'src/app/entities/residents/user.entity';
import { StatementService } from '../statement.service';
import { Statement, StatementStatus, translateStatementStatus } from 'src/app/entities/statement/statement.entity';
import { translateItemKind } from 'src/app/entities/statement/statement-item.entity';

@Component({
  selector: 'app-details-statement',
  templateUrl: './details-statement.component.html',
  styleUrls: ['./details-statement.component.scss'],
})
export class DetailsStatementComponent implements OnInit {
  constructor(public statementSrvc: StatementService, public route: ActivatedRoute, public navService: NavService, public router: Router) {}

  statement: Statement = new Statement();
  guestFullName: string = '';
  loading = false;
  translateStatementStatus = translateStatementStatus;
  translateItemKind = translateItemKind;
  hotelInfo: Unit;
  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.hotelInfo = this.navService.hotelInfo;
      if (params.statementId) {
        this.statement.id = params.statementId;
        this.getData(params.statementId);
      } else {
        this.router.navigate(['error', '404']);
      }
    });
  }

  getData(code: number) {
    this.loading = true;
    this.statementSrvc
      .read(code)
      .then(async (statement) => {
        this.statement = new Statement(statement);
        this.guestFullName = this.statement.guest ? `${this.statement.guest.GivenName}${this.statement.guest.SurName ? ' ' + this.statement.guest.SurName : ''}` : '';
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
        console.error('Erro ao ler resident', err);
        Swal.fire({
          title: 'Ops, ocorreu um erro',
          text: 'Por favor, tente novamente ou contate a equipe de desenvolvimento',
          showConfirmButton: true,
          confirmButtonText: 'Ok',
          showCloseButton: true,
          type: 'error',
        });
      });
  }

  getStatusColor(status: StatementStatus) {
    return status === StatementStatus.PAID ? 'success' : 'warning';
  }

  async deleteStatementClick() {
    Swal.fire({
      title: 'Tem certeza que quer deletar o extrato ' + this.statement.id + '?',
      text: 'Esta ação não poderá ser desfeita',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Excluir',
      confirmButtonClass: 'btn btn-danger',
      cancelButtonClass: 'btn btn-secondary',
    }).then((resp) => {
      if (resp.value) {
        this.delete(Number(this.statement.id));
      }
    });
  }

  delete(id: number) {
    this.statementSrvc
      .deleteStatement(id)
      .then((del) => {
        Swal.fire({
          type: 'success',
          text: 'Extrato ' + this.statement.id + ' deletado com sucesso.',
          titleText: 'Excluído.',
        }).then((resp) => {
          this.goToList();
        });
      })
      .catch((err) => {
        Swal.fire({
          type: 'error',
          titleText: 'Erro ao excluir.',
          text: 'Por favor, tente novamente.',
        });
      });
  }

  goToList() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'statements']);
  }
}
