import { Injectable } from '@angular/core';
import { APIService } from '../api.service';
import { IssueCategory } from './issue-category.entity';

@Injectable({
  providedIn: 'root',
})
export class IssueCategoriesService {
  constructor(public API: APIService) {}

  async getAll(sort?: string): Promise<IssueCategory[]> {
    const params = {};

    if (sort) {
      Object.assign(params, { sort });
    }

    const issueCategories = await this.API.get<IssueCategory[]>('/issue-categories', params, { tokenRequired: true }).catch((e) => {
      throw e;
    });
    if (issueCategories && issueCategories.length) {
      return issueCategories.map((s) => new IssueCategory(s));
    }
    return [];
  }

  create(issueCategory: IssueCategory) {
    issueCategory = this.clearNulls(issueCategory);
    return this.API.post<IssueCategory, IssueCategory>('/issue-categories', issueCategory, { tokenRequired: true });
  }

  read(id: number) {
    return this.API.get<IssueCategory>(`/issue-categories/${id}`, {}, { tokenRequired: true });
  }

  update(issueCategory: IssueCategory, id: number) {
    return this.API.patch<IssueCategory, IssueCategory>(`/issue-categories/${id}`, issueCategory, { tokenRequired: true });
  }

  delete(id: number) {
    return this.API.delete<IssueCategory>(`/issue-categories/${id}`, {}, { tokenRequired: true });
  }

  clearNulls(issueCategory: IssueCategory) {
    for (const key in issueCategory) {
      if (issueCategory[key] === null || issueCategory[key] === undefined) {
        delete issueCategory[key];
      }
    }
    return issueCategory;
  }
}
