import { Component, OnInit } from '@angular/core';
import { Guest } from 'src/app/entities/residents/guest.entity';
import { ResidentsService } from '../residents.service';
import { ActivatedRoute } from '@angular/router';
import { Unit } from '../../entities/units/unit.entity';
import { NavService } from '../../shared/service/nav.service';
import Swal from 'sweetalert2';
import { TotvsService, GuestRelationInterface, GetRequestAttributes } from '../totvs.service';
import { showDefaultErrorAlert } from 'src/app/api.service';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-sync-residents',
  templateUrl: './sync-residents.component.html',
  styleUrls: ['./sync-residents.component.scss']
})
export class SyncResidentsComponent implements OnInit {

  relations: GuestRelationInterface[] = [];
  searchTerm = '';
  hasFilter = false;
  loading = false;
  hotelInfo: Unit;
  files: any = [];

  Guest = Guest;

  pagination = {
    page: 1,
    limit: 25,
    pages: 5,
    collectionSize: 100,
  };

  validateEmail = validateEmail;

  constructor(
    public residentsSrvc: ResidentsService,
    public totvsService: TotvsService,
    public route: ActivatedRoute,
    public navService: NavService,
  ) {
  }

  ngOnInit() {
    this.navService.currentUnit$.subscribe((unit) => {
      this.hotelInfo = unit;
      if (this.hotelInfo && !this.loading) {
        this.getAll();
      }
    });
  }

  async getAll(conditions?: GetRequestAttributes) {
    this.loading = true;
    const resp = await this.totvsService.getAllGuests(this.hotelInfo.hotelCode, conditions, undefined, { hideLoading: true })
    .catch((e) => {
      alert('Erro ao buscar moradores');
      this.loading = false;
      console.error(e);
      return [] as GuestRelationInterface[];
    });

    console.log('DEBUG: ListResidentsComponent -> getAll -> resp', resp);
    this.relations = resp;
    this.loading = false;
    this.pagination.pages = 3;
    this.pagination.collectionSize = 90;
    console.log('DEBUG: ListResidentsComponent -> getAll -> this.pagination', this.pagination);
    this.loading = false;
    this.hasFilter = false;
    this.searchTerm = '';
  }

  async search() {
    this.loading = true;
    this.totvsService.getGuestRelations(this.hotelInfo.hotelCode, {GuestCode: Number(this.searchTerm)}).then(resp => {
      this.loading = false;
      this.hasFilter = true;
      this.pagination.collectionSize = 0;
      this.relations = resp.ResponseList;
    }, err => {
      console.error('DEBUG: SyncResidentsComponent -> search -> err', err);
      alert('Erro ao buscar moradores');
      this.loading = false;
      return [];
    });

  }

  async syncGuests(allRelations: GuestRelationInterface[]) {
    allRelations.forEach(r => r.isLoading = true);
    
    const sync = await this.residentsSrvc.syncGuests(allRelations, { hideLoading: true })
      .catch(e => {
        console.error(e);
        showDefaultErrorAlert(e);
        allRelations.forEach(r => r.isLoading = false);
      });

    if (sync) {
      await Swal.fire({
        toast: true,
        position: 'bottom-right',
        type: 'success',
        titleText: 'Pronto!',
        // tslint:disable-next-line: max-line-length
        text: `Morador${allRelations.length === 1 ? ' ' + allRelations[0].Guest.Name : 'es'} sincronizado${allRelations.length > 1 ? 's' : ''} com Totvs`,
      });

      window.location.reload();

    }
  }

  uploadFile(event) {
    console.log('DEBUG: ListRoomsComponent -> uploadFile -> event', event);
    let name = '';
    for (let index = 0; index < event.length; index++) {
      const el = event[index];
      name = el.name;
      this.files.push(el.name);
    }

    Swal.fire({
      title: 'Deseja importar os quartos contidos na planilha ' + name + '?',
      text: 'Essa ação não poderá ser desfeita.',
      type: 'warning',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Importar',
    }).then(resp => {
      if (resp.value) {
        this.importSheet(event[0]);
      } else {
        this.files = [];
      }
    });
  }


  deleteAttachment(index) {
    this.files.splice(index, 1);
  }

  importSheet(file) {
    this.residentsSrvc.uploadSheet(file, this.hotelInfo.hotelCode).then(
      res => {
        console.log('Upload feito', res);
        Swal.fire({
          type: 'success',
          text: 'Moradores atualizados.',
          titleText: 'Feito!',
        }).then(() => {
          this.getAll();
        });
        this.files = [];
      },
      err => {
        console.error('Erro no upload', err);
        if (err.statusCode === 413) {
          Swal.fire({
            titleText: 'Arquivo muito grande!',
            text: 'Por favor, envie arquivos com menos de 50MB',
            type: 'error'
          });
        } else {
          Swal.fire({
            titleText: 'Ops!',
            text: 'Ocorreu um erro. Por favor, tente novamente ou entre em contato com a equipe de desenvolvimento',
            type: 'error'
          });
        }
      }
    );
  }

}

export function validateEmail(email: string) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email.trim()).toLowerCase());
}
