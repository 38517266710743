import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { translatedVisitType, Visitor, Visits, VisitsService, VisitStatus, visitStatusOptions } from '../visitor.service';
import { NavService } from '../../shared/service/nav.service';
import { Unit } from '../../entities/units/unit.entity';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Guest } from '../../entities/residents/guest.entity';
import { ResidentsService } from '../../residents/residents.service';
import { UserEvent } from '../../events/event.entity';
import { EventsService } from '../../events/events.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { create } from 'domain';
import { Location } from '@angular/common';
import { Employee } from 'src/app/entities/employees/employee.entity';
import { EmployeesService } from 'src/app/employees/employees.service';

@Component({
  selector: 'app-visit-form',
  templateUrl: './visit-form.component.html',
  styleUrls: ['./visit-form.component.scss'],
})
export class VisitFormComponent implements OnInit {
  hotelInfo: Unit;

  visitorType: 'PRE-REGISTERED' | 'NEW';
  visitors: Visitor[];
  previousSelectedVisitor: Visitor;
  visitStatus: { label: string; value: VisitStatus }[];

  isEditing: boolean;
  visitId: number;
  visit: Visits;

  form: FormGroup;
  loading = false;
  errorMessage: string;

  residents: Guest[];
  events: UserEvent[];

  employees: Employee[];
  types;

  photoUrl: string | ArrayBuffer;
  file: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private visitSrvc: VisitsService,
    public navService: NavService,
    private formBuilder: FormBuilder,
    private employeesService: EmployeesService,
    private residentsSrvc: ResidentsService,
    private eventsSrvc: EventsService,
    public toastrSrvc: ToastrService,
    private location: Location,
  ) {
    // default values
    this.residents = [];
    this.events = [];
    this.visitors = [];
    this.visitorType = 'PRE-REGISTERED';
    this.visitStatus = visitStatusOptions;
    this.types = translatedVisitType;
  }

  ngOnInit() {
    this.navService.currentUnit$.subscribe(unit => {
      this.hotelInfo = unit;

      this.loadVisitors();
      this.loadEvents();
      this.loadResidents();
      this.loadEmployees();
      this.createForm();
      this.readParams();
    });
    // this.hotelInfo = this.navService.hotelInfo;
  }

  async loadVisitors() {
    if(!this.hotelInfo) return;
    this.visitors = (await this.visitSrvc.getAllVisitors(this.hotelInfo.hotelCode, 1, 5000)).data;
    console.log('this.visitor', this.visitors);
  }

  async loadEvents() {
    if(!this.hotelInfo) return;
    this.events = (await this.eventsSrvc.getAll(this.hotelInfo.hotelCode, { startDate: new Date(), page: 1, limit: 10000 })).events;
    console.log('this.events -> ', this.events);
  }

  async loadResidents() {
    if(!this.hotelInfo) return;
    this.residents = (await this.residentsSrvc.getAllGuests(this.hotelInfo.hotelCode)).data;
    console.log('this.residents -> ', this.residents);
  }

  async loadEmployees() {
    if(!this.hotelInfo) return;
    this.employees = await this.employeesService.getAllFromUnit(this.hotelInfo.hotelCode);
    console.log('this.employees -> ', this.employees);
  }

  async readParams() {
    this.activatedRoute.params.subscribe(params => {
      const { id } = params;
      this.visitId = id;

      if (id) {
        this.isEditing = true;
        this.loadVisit();
      } else {
        this.visit = {};
      }
    });
  }

  async loadVisit() {
    this.visit = await this.visitSrvc.readVisit(this.visitId);

    if (this.visit) {
      this.updateForm();
    }
  }

  createForm() {
    this.form = this.formBuilder.group(
      {
        visitor: this.formBuilder.group(
          {
            id: new FormControl({ value: null }),
            name: new FormControl({ value: null, disabled: true }, [Validators.required]),
            address: new FormControl({ value: null, disabled: true }, []),
            cpf: new FormControl({ value: null, disabled: true }, [Validators.minLength(1)]),
            rg: new FormControl({ value: null, disabled: true }, []),
            email: new FormControl({ value: null, disabled: true }, []),
          },
          { updateOn: 'blur' },
        ),
        guest: this.formBuilder.group(
          {
            GuestCode: [null],
            GivenName: [null],
          },
          { updateOn: 'blur' },
        ),
        event: this.formBuilder.group(
          {
            id: [null],
            title: [null],
          },
          { updateOn: 'blur' },
        ),
        visitType: [this.types[0], [Validators.required]],
        employee: [null],
        startDate: [null, [Validators.required]],
        endDate: [null, [Validators.required]],
        entryDate: [null],
        predictedLeaveDate: [null],
        realLeaveDate: [null],
        uuid: [null],
        status: [null],
      },
      { updateOn: 'blur' },
    );
  }

  updateForm() {
    console.log('this.visit -> ', this.visit);
    const startDate = new Date(this.visit.startDate);
    const endDate = new Date(this.visit.endDate);

    if (this.visit.visitor.photoUrl) {
      this.photoUrl = this.visit.visitor.photoUrl;
    }

    this.form.patchValue({
      id: this.visit.visitor.id,
      visitor: this.visit.visitor || {},
      // user: this.visit.user,
      guest: this.visit.guest || {},
      event: this.visit.event || {},
      uuid: this.visit.uuid,
      visitType: this.visit.visitType,
      employee: this.visit.employee,
      photo: this.visit.visitor.photoUrl,
      startDate: String(startDate.toISOString()).slice(0, 16),
      endDate: String(endDate.toISOString()).slice(0, 16),
      status: this.visit.status,
    });

    console.log('this form -> ', this.form);

    if (this.visit.predictedLeaveDate) {
      const predictedLeaveDate = new Date(this.visit.predictedLeaveDate);
      this.form.patchValue({
        predictedLeaveDate: String(predictedLeaveDate.toISOString()).slice(0, 16),
      });
    }

    if (this.visit.entryDate) {
      const entryDate = new Date(this.visit.entryDate);
      this.form.patchValue({
        entryDate: String(entryDate.toISOString()).slice(0, 16),
      });
    }

    if (this.visit.realLeaveDate) {
      const realLeaveDate = new Date(this.visit.realLeaveDate);
      this.form.patchValue({
        realLeaveDate: String(realLeaveDate.toISOString()).slice(0, 16),
      });
    }
  }

  showError(field?: string) {
    // console.log('DEBUG: MaintenanceFormComponent -> showError -> field', field);
    const selectedField = this.form.get(field);
    if (this.form.get(field)) {
      return (
        (this.form.get(field).invalid && this.form.get(field).touched) ||
        (!this.form.get(field).touched && this.errorMessage && this.errorMessage.length > 0 && this.form.get(field).invalid)
      );
    }
  }

  get cpf() {
    return this.form.get('visitor.cpf');
  }

  async submit() {
    console.log('form value to be submited -> ', this.form.value);

    if (!this.form.value.startDate) {
      Swal.fire({
        type: 'error',
        titleText: 'Data de entrada não informada',
        text: 'É necessário informar a data de entrada para esta visita',
      });
      return;
    }

    if (!this.form.value.endDate) {
      Swal.fire({
        type: 'error',
        titleText: 'Data prevista de saída não informada',
        text: 'É necessário informar a data prevista de saída para esta visita',
      });
      return;
    }

    if (!this.form.value.guest.GuestCode && !this.form.value.employee) {
      Swal.fire({
        type: 'error',
        titleText: 'Responsável não informado',
        text: 'É necessário informar o morador ou funcionário reponsável pela visita',
      });
      return;
    }

    if (this.visitorType === 'NEW' && !this.isVisitorFormValid()) {
      Swal.fire({
        type: 'error',
        titleText: 'Sem dados do visitante',
        text: 'Preencha todos os campos referente ao novo visitante',
      });
      return;
    } else if (this.visitorType === 'NEW' && this.isVisitorFormValid()) {
      this.loading = true;
      const newVisitor = this.form.get('visitor').value;
      Object.assign(newVisitor, { unit: { hotelCode: this.hotelInfo.hotelCode } });
      this.visitSrvc
        .createVisitor(this.form.get('visitor').value, this.file)
        .then(async res => {
          const createdVisitor = res as Visitor;
          if (createdVisitor) {
            this.visit.visitor = createdVisitor;
            this.visitors.unshift(createdVisitor);
            this.form.patchValue({
              visitor: this.visit.visitor,
            });
            if (this.isEditing) {
              await this.update();
            } else {
              await this.create();
            }
            this.handleVisitorTypeChange({ target: { value: 'PRE-REGISTERED' } });
            this.handleVisitorChange({ target: { value: String(createdVisitor.id) } });
          }
        })
        .catch(err => {
          this.validateAllFormFields(this.form);
          console.error('Error when creating visitor -> ', err);
          this.loading = false;
          this.toastrSrvc.error('Erro ao criar o novo visitante');
        });
    } else {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  async create() {
    const visitPayload: Visits = this.form.value;
    if (visitPayload.startDate) {
      visitPayload.startDate = new Date(visitPayload.startDate).toISOString();
    }
    if (visitPayload.predictedLeaveDate) {
      visitPayload.predictedLeaveDate = new Date(visitPayload.predictedLeaveDate).toISOString();
    }

    if (visitPayload.endDate) {
      visitPayload.endDate = new Date(visitPayload.endDate).toISOString();
    }

    Object.assign(this.visit, { unit: this.hotelInfo });
    try {
      this.loading = true;
      await this.visitSrvc.createVisit({ ...visitPayload, ...this.visit });
      this.toastrSrvc.success('Visita criada com sucesso');
      this.location.back();
    } catch (err) {
      console.error('Error when creating new visit', err);
      const errorMessage = this.handleErrors(err.error);
      Swal.fire({
        type: 'error',
        titleText: 'Erro ao criar visita',
        text: errorMessage,
      });

    } finally {
      this.loading = false;
    }
  }

  async update() {
    const visitPayload: Visits = this.form.value;
    if (visitPayload.startDate) {
      visitPayload.startDate = new Date(visitPayload.startDate).toISOString();
    }

    if (visitPayload.predictedLeaveDate) {
      visitPayload.predictedLeaveDate = new Date(visitPayload.predictedLeaveDate).toISOString();
    }

    if (visitPayload.endDate) {
      visitPayload.endDate = new Date(visitPayload.endDate).toISOString();
    }


    try {
      this.loading = true;

      if (this.file) {
        await this.visitSrvc.updateVisitor(this.visit.visitor.id, visitPayload, this.file);
      }

      await this.visitSrvc.updateVisit(this.visitId, visitPayload);
      this.toastrSrvc.success('Visita atualizada');
    } catch (err) {
      console.error('Error when updating visit -> ', err);
      this.toastrSrvc.error('Não foi possível atualizar a visita', 'Erro ao atualizar');
    } finally {
      this.loading = false;
    }
  }

  isVisitorFormValid(): boolean {
    const { name, cpf } = this.form.get('visitor').value;

    return !!name || !!cpf;
  }

  handleVisitorChange(event) {
    const {
      target: { value },
    } = event;
    const visitorId = Number(value);
    const chosenVisitor: Visitor = this.visitors.find(visitor => visitor.id === visitorId);
    console.log('chosenVisitor -> ', chosenVisitor);
    Object.assign(this.visit, { visitor: chosenVisitor });

    this.form.patchValue({
      visitor: this.visit.visitor,
    });
  }

  handleVisitorTypeChange(event) {
    this.visitorType = event.target.value;
    console.log(this.visitorType);

    if (this.visitorType === 'NEW') {
      this.previousSelectedVisitor = { ...this.visit.visitor };
      this.visit.visitor = {};
      this.form.patchValue({
        visitor: {
          id: null,
          name: null,
          address: null,
          cpf: null,
          rg: null,
          email: null,
        },
      });
      this.form
        .get('visitor')
        .get('name')
        .enable();
      this.form
        .get('visitor')
        .get('address')
        .enable();
      this.form
        .get('visitor')
        .get('cpf')
        .enable();
      this.form
        .get('visitor')
        .get('rg')
        .enable();
      this.form
        .get('visitor')
        .get('email')
        .enable();
    } else {
      this.visit.visitor = { ...this.previousSelectedVisitor };
      this.form.patchValue({
        visitor: this.visit.visitor,
      });
      this.form
        .get('visitor')
        .get('name')
        .disable();
      this.form
        .get('visitor')
        .get('address')
        .disable();
      this.form
        .get('visitor')
        .get('cpf')
        .disable();
      this.form
        .get('visitor')
        .get('rg')
        .disable();
      this.form
        .get('visitor')
        .get('email')
        .disable();
    }
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.file = file;
      const mimeType = file.type;
      if (mimeType.match(/image\/*/) === null) {
        alert('Only images (.jpg, .jpeg, .png) are supported.');
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = _event => {
        this.photoUrl = reader.result;
      };
    }
  }

  cleanType() {
    this.form.get('employee').setValue(null);
    this.form.get('guest').get('GuestCode').setValue(null);
    this.form.get('guest').get('GivenName').setValue(null);
  }

  handleErrors(err) {
    if (err == 'VISITOR_NOT_FOUND') {
      return 'Não foi possível encontrar o visitante, tente novamente!'
    } else if (err == 'VISITOR_NO_DOCUMENT') {
      return 'É necessário informar o documento do visitante.'
    }  else if (err == 'DATE_NOT_INFORMED') {
      return 'É necessário que as datas sejam informadas para o cadastro da visita!'
    }  else if (err == 'END_DATE_BEFORE_START_DATE') {
      return 'A data final informada é antes da data de início da visita!'
    }  else if (err == 'GUEST_NOT_INFORMED') {
      return 'O morador que irá recer a visita não foi informado!';
    }  else {
      return 'Ocorreu um erro ao tentar criar sua visita. Verifique todos os campos informados';
    }
  }
}
