import { BaseEntity } from '../common/base.entity';
import { Professional } from '../professionals/professional.entity';
// tslint:disable-next-line: max-line-length
import { AnswerAOS, AnswerDiagnosisVolume, AnswerPrescriptionEquipments, AnswerPrescriptionMasks, AnswerPrescriptionVolume, AnswerAirViewDiagnosis, AnswerAirViewTherapy, AnswerMyAirPrescription, AnswerReference, AnswerEventsWorkshop, AnswerEventsLecture, AnswerEventsReach, AnswerLecturesRMD, AnswerLaunchesRMD, AnswerWebinarsRMD, AnswerEventsScience, AnswerPublications, AnswerPublicationsRMD, AnswerRelevance, AnswerPAP } from './answers';
export class Evaluation extends BaseEntity {

  constructor(values?: Evaluation) {
    super(values);
    if (values && 'reporter' in values) {
      this.reporter = Object.assign(this.reporter, (this.reporter as Professional).user);
    }
  }

  professional: Professional | number;
  reporter: Professional | number;

  AOS?: AnswerAOS;
  diagnosisVolume?: AnswerDiagnosisVolume;
  titulationPAP?: AnswerPAP;
  prescriptionEquipments?: AnswerPrescriptionEquipments;
  prescriptionMasks?: AnswerPrescriptionMasks;
  prescriptionVolume?: AnswerPrescriptionVolume;
  airViewDiagnosis?: AnswerAirViewDiagnosis;
  airViewTherapy?: AnswerAirViewTherapy;
  myAirPrescription?: AnswerMyAirPrescription;
  reference?: AnswerReference;
  eventsWorkshop?: AnswerEventsWorkshop;
  eventsLecture?: AnswerEventsLecture;
  eventsReach?: AnswerEventsReach;
  lecturesRMD?: AnswerLecturesRMD;
  launchesRMD?: AnswerLaunchesRMD;
  webinarsRMD?: AnswerWebinarsRMD;
  eventsScience?: AnswerEventsScience;
  publications?: AnswerPublications;
  publicationsRMD?: AnswerPublicationsRMD;
  relevance?: AnswerRelevance;

  observation?: string;

  get note() {
    const fields = Object.keys(this).filter(f => {
      return !['id', 'createdDate', 'updatedDate', 'reporter', 'observation'].includes(f);
    });

    const values = fields.map(f => this[f]);
    if (values.length === 0) {
      return 0;
    }

    const sum = values.reduce((x, y) => x + y);

    return sum;
  }

  getNoteColor() {
    if (this.note === '?') {
      return ['badge-light'];
    } else if (this.note < 15) {
      return ['bg-scale-0'];
    } else if (this.note >= 15 && this.note < 30) {
      return ['bg-scale-1'];
    } else if (this.note >= 30 && this.note < 50) {
      return ['bg-scale-2'];
    } else if (this.note >= 50 && this.note < 70) {
      return ['bg-scale-3'];
    } else if (this.note >= 70 && this.note < 90) {
      return ['bg-scale-4'];
    } else if (this.note >= 90 && this.note <= 100) {
      return ['bg-scale-5'];
    }
  }
}
