import { Component, OnInit } from '@angular/core';
import { Employee } from '../../entities/employees/employee.entity';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EmployeesService } from '../employees.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NavService } from 'src/app/shared/service/nav.service';
import { Unit } from 'src/app/entities/units/unit.entity';
import Swal from 'sweetalert2';
import { User, DEFAULT_AVATAR } from '../../entities/residents/user.entity';
import { ChatRoom } from 'src/app/entities/units/chatRoom.entity';
import { ChatService } from 'src/app/shared/service/chat.service';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-details-employee',
  templateUrl: './details-employee.component.html',
  styleUrls: ['./details-employee.component.scss']
})
export class DetailsEmployeeComponent implements OnInit {

  employee: Employee = new Employee();
  hotelInfo: Unit;
  loading = false;
  uploadForm: FormGroup;
  avatar: string | ArrayBuffer = this.employee.avatarUrl;
  bgAvatar = false;
  chat: ChatRoom;
  constructor(
    public service: EmployeesService,
    public router: Router, public authService: AuthService,
    public route: ActivatedRoute,
    public navService: NavService,
    public formBuilder: FormBuilder,
    public chatService: ChatService) { }

  ngOnInit() {
    this.hotelInfo = this.navService.hotelInfo;
    this.route.params.subscribe((params) => {
      if (params.employeeId) {
        this.employee.id = params.employeeId;
        this.getData(params.employeeId);
      } else {
        this.router.navigate(['error', '404']);
      }
    });
    this.uploadForm = this.formBuilder.group({
      avatar: ['']
    });
  }

  async openChat() {
    if (!this.chat) {
      this.chat = await this.chatService.create(this.employee.user.id) as any;
    }
    return this.router.navigate(['units', 'chat', this.chat.id]);
  }

  async getData(id: number) {
    this.loading = true;

    await this.service.read(id).then(async (employee) => {
      this.employee = new Employee(employee);
      if (this.employee.hasAvatar()) {
        this.avatar = this.employee.avatarUrl;
        this.bgAvatar = true;
      }
      const chatObs = await this.chatService.getRooms();
      chatObs.subscribe(rooms => {
        this.chat = rooms.find(value => value.userTarget.id === this.employee.user.id);
      });
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      console.error('Erro ao ler employee', err);
      Swal.fire({
        title: 'Ops, ocorreu um erro',
        text: 'Por favor, tente novamente ou contate a equipe de desenvolvimento',
        showConfirmButton: true,
        confirmButtonText: 'Ok',
        showCloseButton: true,
        type: 'error'
      });
    });
  }

  async deleteEmployeeClick() {
    Swal.fire({
      title: 'Tem certeza que quer deletar o funcionário ' + this.employee.fullName + '?',
      text: 'Esta ação não poderá ser desfeita',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Excluir',
      confirmButtonClass: 'btn btn-danger',
      cancelButtonClass: 'btn btn-secondary',
    }).then(resp => {
      if (resp.value) {
         this.delete(this.employee.id);
      }
     });
  }

  delete(id: number) {
    this.service.delete(id).then(del => {
      Swal.fire({
        type: 'success',
        text: 'Funcionário ' + this.employee.fullName + ' deletado com sucesso.',
        titleText: 'Excluído.'
      }).then(resp => {
        this.goToList();
      });
    }).catch(err => {
      Swal.fire({
        type: 'error',
        titleText: 'Erro ao excluir.',
        text: 'Por favor, tente novamente.',
      });
    });
  }

  updateAvatar() {
    this.service.setAvatar(this.employee.user.id, this.uploadForm.get('avatar').value).then(
      res => {
        console.log('Upload feito', res);
        Swal.fire({
          type: 'success',
          text: 'Foto de perfil atualizada.',
          titleText: 'Feito!',
        });
        this.uploadForm.reset();
        this.employee.user.avatarUrl = res.avatarUrl;
        this.avatar = this.employee.avatarUrl;
        this.bgAvatar = true;
      },
      err => {
        console.error('Erro no upload', err);
        if (err.statusCode === 413) {
          Swal.fire({
            titleText: 'Imagem muito grande!',
            text: 'Por favor, envie imagens com menos de 100MB',
            type: 'error'
          });
          this.uploadForm.reset();
          this.avatar = DEFAULT_AVATAR;
        } else {
          Swal.fire({
            titleText: 'Ops!',
            text: 'Ocorreu um erro. Por favor, tente novamente ou entre em contato com a equipe de desenvolvimento',
            type: 'error'
          });
        }
      }
    );
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log('DEBUG: DetailsEmployeeComponent -> onFileSelect -> file', file);
      this.uploadForm.get('avatar').setValue(file);
      const mimeType = file.type;
      if (mimeType.match(/image\/*/) === null) {
        alert('Only images (.jpg, .jpeg, .png) are supported.');
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.avatar = reader.result;
        this.bgAvatar = true;
      };
    }
  }

  resetForm() {
    this.uploadForm.reset();
    this.avatar = DEFAULT_AVATAR;
    this.bgAvatar = false;
  }

  goToList() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'employees', 'All']);
  }

}
