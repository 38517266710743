import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from '../app-routing.module';
import { NgbAlertModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { QRCodeModule } from 'angularx-qrcode';
import { OccurrenceCategoryService } from './occurrence-categories.service';
import { ListOccurrenceCategoriesComponent } from './list-occurrence-category/list-occurrence-category.component';
import { OccurrenceCategoryFormComponent } from './occurrence-category-form/occurrence-category-form.component';

@NgModule({
  declarations: [ListOccurrenceCategoriesComponent, OccurrenceCategoryFormComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AppRoutingModule,
    NgbAlertModule,
    NgbModalModule,
    QRCodeModule,
  ],
  providers: [ OccurrenceCategoryService ]
})
export class OccurrenceCategoryModule { }
