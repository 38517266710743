import { RestEntity } from '../rest.entity';
import { Schedule } from './schedule.entity';
import { Unit } from '../units/unit.entity';

export enum SpaceCategory {
  BARBECUE = 'BARBECUE',
  EVENT = 'EVENT',
  STUDY = 'STUDY',
}

export class Space extends RestEntity<Space> {
  unit?: Unit;

  name?: string;

  minGuests?: number;

  maxGuests?: number;


  adminOnly?: boolean;

  
  eventRequired?: boolean;

  fullDay?: boolean;

  step?: number;

  minInterval?: number;

  maxInterval?: number;

  startHour?: string;

  endHour?: string;

  minAntecedence?: number;

  maxAntecedence?: number;

  category?: SpaceCategory;

  schedules?: Schedule[];

  get intervalText() {
    if (this.fullDay) {
      return 'Dia todo';
    }

    if (this.minInterval === this.maxInterval) {
      return this.minInterval + 'min';
    }

    return this.minInterval + 'min a ' + this.maxInterval + ' min';
  }
}
