import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NavService } from '../../shared/service/nav.service';
import { Visitor, VisitsService } from '../visitor.service';

@Component({
  selector: 'app-visitor-form',
  templateUrl: './visitor-form.component.html',
  styleUrls: ['./visitor-form.component.scss'],
})
export class VisitorFormComponent implements OnInit {
  form: FormGroup;
  loading = false;
  errorMessage: string;
  errorCode: number;
  visitor: Visitor;
  formSubmitedSuccessfully = false;

  visitorId: number; // email from the URL

  constructor(private formBuilder: FormBuilder, public router: Router, public route: ActivatedRoute, public navService: NavService, private visitsSrvc: VisitsService) {
    this.initCreateForm();
    this.getUrlQueryParams();
  }

  ngOnInit() {}

  getUrlQueryParams() {
    this.route.queryParams.subscribe(async params => {
      this.visitorId = Number(params['invite']);

      if (this.visitorId) {
        await this.getVisitor();
        this.updateForm();
      }
    });
  }

  initCreateForm() {
    this.form = this.formBuilder.group({
      GivenName: [null, [Validators.required, Validators.minLength(2)]],
      Email: ['@site.com.br', [Validators.required, Validators.email]],
      StreetName: [null, [Validators.required, Validators.minLength(3)]],
      AddressNumber: [null, [Validators.required, Validators.minLength(1)]],
      CPF: [null, [Validators.required, Validators.minLength(11)]],
      RG: [null, [Validators.required]],
    });
  }

  async getVisitor() {
    return (this.visitor = await this.visitsSrvc.getById(this.visitorId));
  }

  // updateFormEmail() {
  //   this.form.patchValue({ Email: this.incomingEmail });
  // }

  updateForm() {
    this.form.patchValue({ GivenName: this.visitor.name });
    this.form.patchValue({ Email: this.visitor.email });
  }

  submit() {
    console.log(this.form);
    this.create();
  }

  async create() {
    this.loading = true;

    const street = this.form.get('StreetName').value;

    const addressNumber = this.form.get('AddressNumber').value;

    const fullAddress: string = `${street}, ${addressNumber}`;

    const visitor: Visitor = {
      name: String(this.form.get('GivenName').value),
      address: fullAddress,
      cpf: String(this.form.get('CPF').value),
      rg: String(this.form.get('RG').value),
      email: String(this.form.get('Email').value),
      id: this.visitor.id,
    };

    try {
      await this.visitsSrvc.completeRegistration(visitor);
      this.loading = false;
      this.formSubmitedSuccessfully = true;
    } catch (err) {
      console.error('Debug:: Error when completing registration ->', err);
      this.loading = false;
    }
  }

  showError(field?: string) {
    if (!this.form.get(field)) {
      return false;
    }
    return (
      (this.form.get(field).invalid && this.form.get(field).touched) ||
      (!this.form.get(field).touched && this.errorMessage && this.errorMessage.length > 0 && this.errorCode === 400)
    );
  }

  createVisit() {}

  checkForm() {
    console.log(this.form);
    console.log(this.form.errors);
  }
}
