import { Component, OnInit } from '@angular/core';
import { Unit } from '../../entities/units/unit.entity';
import { UnitsService } from '../units.service';
import { ActivatedRoute } from '@angular/router';
import { NavService } from '../../shared/service/nav.service';

@Component({
  selector: 'app-list-units',
  templateUrl: './list-units.component.html',
  styleUrls: ['./list-units.component.scss']
})
export class ListUnitsComponent implements OnInit {

  units: Unit[] = [];
  searchTerm = '';
  loading = false;
  constructor(
    public unitsService: UnitsService,
    public route: ActivatedRoute,
    public navService: NavService,
  ) {
  }

  ngOnInit() {
    this.getAll();
  }

  async getAll() {
    this.loading = true;
    this.units = await this.unitsService.getAll()
    .catch((e) => {
      alert('Erro ao buscar todos locais');
      this.loading = false;
      console.error(e);
      return [];
    });
    await this.navService.getUnitsArray();
    this.loading = false;
  }

}
