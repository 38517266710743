import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Unit } from 'src/app/entities/units/unit.entity';
import { Router, ActivatedRoute } from '@angular/router';
import { NavService } from 'src/app/shared/service/nav.service';
import Swal from 'sweetalert2';
import { BillingService } from '../billing.service';
import { CleaningService, CleaningQR } from '../../cleaning/cleaning.service';
import { GuestBilling } from '../../entities/residents/guestBilling.entity';
import { Guest } from '../../entities/residents/guest.entity';
import { ResidentsService } from '../../residents/residents.service';
import { Room } from '../../entities/rooms/room.entity';
import { RoomsService } from '../../rooms/rooms.service';
import { parseIsoWeekday } from 'ngx-bootstrap/chronos/units/day-of-week';
import { addHours } from 'date-fns';

@Component({
  selector: 'app-map-form',
  templateUrl: './billing-form.component.html',
  styleUrls: ['./billing-form.component.scss'],
})
export class BillingFormComponent implements OnInit {
  form: FormGroup;
  billing: GuestBilling = new GuestBilling();
  loading = false;
  errorMessage: string;
  hotelInfo: Unit;
  id: number = undefined;
  bannerUrl: string | ArrayBuffer;
  pageMode: 'new' | 'edit' = 'new';
  residents: Guest[];
  rooms: Room[];

  constructor(
    private formBuilder: FormBuilder,
    public service: BillingService,
    public router: Router,
    public route: ActivatedRoute,
    public navService: NavService,
    public residentSrvc: ResidentsService,
    public roomsSrvc: RoomsService,
  ) {}

  ngOnInit() {
    this.loading = true;
    this.hotelInfo = this.navService.hotelInfo;
    this.route.params.subscribe(params => {

      if (params.id) {
        this.id = params.id;
        this.initCreateForm();
        this.initUpdateForm();
      } else {
        this.initCreateForm();
      }

      this.loadList();
    });
  }

  async loadList() {
    let residentRes;

    [this.rooms, residentRes] = await Promise.all([
      this.roomsSrvc.getAllFromUnit(this.hotelInfo.hotelCode),
      this.residentSrvc.getAllGuests(this.hotelInfo.hotelCode)
    ]);

    this.residents = residentRes.data;

    this.loading = false;
  }

  initCreateForm() {
    this.pageMode = 'new';
    this.form = this.formBuilder.group({
      Guest: [null, [Validators.required]],
      Description: [null],
      Date: [null, [Validators.required]],
      Value: [null, [Validators.required]],
      RoomNumber: [null, [Validators.required]],
      BillingHidden: [true, [Validators.required]],
    });
  }

  initUpdateForm() {
    this.loading = true;
    this.pageMode = 'edit';
    console.log(this.id);
    this.service.read(this.id).then(
      billing => {
        this.loading = false;
        this.errorMessage = null;

        this.billing = billing;
        this.billing.Date = addHours(this.billing.Date, -3);

        this.form.patchValue({
          ['Guest']: billing.Guest.GuestCode,
          ['Description']: billing.Description,
          ['Date']: String(new Date(billing.Date).toISOString()).slice(0, 16),
          ['Value']: billing.Value,
          ['RoomNumber']: billing.RoomNumber,
          ['BillingHidden']: !!billing.BillingHidden,
        });
      },
      _err => {
        this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
      },
    );
  }

  submit() {
    if (this.id) {
      this.update();
    } else {
      this.create();
    }
  }

  create() {
    this.loading = true;
    this.billing = new GuestBilling(this.form.value);
    this.billing.Guest = new Guest({GuestCode : this.form.value.Guest});
    this.billing.HotelCode = this.hotelInfo.hotelCode;

    const date = new Date(this.form.value.Date);
    const dateUTC =  Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
      date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

    this.billing.Date = new Date(dateUTC).toISOString();

    try {
      this.residentSrvc.read(this.form.value.Guest).then(
        guest => {
          this.billing.LocatorId = guest.LocatorId;
          this.billing.HIGSReservationNumber = guest.HIGSReservationNumber;
          this.billing.PMSReservationNumber = guest.PMSReservationNumber;
          this.billing.SourceReservationNumber = guest.SourceReservationNumber;
          this.service.create(this.billing).then(
            map => {
              this.successNew(map.data);
              this.loading = false;
              this.errorMessage = null;
            },
          );
        }
      );
    } catch (err) {
      this.loading = false;
      if (err.statusCode === 400) {
        this.errorMessage = 'Ops! Verifique os campos abaixo';
        if (err.message && err.message.length > 0) {
          for (const e of err.message) {
            this.form.get(e.property).setErrors({
              error: true,
            });
          }
        }
      } else if (err.statusCode === 409) {
        this.errorMessage = 'Este pagamento já está registrado nessa unidade. Por favor, escolha outro.';
      } else {
        this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
      }
    }
  }

  update() {
    this.loading = true;
    const billing = new GuestBilling({
      ...this.form.value,
      Guest: new Guest({GuestCode : this.form.value.Guest}),
      HotelCode: this.hotelInfo.hotelCode,
    });
    billing.Date = this.form.value.Date;
    this.service.update(billing, this.id).then(
      _ => {
        this.successEdit();
        this.loading = false;
        this.errorMessage = null;
      },
      err => {
        this.loading = false;
        if (err.statusCode === 400) {
          this.errorMessage = 'Ops! Verifique os campos abaixo';
          if (err.message && err.message.length > 0) {
            for (const e of err.message) {
              this.form.get(e.property).setErrors({
                error: true,
              });
            }
          }
        } else if (err.statusCode === 409) {
          this.errorMessage = 'Este parceiro já está registrado nessa unidade. Por favor, escolha outro.';
        } else {
          this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
        }
      },
    );
  }

  showError(field?: string) {
    // console.log('DEBUG: BillingFormComponent -> showError -> field', field);
    return (
      (this.form.get(field).invalid && this.form.get(field).touched) ||
      (!this.form.get(field).touched && this.errorMessage && this.errorMessage.length > 0 && this.form.get(field).invalid)
    );
  }

  successNew(billing: GuestBilling) {
    Swal.fire({
      title: 'Pagamento cadastrado',
      type: 'success',
      showConfirmButton: true,
      confirmButtonText: 'Ir para parceiro',
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary float-left',
      cancelButtonText: 'Cadastrar outro',
      showCloseButton: true,
      onClose: () => this.goToList(),
    }).then(resp => {
      if (resp.value) {
        this.goToDetails(billing.id);
      } else {
        this.goToForm();
      }
    });
  }

  successEdit() {
    return Swal.fire({
      title: 'pagamento editado!',
      type: 'success',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary',
      cancelButtonText: 'Voltar',
      showCloseButton: true,
      onClose: () => {},
    });
  }

  goToList() {
    this.router.navigate(['unit', this.hotelInfo.code, 'billing']);
  }

  goToDetails(id: number) {
    this.router.navigate(['unit', this.hotelInfo.code, 'billing', id, 'edit']);
  }

  goToForm() {
    this.router.navigate(['unit', this.hotelInfo.code, 'billing', 'create']);
  }
}
