import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IssueCategoryFormComponent } from './issue-category-form/issue-category-form.component';
import { IssueCategoriesService } from './issue-categories.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from '../app-routing.module';
import { NgbAlertModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ListIssueCategoriesComponent } from './list-issue-category/list-issue-category.component';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  declarations: [IssueCategoryFormComponent, ListIssueCategoriesComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AppRoutingModule,
    NgbAlertModule,
    NgbModalModule,
    QRCodeModule,
  ],
  providers: [ IssueCategoriesService ]
})
export class IssueCategoriesModule { }
