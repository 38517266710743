import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavService } from '../../shared/service/nav.service';
import { IssueCategory, IssueCategorySortOptions } from '../issue-category.entity';
import { showDefaultErrorAlert } from '../../api.service';
import { IssueCategoriesService } from '../issue-categories.service';
import { maintenanceTypeTranslator } from '../../maintenances/maintenance.service';

@Component({
  selector: 'app-list-issuecategories',
  templateUrl: './list-issue-category.component.html',
  styleUrls: ['./list-issue-category.component.scss'],
})
export class ListIssueCategoriesComponent implements OnInit {
  issueCategories: IssueCategory[] = [];
  searchTerm = '';
  loading = true;
  sortBy: IssueCategorySortOptions;

  sortByOptions = IssueCategorySortOptions;

  maintenanceTypeTranslator = maintenanceTypeTranslator;

  constructor(public service: IssueCategoriesService, public route: ActivatedRoute, public navService: NavService) {}

  ngOnInit() {
    this.navService.currentUnit$.subscribe((unit) => {
      this.getAll();
    });
  }

  loadIssueCategories() {
    let sortOption: string;

    switch (this.sortBy) {
      case IssueCategorySortOptions.CATEGORY:
        // sortOption = 'type,ASC';
        this.sortByCategoryName('ASC');
        break;
      case IssueCategorySortOptions.CATEGORYINVERSE:
        // sortOption = 'type,DESC';
        this.sortByCategoryName('DESC');
        break;
      case IssueCategorySortOptions.SUBCAT:
        sortOption = 'name,ASC';
        break;
      case IssueCategorySortOptions.SUBCATINVERSE:
        sortOption = 'name,DESC';
        break;
      default:
        sortOption = '';
        break;
    }
    console.log('sortOption -> ', sortOption);
    if (this.sortBy === IssueCategorySortOptions.SUBCAT || this.sortBy === IssueCategorySortOptions.SUBCATINVERSE) {
      this.getAll(sortOption);
    }
  }

  sortByCategoryName(order: 'ASC' | 'DESC') {
    this.issueCategories.sort((leftIssueCategory: IssueCategory, rightIssueCategory: IssueCategory) => {
      if (maintenanceTypeTranslator(leftIssueCategory.type) > maintenanceTypeTranslator(rightIssueCategory.type)) {
        return order === 'DESC' ? -1 : 1;
      }
      if (maintenanceTypeTranslator(rightIssueCategory.type) > maintenanceTypeTranslator(leftIssueCategory.type)) {
        return order === 'DESC' ? 1 : -1;
      }
      return 0;
    });
  }

  async getAll(sort?: string) {
    this.loading = true;
    this.issueCategories = await this.service.getAll(sort).catch((e) => {
      showDefaultErrorAlert(e);
      this.loading = false;
      console.error(e);
      return [];
    });
    console.log('this.issueCategories -> ', this.issueCategories);
    this.loading = false;
  }
}
