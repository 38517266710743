export enum AnswerAOS {
  NO = 0,
  PSG = 2,
  PSG_HST = 4,
  HST = 6,
  HST_APNEA = 8,
}

export enum AnswerDiagnosisVolume {
  NONE = 0,
  FEW = 2,
  REGULAR = 4,
  MANY = 6,
  LOTS = 8,
}

export enum AnswerPAP {
  NO = 0,
  LAB = 2,
  LAB_REMOTE = 4,
  REMOTE_ONLY = 6,
  OXIMETRY = 8,
}

export enum AnswerPrescriptionEquipments {
  NO = 0,
  TWO_MORE = 2,
  SUGGEST_RESMED = 4,
  ACTIVE_RESMED = 6,
  ONLY_RESMED = 8,
}

export enum AnswerPrescriptionMasks {
  NO = 0,
  TWO_MORE = 2,
  SUGGEST_RESMED = 4,
  ACTIVE_RESMED = 6,
  ONLY_RESMED = 8,
}

export enum AnswerPrescriptionVolume {
  NONE = 0,
  FEW = 2,
  REGULAR = 4,
  MANY = 6,
  LOTS = 8,
}

export enum AnswerAirViewDiagnosis {
  NO = 0,
  ACCOUNT = 2,
  SOMETIMES = 4,
  HALF = 6,
  AIR_VIEW = 8,
}

export enum AnswerAirViewTherapy {
  NO = 0,
  ACCOUNT = 1,
  SOMETIMES = 2,
  HALF = 3,
  AIR_VIEW = 4,
}

export enum AnswerMyAirPrescription {
  NO = 0,
  PACIENT = 2,
  SOME = 4,
  ALL = 6,
  REGISTER_ALL = 8,
}

export enum AnswerReference {
  CITY = 1,
  STATE = 2,
  REGION = 3,
  COUNTRY = 4,
}

export enum AnswerEventsWorkshop {
  NONE = 0,
  ONE = 2,
  TWO = 3,
  MORE = 4,
}

export enum AnswerEventsLecture {
  NONE = 0,
  ONE = 1,
  TWO = 1.5,
  MORE = 2,
}

export enum AnswerEventsReach {
  NONE = 0,
  FEW = 1,
  REGULAR = 2,
  MANY = 3,
  LOTS = 4,
}

export enum AnswerLecturesRMD {
  NEVER = 0,
  RARELY = 0.5,
  SOMETIMES = 1,
  OFTEN = 1.5,
  ALWAYS = 2,
}

export enum AnswerLaunchesRMD {
  NEVER = 0,
  RARELY = 0.5,
  SOMETIMES = 1,
  OFTEN = 1.5,
  ALWAYS = 2,
}

export enum AnswerWebinarsRMD {
  NEVER = 0,
  RARELY = 0.5,
  SOMETIMES = 1,
  OFTEN = 1.5,
  ALWAYS = 2,
}

export enum AnswerEventsScience {
  NONE = 0,
  REGIONAL = 0.5,
  NATIONAL = 1.5,
  INTERNATIONAL = 2,
}

export enum AnswerPublications {
  NONE = 0,
  REGIONAL = 0.5,
  NATIONAL = 1.5,
  INTERNATIONAL = 2,
}

export enum AnswerPublicationsRMD {
  NONE = 0,
  REGIONAL = 1,
  NATIONAL = 3,
  INTERNATIONAL = 4,
}

export enum AnswerRelevance {
  NONE = 0,
  LOW = 1,
  REGULAR = 2,
  HIGH = 4,
}

export function getAnswerColor(enumerator: object, value: number = NaN) {
  const keys = Object.keys(enumerator)
                .filter(k => !isNaN(k as any))
                .map(k => parseFloat(k))
                .sort();
  const min = keys[0];
  const max = keys[keys.length - 1];
  const note = (value / (max - min)) * 100;
  // if ('INTERNATIONAL' in enumerator) {
  //   console.log("TCL: getAnswerColor -> keys", keys)
  //   console.log("TCL: getAnswerColor -> min", min)
  //   console.log("TCL: getAnswerColor -> max", max)
  //   console.log("TCL: getAnswerColor -> note", note)
  // }

  if (isNaN(value)) {
    return ['badge-light'];
  } else if (note < 15) {
    return ['bg-scale-0'];
  } else if (note >= 15 && note < 30) {
    return ['bg-scale-1'];
  } else if (note >= 30 && note < 50) {
    return ['bg-scale-2'];
  } else if (note >= 50 && note < 70) {
    return ['bg-scale-3'];
  } else if (note >= 70 && note < 90) {
    return ['bg-scale-4'];
  } else if (note >= 90 && note <= 100) {
    return ['bg-scale-5'];
  }
}

export function getEnumValue(enumerator: object, index: number) {
  const keys = Object.keys(enumerator).filter(k => isNaN(k as any));
  // console.log("TCL: getEnumValue -> keys", keys)

  return enumerator[keys[index]];
}
