export enum ReservationStatus {
  Confirmed = 1,
  ConfirmedWithChanges = 2,
  Cancelled = 3,
  CheckedIn = 4,
  CheckedOut = 5,
  NoShow = 6,
  CheckInNotAccepted = 10,
  CheckOutNotAccepted = 11,
  CheckInConfirmed = 12,
  CheckOutConfirmed = 13,
}

export interface GetRequestAttributes {
  HIGSReservationNumber?: string;
  PMSReservationNumber?: string;
  SourceReservationNumber?: string;
  ReservationStatus?: ReservationStatus;
  GuestRoom?: string;
  GuestCode?: number;
}

export interface GetManyResponse<T> {
  ResponseList: T[];
  Page: string;
  NextPage: boolean;
}

export enum DocumentType {
  None = 0,
  CPF = 1,
  CivilIdentification = 2,
  BirthCertificate = 3,
  Passport = 4,
}

export enum TravelReason {
  None = 0,
  Tourism = 1,
  Business = 2,
  Leisure = 3,
  Conventions = 4,
  Other = 5,
}

export enum Transport {
  None = 0,
  Ship = 1,
  Airplane = 2,
  Car = 3,
  Other = 4,
}

export interface GuestTotvs {
  Code: number;
  Name?: string;
  MainGuest?: boolean;
}

export class GuestInformation {
  LocatorId: string;
  HotelCode: number;
  Guest: GuestTotvs;
  ReservationIds: {
    HIGSReservationNumber: number;
    PMSReservationNumber: number;
    SourceReservationNumber: number;
  };
  GivenName: string;
  SurName: string;
  Email: string;
  Profession: string;
  BirthDate: string | Date;
  Gender: 'Male' | 'Female';
  DocumentType: DocumentType;
  DocumentNumber: string;
  GuestAddress: {
    StreetName: string;
    AddressNumber: string;
    Complement: string;
    PostalCode: string;
    District: string;
    CityName: string;
    StateName: string;
    StateCode: string;
    CountryName: string
  };
  Nationality: string;
  PhoneNumber: string;
  CelPhoneNumber: string;
  LastSource: string;
  NextStop: string;
  TravelReason: TravelReason;
  Transport: Transport;
  Checkin: Date;
  Checkout: Date;
}

export interface GuestBilling {
  LocatorId: number;
  HotelCode: number;
  Guest: GuestTotvs;
  Description: string;
  Date: string;
  Value: number;
  ReservationsIds: {
    HIGSReservationNumber: number;
    PMSReservationNumber: number;
    SourceReservationNumber: number;
  };
  RoomNumber: string;
  BillingHidden: boolean;
}
