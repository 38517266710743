import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListEmployeesComponent } from './list-employees/list-employees.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from '../app-routing.module';
import { EmployeesService } from './employees.service';
import { EmployeeFormComponent } from './employee-form/employee-form.component';
import { NgbAlertModule, NgbTooltipModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { DetailsEmployeeComponent } from './details-employee/details-employee.component';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [ListEmployeesComponent, EmployeeFormComponent, DetailsEmployeeComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AppRoutingModule,
    NgbAlertModule,
    NgbPaginationModule,
    NgxMaskModule,
    NgbTooltipModule,
    ToastrModule
  ],
  providers: [EmployeesService],
  exports: [ListEmployeesComponent, EmployeeFormComponent],
})
export class EmployeesModule { }
