import { Component, OnInit } from '@angular/core';
import { PROFESSIONS_NAME, Professional } from '../professionals/professional.entity';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProfessionalsService } from '../professionals/professionals.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-professional',
  templateUrl: './new-professional.component.html',
  styleUrls: ['./new-professional.component.scss']
})
export class NewProfessionalComponent implements OnInit {

  form: FormGroup;
  professional: Professional = new Professional();
  loading = false;
  errorMessage: string;
  constructor(
    private formBuilder: FormBuilder,
    public professionalService: ProfessionalsService,
    public router: Router) {
    }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [null, Validators.required],
      email: null,
      phone: null,
      city: [null, Validators.required],
      state: [null, Validators.required],
      countryCode: ['BR', Validators.required],
      job: [null, Validators.required],
      profession: [null, Validators.required, Validators.minLength(1)],
    });
  }

  createProfessional() {
    this.loading = true;
    this.professionalService.create(this.form.value).subscribe(prof => {
      this.showSuccess(prof);
      this.loading = false;
      this.errorMessage = null;
    }, err => {
      console.error('TCL: NewProfessionalComponent -> createProfessional -> err', err);
      this.loading = false;
      if (err.error.statusCode === 400) {
        this.errorMessage = 'Ops! Verifique os campos abaixo';
      } else {
        this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
      }
    });
  }

  showError(field?: string) {
    return (this.form.get(field).invalid && this.form.get(field).touched) ||
           (!this.form.get(field).touched && this.errorMessage && this.errorMessage.length > 0);
  }

  showSuccess(prof: Professional) {
    Swal.fire({
      title: 'Profissional criado!',
      text: 'Deseja fazer uma avaliação desse profissional agora?',
      type: 'success',
      showConfirmButton: true,
      confirmButtonText: 'Fazer avaliação',
      showCancelButton: true,
      cancelButtonClass: 'btn-secondary',
      cancelButtonText: 'Cadastrar outro',
      showCloseButton: true,
      onClose: () => this.goToProfessional(prof.id),
    }).then(resp => {
      if (resp.value) {
        this.goToNewEvaluation(prof.id);
      } else {
        this.goToNewProfessional();
      }
    });
  }

  goToProfessional(id: number) {
    this.router.navigateByUrl('/professionals/' + id);
  }

  goToNewEvaluation(id: number) {
    this.router.navigateByUrl('/professionals/' + id + '/evaluate');
  }

  goToNewProfessional() {
    this.router.navigateByUrl('/professionals/');
  }

  get professions(): {key: string, label: string}[] {
    const professions = Object.keys(PROFESSIONS_NAME);
    return professions.map((prof) => {
      return {key: prof, label: PROFESSIONS_NAME[prof]};
    });
  }

}
