import { RestEntity } from '../rest.entity';
import { Cleaning } from './cleaning.entity';
import { Unit } from '../units/unit.entity';

export enum Frequency {
  SINGLE = 'SINGLE',
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'BIWEEKLY',
  MONTHLY = 'MONTHLY',
  PACKAGE = 'PACKAGE',
}
export class CleaningType extends RestEntity<CleaningType> {
  cleaning?: Cleaning[];

  name: string;

  price: number;

  duration: number;

  description: string;

  iconUrl?: string;

  frequency?: Frequency;

  packQuantity: number;

  showToGuests?: boolean;

  unit: Unit;

  get frequencyText() {
    switch (this.frequency) {
      case Frequency.SINGLE:
        return 'Avulsa';
      case Frequency.WEEKLY:
        return 'Semanal';
      case Frequency.BIWEEKLY:
        return 'Quinzenal';
      case Frequency.MONTHLY:
        return 'Mensal';
      case Frequency.PACKAGE:
        return 'Pacote';
      default:
        return '?';
    }
  }
}
