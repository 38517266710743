import { Component, OnInit, ViewChild, TemplateRef, HostListener } from '@angular/core';
import { CalendarView, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarMonthViewDay, CalendarMonthViewBeforeRenderEvent } from 'angular-calendar';
import { addHours, startOfDay, addMinutes, differenceInMinutes, getYear, getMonth, getDate, isBefore, isAfter, isSameMonth, isSameDay, setHours } from 'date-fns';
import { CalendarEvent } from 'calendar-utils';
import { ActivatedRoute, Router } from '@angular/router';
import { CleaningReport, CleaningService } from '../cleaning.service';
import { Unit } from 'src/app/entities/units/unit.entity';
import { NavService } from 'src/app/shared/service/nav.service';
import { Cleaning, CleaningPeriod } from 'src/app/entities/cleaning/cleaning.entity';
import { Employee } from 'src/app/entities/employees/employee.entity';
import { EmployeesService } from 'src/app/employees/employees.service';
import Swal from 'sweetalert2';
import { formatDate } from '@angular/common';
import { Subject } from 'rxjs';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CanComponentDeactivate } from '../../shared/guard/can-deactivate.guard';
import { CleaningStatus, translatePeriod, translateStatus } from '../../entities/cleaning/cleaning.entity';
import { SchedulesService } from 'src/app/unit/schedules.service';
import { CalendarFormComponent } from '../calendar-form/calendar-form.component';
import { UserRole } from '../../entities/residents/user.entity';
import { CleaningLimit, CleaningLimitInput, CleaningLimitsService } from '../cleaning-limit.service';
import { ToastrService } from 'ngx-toastr';
import { Workbook } from 'exceljs';
import { convertToTimeZone } from 'date-fns-timezone';
import { downloadFile } from '../../maintenances/maintenances-reports/maintenances-reports.component'
import { translatePackStatus } from 'src/app/entities/cleaning/cleaning-pack.entity';

const randomColor = () => '#000000'.replace(/0/g, () => Math.round(Math.random() * 12).toString(16));
const defaultUser = {
  id: null,
  name: 'Solicitadas',
  color: {
    primary: '#F18041',
    secondary: '#F18041',
  },
  avatar: null,
};

@Component({
  selector: 'app-calendar-component',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  styles: [
    `
      .bg-pink div.call-cel-top {
        background-color: orange !important;
      }
    `,
  ],
})
export class CalendarPageComponent implements OnInit, CanComponentDeactivate {
  refresh: Subject<any> = new Subject();

  renderEvent: CalendarMonthViewBeforeRenderEvent;

  constructor(
    private activeModal: NgbActiveModal,
    private modal: NgbModal,
    public service: CleaningService,
    public employeeSrvc: EmployeesService,
    public route: ActivatedRoute,
    public router: Router,
    public schedulesService: SchedulesService,
    public cleaningService: CleaningService,
    public navService: NavService,
    private cleaningLimitsSrvc: CleaningLimitsService,
    private toastSrvc: ToastrService,
  ) {
    this.cleaningLimits = [];
    this.unavailableDays = [];

    // initially set default values for limits form (may be overwritten later by fetched data)
    this.morningLimit = 5;
    this.afternoonLimit = 5;
    this.nightLimit = 0;

    this.selectedDays = [];
  }
  hasChanges = false;
  viewDate = new Date();
  hotelInfo: Unit;
  loading = true;
  cleanings: Cleaning[];
  cleaningsReports: CleaningReport[];
  employees: Employee[];
  events: CalendarEvent<any>[] = [];
  dateConstraint = {};
  users = [defaultUser];
  view: CalendarView = CalendarView.Month;
  activeDayIsOpen = true;
  CalendarView = CalendarView;
  CleaningStatus = CleaningStatus;

  savingCleaningLimit: boolean;
  cleaningLimits: CleaningLimit[];
  /**
   * Days where there's no availability in all periods
   */
  unavailableDays: CleaningLimit[];

  morningLimit: number;
  afternoonLimit: number;
  nightLimit: number;

  morningUnavailable: boolean;
  afternoonUnavailable: boolean;
  nightUnavailable: boolean;

  isEditingAvailability: boolean;
  selectedMonthViewDay: CalendarMonthViewDay;
  selectedDays: any;
  savingUnavailability: boolean;

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil event-title-margin text-white float-right"></i>',
      a11yLabel: 'Edit',
      cssClass: 'white',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Clicked', event);
      },
    },
  ];

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  modalData: {
    action: string;
    event: CalendarEvent;
  };
  trackByEventId = (index: number, event: CalendarEvent) => (event.id ? event.id : event);

  ngOnInit() {
    this.navService.currentUnit$.subscribe(unit => {
      this.hotelInfo = unit;

      this.getAll();
      this.getCleaningLimits();
    });
  }

  async getCleaningLimits() {
    if(!this.hotelInfo) return;

    this.cleaningLimits = await this.cleaningLimitsSrvc.getUnitCleaningLimits(this.hotelInfo.hotelCode);

    // map date to Date object
    this.cleaningLimits = this.cleaningLimits.map(cleaningLimit => {
      cleaningLimit.date = new Date(cleaningLimit.date);
      return cleaningLimit;
    });

    if (this.cleaningLimits && this.cleaningLimits.length > 0) {
      this.getUnavailableDays();
    } else {
      this.unavailableDays = [];
    }

    console.log('this.cleaningLimits', this.cleaningLimits);
  }

  getUnavailableDays() {
    this.unavailableDays = this.cleaningLimits.filter(cleaningLimit => {
      const { morning, afternoon, night } = cleaningLimit;

      return Number(morning) === 0 && Number(afternoon) === 0 && Number(night) === 0;
    });

    console.log('this.unavailableDays -> ', this.unavailableDays);
  }

  canDeactivate() {
    if (this.hasChanges) {
      return window.confirm('Há alterações não salvas. Deseja realmente sair?');
    }
    return true;
  }

  async getAll(report?) {
    if(!this.hotelInfo) return;
    this.loading = true;

    try {

      if (report) {
        this.cleaningsReports = await this.service.getCleaningReport(this.hotelInfo.hotelCode, this.viewDate);
      } else {
        this.cleanings = await this.service.getAllCleanings(this.hotelInfo.hotelCode, this.viewDate);
      }

      const cleaning = (await this.employeeSrvc.search(this.hotelInfo.hotelCode, '', UserRole.Cleaning)).data;
      const facilities = (await this.employeeSrvc.search(this.hotelInfo.hotelCode, '', UserRole.Facilities)).data;
      this.employees = [...cleaning, ...facilities];
    } catch (e) {
      console.error(e);
      alert('Erro ao buscar tipos de limpeza');
      this.loading = false;
      return [];
    }

    if (!report) {
      this.makeUsers(this.employees);
      this.makeEvents(this.cleanings);
    }

    this.loading = false;
  }

  makeUsers(employees: Employee[]) {
    this.users = [defaultUser];
    for (let i = 0; i < employees.length; i++) {
      const employee = employees[i];
      this.users.push({
        id: employee.id,
        name: `${employee.firstName} ${employee.lastName}`,
        avatar: employee.avatarUrl,
        color: {
          primary: this.getColorByEmployee(employee),
          secondary: this.getColorByEmployee(employee),
        },
      });
    }
  }

  makeTime(cleaning: Cleaning) {
    // TODO test with midnight problem
    // get start and end of the current cleaning
    const date = new Date(cleaning.date).getTime();
    const unit = cleaning.unit;
    const periods = { start: '', end: '' };
    if (cleaning.period === CleaningPeriod.MORNING) {
      periods.start = unit.morningStart || '';
      periods.end = unit.morningEnd || '';
    } else if (cleaning.period === CleaningPeriod.AFTERNOON) {
      periods.start = unit.afternoonStart || '';
      periods.end = unit.afternoonEnd || '';
    } else {
      periods.start = unit.nightStart || '';
      periods.end = unit.nightEnd || '';
    }
    const periodStart = new Date();
    periodStart.setHours(parseInt(periods.start.substring(0, 2), 10));
    periodStart.setMinutes(parseInt(periods.start.substring(3, 5), 10));
    const periodEnd = new Date();
    periodEnd.setHours(parseInt(periods.end.substring(0, 2), 10));
    periodEnd.setMinutes(parseInt(periods.end.substring(3, 5), 10));

    // get duration default 1h
    const duration = cleaning.cleaningType ? cleaning.cleaningType.duration : 60;

    // set start and end date for the request
    if (this.dateConstraint[date]) {
      this.dateConstraint[date] += duration;
      // if the time is higer than the period reset the time constraint
      if (this.dateConstraint[date] >= differenceInMinutes(periodEnd, periodStart)) {
        this.dateConstraint[date] -= differenceInMinutes(periodEnd, periodStart);
      }
    } else {
      this.dateConstraint[date] = duration;
    }
    // set start date: the midnight of the requested day + (the initial time of the period + already added requests)
    cleaning.startDate = addHours(startOfDay(cleaning.date), addMinutes(periodStart, this.dateConstraint[date]).getHours());
    cleaning.endDate = addMinutes(cleaning.startDate, duration); // add the duration as minutes

    return cleaning;
  }

  makeEvents(cleanings: Cleaning[]) {
    this.events = [];
    for (let i = 0; i < cleanings.length; i++) {
      let cln = cleanings[i];
      if (!cln.startDate || !cln.endDate) {
        cln = this.makeTime(cln);
      } else {
        cln.startDate = new Date(String(cln.startDate));
        cln.endDate = new Date(String(cln.endDate));
      }
      let found;
      if (cln.employee) {
        found = this.users.map(e => e.id).indexOf(cln.employee.id);
      }
      if (!cln.room) {
        console.log('NO ROOM: ', cln);
      }
      const editPermit = this.navService.checkPermission('cleaning', 'update');
      const formattedTitle = `<b class='event-title-margin'>${cln.room ? cln.room.roomNumber : ""}<b><br><small>${cln.cleaningType.name}</small>`;
      this.events.push({
        title: cln.room ? formattedTitle : cln.area ? cln.area.name : "Horário bloqueado",
        color: {
          primary: this.getColorByStatus(cln.status),
          secondary: this.getColorByStatus(cln.status),
        },
        meta: {
          changed: false,
          cleaning: cln,
          user: !found || found === -1 ? this.users[0] : this.users[found],
          users: this.users,
        },
        actions: editPermit ? this.actions : undefined,
        start: cln.startDate,
        end: cln.endDate,
        resizable: {
          beforeStart: !cln.room, // area requests will be resizable
          afterEnd: !cln.room,
        },
        draggable: true,
      });
    }
  }

  async exportExcel() {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Limpezas');

    worksheet.columns = [
      { header: 'Número do quarto', key: 'roomNumber'},
      { header: 'Data', key: 'date'},
      { header: 'Período', key: 'period'},
      { header: 'Status', key: 'status'},
      { header: 'Valor total', key: 'totalPrice'},
      { header: 'Solicitado por', key: 'requester'},
      { header: 'Tipo de limpeza', key: 'type'},
    ];

    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true };
    });

    await this.getAll(true);

    const rows = this.cleaningsReports.map(cleaning => {
      console.log('DEBUG: CalendarComponent -> exportExcel -> cleaning', cleaning);
      return {
        roomNumber: cleaning.roomNumber || '',
        date: this.formattingDate(cleaning.date),
        period: translatePeriod(cleaning.period) || '',
        status: translateStatus(cleaning.status) || translatePackStatus(cleaning.status) || '',
        totalPrice: Number(cleaning.totalPrice).toLocaleString('pt-BR', {minimumFractionDigits: 2}),
        requester: cleaning.user? cleaning.user.name : "Não informado",
        type: cleaning.cleaningType.name,
      }
    });

    worksheet.addRows(rows);
    const month = this.viewDate.toLocaleString('pt-BR', { month: 'long' });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    downloadFile(blob, 'Limpezas ' + this.hotelInfo.name + ' - ' + month.charAt(0).toUpperCase() + month.slice(1));
  }

  formattingDate(date: Date) {
    date = convertToTimeZone(new Date(date), { timeZone:'America/Recife' });
    return formatDate(new Date(date), 'dd/MM/yyyy HH:mm', 'pt-PT');
  }

  eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    this.hasChanges = true;
    event.start = newStart;
    event.end = newEnd;
    event.meta.changed = true;

    event.meta.cleaning.startDate = event.start;
    event.meta.cleaning.endDate = event.end;

    this.events = [...this.events];
  }

  userChanged({ event, newUser }) {
    this.hasChanges = true;
    event.color = {
      primary: this.getColorByStatus(event.meta.cleaning.status),
      secondary: this.getColorByStatus(event.meta.cleaning.status),
    }
    event.meta.user = newUser;
    event.meta.changed = true;

    event.meta.cleaning.startDate = event.start;
    event.meta.cleaning.endDate = event.end;

    if (event.meta.user.id === 0) {
      event.meta.cleaning.status = 'Requested';
      event.meta.cleaning.employee = null;
    } else if (event.meta.cleaning.status === 'Requested') {
      event.meta.cleaning.status = 'Confirmed';
      event.meta.cleaning.employee = newUser;
    } else {
      event.meta.cleaning.employee = newUser;
    }

    this.events = [...this.events];
  }

  makeAssign() {
    const cleanings = [];
    this.events.forEach(e => {
      if (e.meta.changed) {
        if (this.checkDates(e.meta.cleaning)) {
          cleanings.push(e.meta.cleaning);
        }
      }
    });
    console.log(cleanings);
    if (cleanings) {
      this.service
        .assign(cleanings)
        .then(cln => {
          Swal.fire({
            title: 'Limpezas marcadas com sucesso',
            type: 'success',
            showConfirmButton: true,
            confirmButtonText: 'Ok',
            showCloseButton: true,
          });
          this.hasChanges = false;
        })
        .catch(err => {
          const errorTranslation = {
            "BUSY_EMPLOYEE": "Funcionário já possui uma limpeza neste horário",
            "CHOSEN_PERIOD_AND_DAY_NOT_AVAILABLE": "Período escolhido não disponível. Verifique os limites diários e de turno",
            "ASSIGN_OUT_OF_DATE_NOT_ALLOWED": "Não é permitido marcar por mais de um dia",
            "REQUEST_ALREADY_CANCELLED": "Limpeza já foi cancelada",
            "REQUEST_ALREADY_REFUSED": "Limpeza já foi recusada",
            "CLEANING_NOT_FOUND": "Limpeza não encontrada",
            "CLEAN_TOO_SOON": "Agendamento deve ser feito com pelo menos 48h de antecedência",
          }

          Swal.fire({
            title: 'Ação inválida',
            text: errorTranslation[err.error] || 'Mensagem: ' + err,
            showConfirmButton: true,
            confirmButtonText: 'Ok',
            showCloseButton: true,
            type: 'error',
          });
        });
    }
  }

  checkDates(cln: Cleaning) {
    const year = getYear(cln.startDate);
    const month = getMonth(cln.startDate);
    const day = getDate(cln.startDate);
    let minStart: Date;
    let maxEnd: Date;

    if (cln.period === CleaningPeriod.MORNING) {
      const [minStartHours, minStartMinutes] = cln.unit.morningStart.split(':');
      const [maxEndHours, maxEndMinutes] = cln.unit.morningEnd.split(':');

      minStart = this.set(Number(minStartHours), Number(minStartMinutes), year, month, day);
      maxEnd = this.set(Number(maxEndHours), Number(maxEndMinutes), year, month, day);
      console.log(minStart);
      console.log(maxEnd);
    }

    if (cln.period === CleaningPeriod.AFTERNOON) {
      const [minStartHours, minStartMinutes] = cln.unit.afternoonStart.split(':');
      const [maxEndHours, maxEndMinutes] = cln.unit.afternoonEnd.split(':');

      minStart = this.set(Number(minStartHours), Number(minStartMinutes), year, month, day);
      maxEnd = this.set(Number(maxEndHours), Number(maxEndMinutes), year, month, day);
    }

    if (cln.period === CleaningPeriod.NIGHT) {
      const [minStartHours, minStartMinutes] = cln.unit.nightStart.split(':');
      const [maxEndHours, maxEndMinutes] = cln.unit.nightEnd.split(':');

      minStart = this.set(Number(minStartHours), Number(minStartMinutes), year, month, day);
      maxEnd = this.set(Number(maxEndHours), Number(maxEndMinutes), year, month, day);
    }

    /** checking start and end date informed by the admin */
    if (isBefore(cln.startDate, minStart)) {
      Swal.fire({
        title: 'Período incorreto',
        text:
          'A limpeza do apto. ' +
          (cln.room ? cln.room.roomNumber : cln.area.name) +
          ' precisa ser agendada entre ' +
          formatDate(minStart, 'HH:mm', 'pt-PT') +
          ' e ' +
          formatDate(maxEnd, 'HH:mm', 'pt-PT'),
        showConfirmButton: true,
        confirmButtonText: 'Ok',
        showCloseButton: true,
        type: 'error',
      });
      throw new Error('Start hour can not be before start of period');
    }

    if (isAfter(cln.endDate, maxEnd)) {
      Swal.fire({
        title: 'Período incorreto',
        text:
          'A limpeza do apto. ' +
          (cln.room ? cln.room.roomNumber : cln.area.name) +
          ' precisa ser agendada entre ' +
          formatDate(minStart, 'HH:mm', 'pt-PT') +
          ' e ' +
          formatDate(maxEnd, 'HH:mm', 'pt-PT'),
        showConfirmButton: true,
        confirmButtonText: 'Ok',
        showCloseButton: true,
        type: 'error',
      });
      throw new Error('End hour can not be after end of period');
    }

    return true;
  }

  set(hours, minutes, year, month, date) {
    const baseDate = new Date();

    if (hours !== undefined && minutes !== undefined && year !== undefined && month !== undefined && date !== undefined) {
      baseDate.setHours(hours);
      baseDate.setMinutes(minutes);
      baseDate.setFullYear(year, month, date);
      return baseDate;
    }
    throw new Error('Broken Date' + baseDate + '/' + hours + '/' + minutes + '/' + year + '/' + month + '/' + date);
  }

  @HostListener('window:beforeunload', ['$event'])
  canLeavePage($event: Event) {
    $event.returnValue = !this.hasChanges;
    return !this.hasChanges;
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
    if (this.view === CalendarView.Month) {
      this.getAll();
    } else {
      this.getCleaningLimitForSelectedDay();
      this.setView(CalendarView.Day);
    }
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    this.loading = true;
    if (isSameMonth(date, this.viewDate)) {
      if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
      this.getCleaningLimitForSelectedDay();
      this.setView(CalendarView.Day);
    }
    this.loading = false;
  }

  getCleaningLimitForSelectedDay() {
    if (this.cleaningLimits.length === 0) {
      return;
    }

    const foundCleaningLimit = this.cleaningLimits.find(cleaningLimit => isSameDay(cleaningLimit.date, this.viewDate));
    console.log('foundCleaningLimit -> ', foundCleaningLimit);

    if (!foundCleaningLimit) {
      // back to default values
      this.morningLimit = 5;
      this.afternoonLimit = 5;
      this.nightLimit = 0;
    } else {
      this.morningLimit = foundCleaningLimit.morning;
      this.afternoonLimit = foundCleaningLimit.afternoon;
      this.nightLimit = foundCleaningLimit.night;
    }

    this.morningUnavailable = Number(this.morningLimit) === 0;
    this.afternoonUnavailable = Number(this.afternoonLimit) === 0;
    this.nightUnavailable = Number(this.nightLimit) === 0;
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    const scheduleModal = this.modal.open(CalendarFormComponent, { size: 'lg', centered: true });
    const { id: cleaningId } = event.meta.cleaning;
    scheduleModal.componentInstance.isModal = true;
    scheduleModal.componentInstance.id = cleaningId;

    scheduleModal.result.then(res => {
      if (res.updated) {
        // this.loadSchedule();
        this.getAll();
      }
    });
  }

  createEvent(eventDate): void {
    this.router.navigate(['/unit',this.hotelInfo.code,'cleaning', 'calendar', 'create', { date: eventDate }]);
  }

  countEvents(events: CalendarEvent[], status: CleaningStatus) {
    return events.filter(ev => ev.meta.cleaning.status === status).length;
  }

  mapToCalendar(cleaning: Cleaning): CalendarEvent<Cleaning> {
    cleaning = new Cleaning(cleaning);
    return {
      start: new Date(cleaning.startDate),
      end: new Date(cleaning.endDate),
      title: cleaning.room ? cleaning.room.roomNumber : cleaning.area.name,
      meta: cleaning,
      draggable: false,
      resizable: {
        beforeStart: false,
        afterEnd: false,
      },
      // color: {
      //   secondary: this.getColorBySpace(schedule),
      //   primary: this.getColorBySpace(schedule),
      // },
    };
  }

  getColorByStatus(status){
    const statusColor = {
      Requested: "#f18041",
      Confirmed: "#f18041",
      Started: "#09c73a",
      Finished: "#09c73a",
      Refused: "#ff2f2f",
      Cancelled: "",
    }

    return statusColor[status]  || "#f18041";
  }

  getColorByEmployee(user?: Employee) {
    const colors = [
      '#056E38',
      '#087C7C',
      '#0B588B',
      '#0F1599',
      '#5E14A8',
      '#B619B6',
      '#C51F74',
      '#D22625',
      '#DC862C',
      '#E2E633',
      '#8FEF3B',
      '#E9C441',
      '#E24D48',
      '#DC4EB4',
      '#9954D6',
      '#5B74D0',
      '#61C6C9',
    ];

    if (!user) {
      return '#F18041';
    }
    const id = (user.id * 15) % colors.length;
    return colors[id];
  }

  async createOrSaveCleaningLimit() {
    if (this.morningUnavailable) {
      this.morningLimit = 0;
    }

    if (this.afternoonUnavailable) {
      this.afternoonLimit = 0;
    }

    if (this.nightUnavailable) {
      this.nightLimit = 0;
    }

    // set hour to 12:00pm to avoid timezone conflicts and inconsistency
    const cleaningLimitDate = setHours(this.viewDate, 12);

    const cleaningLimitPayload: CleaningLimitInput = {
      unit: this.hotelInfo.hotelCode,
      date: cleaningLimitDate.toISOString().slice(0, 16),
    };

    if (this.hotelInfo.morningStart) {
      Object.assign(cleaningLimitPayload, { morning: this.morningLimit });
    }

    if (this.hotelInfo.afternoonStart) {
      Object.assign(cleaningLimitPayload, { afternoon: this.afternoonLimit });
    }

    if (this.hotelInfo.nightStart) {
      Object.assign(cleaningLimitPayload, { night: this.nightLimit });
    }

    console.log('cleaningLimit -> ', cleaningLimitPayload);

    const foundCleaningLimit = this.cleaningLimits.find(cleaningLimit => isSameDay(cleaningLimit.date, this.viewDate));

    try {
      this.savingCleaningLimit = true;
      if (foundCleaningLimit) {
        await this.cleaningLimitsSrvc.updateCleaningLimit(this.hotelInfo.hotelCode, foundCleaningLimit.id, cleaningLimitPayload);
      } else {
        await this.cleaningLimitsSrvc.createCleaningLimit(this.hotelInfo.hotelCode, cleaningLimitPayload);
      }
      this.getCleaningLimits();
      this.toastSrvc.success('Limites salvos com sucesso');
    } catch (err) {
      this.toastSrvc.error('Erro ao salvar limite de limpezas para esse dia');
    } finally {
      this.savingCleaningLimit = false;
    }
  }

  async toggleEditingAvailability() {
    // will save
    if (this.isEditingAvailability) {
      this.savingUnavailability = true;
      await this.saveDaysOfUnavailability();
      await this.getCleaningLimits();
      this.savingUnavailability = false;
    }

    this.isEditingAvailability = !this.isEditingAvailability;
  }

  async saveDaysOfUnavailability() {
    // array containing only the dates selected
    const selectedDates: any[] = this.selectedDays.map(selectedDay => selectedDay.date);
    const daysToTurnAvailableAgain = this.unavailableDays.filter(unavailableDay => {
      const wasSelectedDayUnavailable = this.selectedDays.findIndex(selectedDay => isSameDay(new Date(selectedDay.date), new Date(unavailableDay.date)));
      return wasSelectedDayUnavailable === -1;
    });

    const daysToTurnUnavailable = selectedDates.filter(selectedDate => {
      const datesToTurnUnavailable = daysToTurnAvailableAgain.findIndex(dayToTurnUnavailable => isSameDay(new Date(dayToTurnUnavailable.date), new Date(selectedDate.date)));
      const datesAlreadyUnavailable = this.unavailableDays.findIndex(unavailableDay => isSameDay(new Date(unavailableDay.date), new Date(selectedDate.date)));
      return !(datesToTurnUnavailable > -1) && !(datesAlreadyUnavailable > -1);
    });

    const payload: CleaningLimitInput[] = daysToTurnUnavailable.map(day => {
      const chosenDay = setHours(day, 12);

      return {
        unit: this.hotelInfo.hotelCode,
        date: chosenDay.toISOString().slice(0, 16),
        morning: 0,
        afternoon: 0,
        night: 0,
      };
    });

    console.log('payload -> payload', payload);

    if ((payload && payload.length > 0) || (daysToTurnUnavailable && daysToTurnUnavailable.length > 0)) {
      try {
        await this.cleaningLimitsSrvc.createCleaningLimits(this.hotelInfo.hotelCode, payload);
        this.toastSrvc.success('Alterações salvas com sucesso');
      } catch (err) {
        this.toastSrvc.error('Não foi possível salvar suas alterações');
      }
    }

    if (daysToTurnAvailableAgain && daysToTurnAvailableAgain.length > 0) {
      try {
        await this.cleaningLimitsSrvc.deleteCleaningLimits(daysToTurnAvailableAgain.map(dayToTurnAvailableAgain => dayToTurnAvailableAgain.id));
        this.toastSrvc.success('Alterações salvas com sucesso');
      } catch (err) {
        this.toastSrvc.error('Não foi possível salvar suas alterações');
      }
    }
  }

  chooseDay(day: CalendarMonthViewDay): void {
    this.selectedMonthViewDay = day;
    const selectedDateTime = this.selectedMonthViewDay.date.getTime();
    const dateIndex = this.selectedDays.findIndex(selectedDay => selectedDay.date.getTime() === selectedDateTime);
    if (dateIndex > -1) {
      delete this.selectedMonthViewDay.cssClass;
      this.selectedDays.splice(dateIndex, 1);
    } else {
      this.selectedDays.push(this.selectedMonthViewDay);
      day.cssClass = 'cal-day-selected';
      this.selectedMonthViewDay = day;
    }
  }

  beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
    this.renderEvent = renderEvent;
    renderEvent.body.forEach(day => {
      if (this.unavailableDays && this.unavailableDays.length > 0) {
        const unavailableDate = this.unavailableDays.find(unavailableDay => isSameDay(unavailableDay.date, day.date));

        if (unavailableDate) {
          const dateAlreadyPushed = this.selectedDays.find(selectedDay => isSameDay(selectedDay.date, unavailableDate.date));
          if (!dateAlreadyPushed) {
            this.selectedDays.push(day);
          }
          day.cssClass = 'cal-day-selected';
        }
      }
    });
  }
}
