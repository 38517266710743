import { Injectable } from '@angular/core';
import { APIService } from '../api.service';
import { Unit } from '../entities/units/unit.entity';
import { Area } from '../entities/units/area.entity';

@Injectable({
  providedIn: 'root'
})
export class AreasService {

  constructor(
    public API: APIService
  ) { }

  async getAllFromUnit(hotelCode: number): Promise<Area[]> {
    const areas = await this.API.get<Area[]>('/areas', {
      filter: 'unit.HotelCode||eq||' + hotelCode
    }, {tokenRequired: true});
    if (areas && areas.length) {
      return areas.map(s => new Area(s));
    }
    return [];
  }

  create(area: Area) {
    area = this.clearNulls(area);
    return this.API.post<Area, Area>('/areas', area, {tokenRequired: true});
  }

  read(id: number) {
    return this.API.get<Area>(`/areas/${id}`, {}, {tokenRequired: true});
  }

  update(area: Area, id: number) {
    return this.API.patch<Area, Area>(`/areas/${id}`, area, {tokenRequired: true});
  }

  delete(id: number) {
    return this.API.delete<Area>(`/areas/${id}`, {}, {tokenRequired: true});
  }


  clearNulls(area: Area) {
    for (const key in area) {
      if (area[key] === null || area[key] === undefined) {
        delete area[key];
      }
    }
    return area;
  }

  getQR(hotelCode: number) {
    return this.API.get<any>('/cleaning/QR/generateArea', {hotelCode}, { tokenRequired: true });
  }
}
