import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Unit } from 'src/app/entities/units/unit.entity';
import { Router, ActivatedRoute } from '@angular/router';
import { NavService } from 'src/app/shared/service/nav.service';
import Swal from 'sweetalert2';
import { Room } from 'src/app/entities/rooms/room.entity';
import { RoomsService } from '../rooms.service';
import { CleaningService, CleaningQR } from '../../cleaning/cleaning.service';

@Component({
  selector: 'app-room-form',
  templateUrl: './room-form.component.html',
  styleUrls: ['./room-form.component.scss']
})
export class RoomFormComponent implements OnInit {

  form: FormGroup;
  room: Room = new Room();
  loading = false;
  errorMessage: string;
  hotelInfo: Unit;
  id: number = undefined;

  cleaningQR: CleaningQR = {
    type: 'room',
    hotelCode: null,
  };

  codeQRencoded = '';
  codeSRC = '';

  constructor(
    private formBuilder: FormBuilder,
    public service: RoomsService,
    public router: Router,
    public route: ActivatedRoute,
    public navService: NavService,
    public cleaningService: CleaningService) {
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {

      this.hotelInfo = this.navService.hotelInfo;
      this.cleaningQR.hotelCode = this.hotelInfo.hotelCode;

      if (params.roomId) {
        this.id = params.roomId;
        this.initCreateForm();
        this.initUpdateForm();
      } else {
        this.initCreateForm();
      }
    });
  }

  initCreateForm() {
    this.form = this.formBuilder.group({
      user: [null],
      roomNumber: [null, [Validators.required, Validators.minLength(1)]],
    });
  }

  initUpdateForm() {
    this.loading = true;
    this.service.read(this.id).then(room => {
      this.loading = false;
      this.errorMessage = null;

      this.room = room;

      this.form.patchValue({
        ['user']: room.guest ? `${room.guest.GivenName} ${room.guest.SurName}` : '',
        ['roomNumber']: room.roomNumber,
      });
      this.cleaningQR.roomId = this.id;
      this.calcCode();

    }, _err => {
      this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
    });
  }

  submit() {
    if (this.id) {
      console.log('TCL: RoomsFormComponent -> submit -> this.id', this.id);
      this.update();
    } else {
      console.log('NO ID');
      this.create();
    }
  }

  create() {
    this.loading = true;
    this.room = new Room(this.form.value);
    this.room.unit = new Unit({ hotelCode: this.hotelInfo.hotelCode});
    this.service.create(this.room).then(room => {
      this.successNew(room);
      this.loading = false;
      this.errorMessage = null;
    }, err => {
      console.error('Error in create room component', err);
      this.loading = false;
      if (err.statusCode === 400) {
        this.errorMessage = 'Ops! Verifique os campos abaixo';
        if (err.message && err.message.length > 0) {
          for (const e of err.message) {
            this.form.get(e.property).setErrors({
              error: true
            });
          }
        }
      } else if (err.statusCode === 409) {
        this.errorMessage = 'Este quarto já está registrado nessa unidade. Por favor, escolha outro.';
      } else {
        this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
      }
    });
  }

  update() {
    this.loading = true;
    console.log(this.room);
    const room = new Room({
      roomNumber: this.form.value.roomNumber,
      unit: {hotelCode: this.hotelInfo.hotelCode},
    });
    this.service.update(room, this.id).then(_room => {
      this.successEdit();
      this.loading = false;
      this.errorMessage = null;
    }, err => {
      console.error('Error in update room component', err);
      this.loading = false;
      if (err.statusCode === 400) {
        this.errorMessage = 'Ops! Verifique os campos abaixo';
        if (err.message && err.message.length > 0) {
          for (const e of err.message) {
            this.form.get(e.property).setErrors({
              error: true
            });
          }
        }
      } else if (err.statusCode === 409) {
        this.errorMessage = 'Este quarto já está registrado nessa unidade. Por favor, escolha outro.';
      } else {
        this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
      }
    });
  }



  calcCode() {
    console.log('DEBUG: QrCodeCleaningComponent -> calcCode -> this.cleaningQR', this.cleaningQR);
    if (this.cleaningQR.type === 'area') {
      delete this.cleaningQR.roomId;
    } else if (this.cleaningQR.type === 'room') {
      delete this.cleaningQR.areaId;
    }
    console.log('DEBUG: QrCodeCleaningComponent -> calcCode -> this.cleaningQR', this.cleaningQR);
    this.loading = true;
    this.cleaningService.generateQR(this.cleaningQR).then((resp) => {
      this.codeQRencoded = resp.QR;
      setTimeout(() => {
        this.codeSRC = document.querySelector('#QR img').getAttribute('src');
      }, 100);
      this.loading = false;
    });
  }

  printQR() {
    const img = document.querySelector('#QR img');
    img.setAttribute('style', 'display: initial;');

    const popup = window.open();
    popup.document.write(`<div style='display: table-cell;
                                      vertical-align: middle;
                                      height: 100vh;
                                      text-align: center;
                                      width: 100vw;
                                      color: rgb(241, 128, 65);'>`);
    popup.document.write(img.outerHTML);
    // tslint:disable-next-line: max-line-length
    popup.document.write(`<p><img src="https://i.imgur.com/F0zipA9.png" height="20" style="position: relative;top: 5px;"/> <b>Uliving</b> - `);
    popup.document.write(
      this.cleaningQR.type === 'room' ?
        ('Quarto ' + this.room.roomNumber) :
        ('Área: ' + this.cleaningQR.areaId)
    );
    popup.document.write(`</p></div>`);
    popup.focus();
    setTimeout(() => {
      popup.print();
    }, 500);
  }

  showError(field?: string) {
    // console.log('DEBUG: RoomFormComponent -> showError -> field', field);
    return (this.form.get(field).invalid && this.form.get(field).touched) ||
           (!this.form.get(field).touched && this.errorMessage && this.errorMessage.length > 0 && this.form.get(field).invalid );
  }

  successNew(room: Room) {
    Swal.fire({
      title: 'Quarto ' + this.room.roomNumber + ' cadastrado',
      type: 'success',
      showConfirmButton: true,
      confirmButtonText: 'Ir para quarto',
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary float-left',
      cancelButtonText: 'Cadastrar outro',
      showCloseButton: true,
      onClose: () => this.goToList(),
    }).then(resp => {
      if (resp.value) {
        this.goToDetails(room.id);
      } else {
        this.goToForm();
      }
    });
  }

  successEdit() {
    return Swal.fire({
      title: 'Quarto editado!',
      type: 'success',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary',
      cancelButtonText: 'Voltar',
      showCloseButton: true,
      onClose: () => {},
    });
  }

  clickDelete(id: number) {
    Swal.fire({
      title: 'Deseja mesmo excluir este quarto?',
      showConfirmButton: true,
      confirmButtonText: 'Sim, excluir',
      confirmButtonClass: 'btn btn-danger',
      showCancelButton: true,
      cancelButtonClass: 'btn btn-secondary',
      cancelButtonText: 'Cancelar',
      showCloseButton: true,
    }).then(resp => {
      if (resp.value) {
        this.delete(id);
      }
    });
  }

  delete(id: number) {
    this.service
      .delete(id)
      .then(del => {
        Swal.fire({
          type: 'success',
          text: 'Quarto ' + id + ' deletado com sucesso.',
          titleText: 'Excluído.',
        }).then(resp => {
          this.goToList();
        });
      })
      .catch(err => {
        Swal.fire({
          type: 'error',
          titleText: 'Erro ao excluir.',
          text: 'Por favor, tente novamente.',
        });
      });
  }

  goToList() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'rooms']);
  }
  goToDetails(id: number) {
    this.router.navigate(['/unit', this.hotelInfo.code, 'rooms', id, 'edit']);
  }

  goToForm() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'rooms', 'create']);
  }

}
