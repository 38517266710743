import { ReservationsIds } from './reservationsIds.entity';
import { DocumentType, TravelReason, Transport, GuestInformation, ReservationStatus } from './totvs.interfaces';
import { GuestAddress } from './address.entity';
import { User, UserStatus, DEFAULT_AVATAR } from './user.entity';
import { DeepPartial } from '../rest.entity';
import { Room } from '../rooms/room.entity';
import { GuestRelationInterface } from '../../residents/totvs.service';

export enum Gender {
  FEMALE = 'Female',
  MALE = 'Male',
}

export interface GuestQuantitativeReport {
  all: number;
  preCreated: number;
  confirmed: number;
  confirmedWithChanges: number;
  cancelled: number;
  checkedIn: number;
  checkedOut: number;
  noShow: number;
  app: {
    active: number;
    blocked: number;
    pendingAccess: number;
  };
}

export class Guest {
  constructor(obj?: DeepPartial<Guest>) {
    Object.assign(this, obj);
  }

  id: string | number;

  createdDate?: Date;

  updatedDate?: Date;

  indicationCode: string;

  user?: User;

  LocatorId?: string;

  HotelCode?: number;

  GuestCode?: number;

  Name?: string;

  MainGuest?: boolean;

  HIGSReservationNumber?: string;
  PMSReservationNumber?: string;
  SourceReservationNumber?: string;

  GivenName?: string;

  SurName?: string;

  Email?: string;

  Profession?: string;

  BirthDate?: string;

  Gender?: Gender;

  DocumentType?: DocumentType;

  DocumentNumber?: string;

  GuestAddress?: GuestAddress;

  Nationality?: string;

  PhoneNumber?: string;

  CelPhoneNumber?: string;

  LastSource?: string;

  NextStop?: string;

  TravelReason?: TravelReason;

  Transport?: Transport;

  Checkin?: string;

  Checkout?: string;

  rooms?: Room[];

  RoomNumber?: string;
  ReservationStatus?: ReservationStatus;

  confirmationCode?: string;

  expireDate?: Date;

  organization?: string;

  course?: string;

  static getReservationText(reservationStatus: ReservationStatus) {
    switch (reservationStatus) {
      case ReservationStatus.Cancelled:
        return 'Cancelada';
      case ReservationStatus.CheckedIn:
      case ReservationStatus.CheckInConfirmed:
        return 'Checked In';
      case ReservationStatus.CheckedOut:
      case ReservationStatus.CheckOutConfirmed:
        return 'Checked Out';
      case ReservationStatus.Confirmed:
        return 'Confirmada';
      case ReservationStatus.ConfirmedWithChanges:
        return '*Confirmada';
      case ReservationStatus.NoShow:
        return 'No Show';
      default:
        return '?';
    }
  }

  static getReservationColor(reservationStatus: ReservationStatus) {
    switch (reservationStatus) {
      case ReservationStatus.Cancelled:
        return 'warning';
      case ReservationStatus.CheckedIn:
      case ReservationStatus.CheckInConfirmed:
        return 'success';
      case ReservationStatus.CheckedOut:
      case ReservationStatus.CheckOutConfirmed:
        return 'muted';
      case ReservationStatus.Confirmed:
        return 'info';
      case ReservationStatus.ConfirmedWithChanges:
        return 'info';
      case ReservationStatus.NoShow:
        return 'danger';
      default:
        return '';
    }
  }

  static getUserStatusTotvs(relation: GuestRelationInterface) {
    if (relation.appGuest) {
      const guest = new Guest(relation.appGuest);
      return { text: guest.statusText, color: guest.statusColor };
    } else {
      return { text: 'Não Sincronizado', color: 'warning' };
    }
  }

  setFromGuestInformation(info: GuestInformation) {
    this.Name = info.Guest.Name;
    this.GuestCode = info.Guest.Code;
    this.MainGuest = info.Guest.MainGuest ? info.Guest.MainGuest : true;
    delete info.Guest;
    Object.assign(this, info);
  }

  get fullname() {
    return this.GivenName + ' ' + this.SurName;
  }

  get reservationText() {
    return Guest.getReservationText(this.ReservationStatus);
  }

  get reservationColor() {
    return Guest.getReservationColor(this.ReservationStatus);
  }

  get fullName(): string {
    return this.GivenName + ' ' + this.SurName;
  }

  get statusText() {
    if (!this.user) {
      return 'Acesso Pendente';
    }
    switch (this.user.status) {
      case UserStatus.APPROVED:
        return 'Ativo';
      case UserStatus.BLOCKED:
        return 'Bloqueado';
      case UserStatus.PRECREATED:
        return 'Pré-criado';
    }
  }

  get statusColor() {
    if (!this.user) {
      return 'secondary';
    }
    switch (this.user.status) {
      case UserStatus.APPROVED:
        return 'success';
      case UserStatus.BLOCKED:
        return 'danger';
      case UserStatus.PRECREATED:
        return 'info';
      default:
        return '';
    }
  }

  get avatarUrl() {
    if (this.user && this.user.avatarUrl) {
      return this.user.avatarUrl;
    } else {
      return DEFAULT_AVATAR;
    }
  }

  hasAvatar() {
    return this.user && this.user.avatarUrl;
  }
}
