import { Component, OnInit } from '@angular/core';
import { Evaluation } from '../evaluations/evaluations.entity';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
// tslint:disable-next-line: max-line-length
import * as answers from './../evaluations/answers';
import { Question, QUESTIONS } from '../evaluations/questions';
import { ProfessionalsService } from '../professionals/professionals.service';
import { Professional } from '../professionals/professional.entity';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-new-evaluation',
  templateUrl: './new-evaluation.component.html',
  styleUrls: ['./new-evaluation.component.scss']
})
export class NewEvaluationComponent implements OnInit {

  evaluation: Evaluation = new Evaluation();
  form: FormGroup;
  questions: Question[] = QUESTIONS;
  answers = answers;
  professional: Professional = new Professional();
  loading = false;
  errorMessage: string;
  constructor(
    private formBuilder: FormBuilder,
    public professionalService: ProfessionalsService,
    private route: ActivatedRoute,
    public router: Router
  ) {
    this.loadData();
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      AOS: [null, Validators.required],
      diagnosisVolume: [null, Validators.required],
      titulationPAP: [null, Validators.required],
      prescriptionEquipments: [null, Validators.required],
      prescriptionVolume: [null, Validators.required],
      prescriptionMasks: [null, Validators.required],
      airViewDiagnosis: [null, Validators.required],
      airViewTherapy: [null, Validators.required],
      myAirPrescription: [null, Validators.required],
      reference: null,
      eventsWorkshop: [null, Validators.required],
      eventsLecture: [null, Validators.required],
      eventsReach: [null, Validators.required],
      lecturesRMD: null,
      launchesRMD: null,
      webinarsRMD: null,
      eventsScience: null,
      publications: null,
      publicationsRMD: null,
      relevance: null,
      observation: [null, Validators.required],
    });

    this.listenToChanges();
  }

  listenToChanges() {
    this.form.valueChanges.subscribe(ev => {
      this.evaluation = new Evaluation(ev);
    });
  }

  saveEvaluation() {
    this.loading = true;
    this.professionalService.evaluate(this.professional.id, this.form.value).subscribe(prof => {
      this.showSuccess(prof);
      this.loading = false;
      this.errorMessage = null;
    }, err => {
      console.error("TCL: NewProfessionalComponent -> createProfessional -> err", err)
      this.loading = false;
      if (err.error.statusCode === 400) {
        this.errorMessage = 'Ops! Verifique os campos abaixo';
      } else {
        this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
      }
    });
  }

  showError(field?: string) {
    return (this.form.get(field).invalid && this.form.get(field).touched) ||
           (!this.form.get(field).touched && this.errorMessage && this.errorMessage.length > 0);
  }

  showSuccess(prof: Professional) {
    Swal.fire({
      title: 'Avaliação salva!',
      type: 'success',
    }).then(resp => {
      this.router.navigateByUrl('/professionals/' + this.professional.id);
    });
  }

  async loadData() {
    console.log('loading data');
    const params = await this.route.params.pipe(take(1)).toPromise();
    this.professional.id = params['id'];
    this.professionalService.read(this.professional.id).subscribe(prof => {
      this.professional = new Professional(prof);
      if (this.professional.evaluations && this.professional.evaluations.length > 0) {
        this.evaluation = this.professional.evaluations[0];
        const evaluationGroup = this.evaluation;
        delete evaluationGroup.id;
        delete evaluationGroup.createdDate;
        delete evaluationGroup.updatedDate;
        delete evaluationGroup.reporter;
        delete evaluationGroup.professional;
        this.form.setValue(evaluationGroup);
        this.form.updateValueAndValidity();
      }
    });
  }

}
