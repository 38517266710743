import { RestEntity, DeepPartial } from '../rest.entity';
import { User } from '../residents/user.entity';
import { Unit } from '../units/unit.entity';
import { Guest } from '../residents/guest.entity';

export class Room extends RestEntity<Room> {

  constructor(obj?: DeepPartial<Room>) {
    super(obj);
    if (obj && obj.guest) {
      this.guest = new Guest(obj.guest);
    }
  }
  id?: number;

  guest?: Guest;

  user?: User;

  unit?: Unit;

  roomNumber?: string;

  cleanings?: object[];
}
