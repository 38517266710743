import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingFormComponent } from './billing-form/billing-form.component';
import { BillingService } from './billing.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from '../app-routing.module';
import { NgbAlertModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ListBillingComponent } from './list-billing/list-billing.component';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  declarations: [BillingFormComponent, ListBillingComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AppRoutingModule,
    NgbAlertModule,
    NgbModalModule,
    QRCodeModule,
  ],
  providers: [ BillingService ]
})
export class BillingModule {}
