import { RestEntity, DeepPartial } from '../rest.entity';
import { Guest } from './guest.entity';

export class GuestBilling extends RestEntity<GuestBilling> {

  constructor(obj?: DeepPartial<GuestBilling>) {
    super(obj);
    if (obj && obj.Guest) {
      this.Guest = new Guest(obj.Guest);
    }
  }

  LocatorId: string;
  HotelCode: number;
  Guest: Guest;
  Description: string;
  Date: string | Date;
  Value: number;
  HIGSReservationNumber: string;
  PMSReservationNumber: string;
  SourceReservationNumber: string;
  RoomNumber: string;
  BillingHidden: boolean;
  totvsId: string;
  Reversal: boolean;

}
