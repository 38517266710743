import { Injectable } from '@angular/core';
import { Unit } from '../entities/units/unit.entity';
import { User } from '../entities/residents/user.entity';
import { APIService, APIPaginationdResponse } from '../api.service';
import { RestEntity, DeepPartial } from '../entities/rest.entity';
import { Employee } from '../entities/employees/employee.entity';
import { distanceInWords, distanceInWordsStrict, differenceInHours } from 'date-fns';
import * as localeBR from 'date-fns/locale/pt';
import { Room } from '../entities/rooms/room.entity';
import { OccurrenceCategory } from '../occurrence-categories/occurrence-categories.entity';
export const DEFAULT_AVATAR = '../../assets/images/avtar/user-avatar.png';

export enum AreaType {
  APARTMENT = 'APARTMENT',
  COMMON = 'COMMON',
}

export enum OccurrenceType {
  OCCURRENCE = 'OCCURRENCE',
}

export enum OccurrenceStatus {
  REQUESTED = 'REQUESTED',
  RECEIVED = 'RECEIVED',
  PROGRESS = 'PROGRESS',
  FINISHED = 'FINISHED',
  APPROVED = 'APPROVED',
  REFUSED = 'REFUSED',
  CANCELED = 'CANCELED',
}

export enum OccurrenceSort {
  NEWER = 'NEWER',
  OLDER = 'OLDER',
  STATUS = 'STATUS',
  PLACE = 'PLACE',
  STATUSINVERSE = 'STATUSINVERSE',
  PLACEINVERSE = 'PLACEINVERSE',
}

export interface Area {
  id?: number;
  name?: string;
  unit?: Unit;
  //cleanings?: Cleaning[];
}


export function occurrenceTypeTranslator(type: OccurrenceType) {
  return {
    OCCURRENCE: 'Regulamento Interno',
  }[type];
}

export function handleErrors(error) {
  switch(error) {
    case "ROOM_REQUIRED_FOR_APARTMENT_TYPE":
      return "É necessário informar o quarto desejado para esse tipo de manutenção!";
    case "AREA_REQUIRED_FOR_COMMON_TYPE":
      return "É necessário informar a área desejada para esse tipo de manutenção!";
    case "TYPE_NOT_FOUND":
      return "Tipo de manutenção não encontrado, tente novamente!";
    case "USER_NOT_FOUND":
      return "Não foi possível encontrar o usuário, recarregue a página e tente novamente!"
    case "UNIT_NOT_FOUND":
      return "Não foi possível encontrar a unidade, recarregue a página e tente novamente!"
    case "OCCURRENCE_NOT_FOUND":
      return "Não foi possível encontrar a manutenção desejada, tente novamente!"
    case "EMPLOYEE_NOT_INFORMED":
      return "É necessário informar o funcionário para a manutenção."
    case "EMPLOYEE_NOT_FOUND":
      return "Não foi possível encontrar o funcionário, tente novamente!"
    case "PROGRESS_NOT_REASSIGNABLE":
      return "O serviço já foi iniciado pelo funcionário, não será possível reatribuir!"
    case "OCCURRENCE_ALREADY_FINISHED":
      return "O serviço já foi finalizado."
    case "OCCURRENCE_ALREADY_CANCELED":
      return "O serviço já foi cancelado."
    case "OCCURRENCE_NOT_OWNED":
      return "Somente o próprio usuário que abriu a manutenção deverá fechar ou cancelar."
    default:
      return "Ocorreu um problema ao executar essa ação. Por favor, tente novamente ou contate a equipe de desenvolvimento."
  }
}

export class Occurrence extends RestEntity<Occurrence> {
  id?: number;
  roomNumber?: string;
  areaType?: AreaType;
  area?: Area;
  type?: OccurrenceType;
  status?: OccurrenceStatus;
  photoUrl?: string;
  comments?: string;
  user?: User;
  employee?: Employee;
  subcategory?: OccurrenceCategory;
  adminComment?: string;
  employeeComment?: string;
  serviceNumber?: string;
  unit?: Unit;
  finishedDate?: Date;
  approvedDate?: Date;
  startedDate?: Date;
  room?: Room;

  constructor(obj?: DeepPartial<Occurrence>) {
    super(obj);

    if (obj && obj.user) {
      this.user = new User(obj.user);
    }

    if (obj && obj.employee) {
      this.employee = new Employee(obj.employee);
    }

    if (obj && obj.subcategory) {
      this.subcategory = new OccurrenceCategory(obj.subcategory);
    }

    if (obj && obj.unit) {
      this.unit = new Unit(obj.unit);
    }
  }

  get statusBadge(): { text: string; class: string } {
    if (this.status === OccurrenceStatus.REQUESTED) {
      return { text: 'Aberta', class: 'badge-warning' };
    } else if (this.status === OccurrenceStatus.RECEIVED) {
      return { text: 'Recebida', class: 'badge-warning' };
    } else if (this.status === OccurrenceStatus.PROGRESS) {
      return { text: 'Em andamento', class: 'badge-primary' };
    } else if (this.status === OccurrenceStatus.FINISHED) {
      return { text: 'Finalizada', class: 'badge-info' };
    } else if (this.status === OccurrenceStatus.CANCELED) {
      return { text: 'Cancelada', class: 'badge-light' };
    } else if (this.status === OccurrenceStatus.APPROVED) {
      return { text: 'Aprovada', class: 'badge-success' };
    } else if (this.status === OccurrenceStatus.REFUSED) {
      return { text: 'Recusada', class: 'badge-danger' };
    } else {
      return { text: '?', class: 'badge-dark' };
    }
  }

  get typeTranslation(): string {
    return occurrenceTypeTranslator(this.type);
  }

  get sla() {
    switch (this.status) {
      case OccurrenceStatus.RECEIVED:
      case OccurrenceStatus.REQUESTED:
      case OccurrenceStatus.REFUSED:
      case OccurrenceStatus.PROGRESS:
        return distanceInWordsStrict(new Date(), new Date(this.createdDate), { locale: localeBR });

      case OccurrenceStatus.FINISHED:
      case OccurrenceStatus.APPROVED:
        if (this.finishedDate) {
          return distanceInWordsStrict(new Date(this.finishedDate), new Date(this.createdDate), { locale: localeBR });
        } else if (this.updatedDate) {
          return distanceInWordsStrict(new Date(this.updatedDate), new Date(this.createdDate), { locale: localeBR });
        }
        return '-';
      case OccurrenceStatus.CANCELED:
        return '-';

      default:
        return '-';
    }
  }

  get slaHours() {
    switch (this.status) {
      case OccurrenceStatus.RECEIVED:
      case OccurrenceStatus.REQUESTED:
      case OccurrenceStatus.REFUSED:
      case OccurrenceStatus.PROGRESS:
        return differenceInHours(new Date(), new Date(this.createdDate));

      case OccurrenceStatus.FINISHED:
      case OccurrenceStatus.APPROVED:
        if (this.finishedDate) {
          return differenceInHours(new Date(this.finishedDate), new Date(this.createdDate));
        } else if (this.updatedDate) {
          return differenceInHours(new Date(this.updatedDate), new Date(this.createdDate));
        }
        return undefined;
      case OccurrenceStatus.CANCELED:
        return undefined;

      default:
        return undefined;
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class OccurrenceService {
  constructor(private API: APIService) {}

  async loadHotelOccurrences(hotelCode: number, page: number = 1, limit: number = 25, filter: any = {}, hideLoading = false) {
    return await this.API.get<APIPaginationdResponse<Occurrence>>(
      `/occurrences?hotelCode=${hotelCode}&page=${page}&limit=${limit}`,
      { ...filter },
      { tokenRequired: true, hideLoading },
    );
  }

  async read(id: number) {
    return await this.API.get<Occurrence>(`/occurrences/${id}`, {}, { tokenRequired: true });
  }

  create(occurrence: Occurrence, file?: any, hideLoading = false) {
    console.log('DEBUG: create -> occurrences', occurrence);

    const formData = new FormData();

    formData.append('areaType', occurrence.areaType);
    formData.append('comments', occurrence.comments);
    formData.append('type', occurrence.type);
    formData.append('unit', String(occurrence.unit.hotelCode));

    if (occurrence.area) {
      formData.append('area', String(occurrence.area.id));
    }

    if (occurrence.employee) {
      formData.append('employee', String(occurrence.employee.id));
    }

    if (occurrence.roomNumber) {
      formData.append('roomNumber', String(occurrence.roomNumber));
    }

    if (occurrence.room) {
      formData.append('room', String(occurrence.room.id));
    }

    if (file) {
      formData.append('file', file);
    }

    if (occurrence.subcategory) {
      formData.append('subcategory', occurrence.subcategory.id as any);
    }

    if (occurrence.createdDate) {
      formData.append('createdDate', occurrence.createdDate as any);
    }

    if (occurrence.finishedDate) {
      formData.append('finishedDate', occurrence.finishedDate as any);
    }

    if (occurrence.approvedDate) {
      formData.append('approvedDate', occurrence.approvedDate as any);
    }

    if (occurrence.status) {
      formData.append('status', occurrence.status as any);
    }

    return this.API.post<Occurrence | FormData, Occurrence>('/occurrences', formData, { tokenRequired: true, hideLoading });
  }

  async update(id: number, occurrence: Occurrence, hideLoading = false) {
    return await this.API.patch<Occurrence>(`/occurrences/` + id, occurrence, { tokenRequired: true, hideLoading });
  }

  delete(id: number) {
    return this.API.delete<Occurrence>(`/occurrences/${id}`, {}, { tokenRequired: true });
  }

  async assignEmployee(serviceId: number, id: number) {
    return await this.API.patch(`/occurrences/${serviceId}/assign`, { employeeId: id }, { tokenRequired: true });
  }

  async updateAdmin(serviceId: number, bodyData?: { replyComment?: string; status?: OccurrenceStatus; commentRejoinder?: string }) {
    return await this.API.patch(`/occurrences/${serviceId}/update`, bodyData, { tokenRequired: true });
  }

  async uploadPhoto(serviceId: number, file) {
    const formData = new FormData();

    formData.append('file', file);

    return await this.API.patch(`/occurrences/${serviceId}/update`, formData, { tokenRequired: true });
  }
}
