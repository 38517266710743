
import { User } from "../residents/user.entity";
import { RestEntity } from "../rest.entity";
import { CleaningType } from "./cleaning-type.entity";
import { Cleaning } from "./cleaning.entity";

export enum CleaningPackStatus {
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED',
}

export function translatePackStatus(status) {
  switch(status) {
    case 'CONFIRMED':
      return 'Confirmado';
    case 'CANCELLED':
      return 'Cancelado';
  }
}

export class CleaningPack extends RestEntity<CleaningPack> {
  cleanings?: Cleaning[];

  cleaningType?: CleaningType;

  status: CleaningPackStatus;

  expireDate?: Date;

  usedCredits?: number;

  remainingCredits?: number;

  user: User;
}
