import { Routes, RouterModule } from '@angular/router';
import { ProfessionalsComponent } from '../../professionals/professionals.component';
import { ProfessionalComponent } from '../../professional/professional.component';
import { NewEvaluationComponent } from '../../new-evaluation/new-evaluation.component';
import { NewProfessionalComponent } from '../../new-professional/new-professional.component';
import { ListResidentsComponent } from '../../residents/list-residents/list-residents.component';
import { ListEmployeesComponent } from '../../employees/list-employees/list-employees.component';
import { EmployeeFormComponent } from '../../employees/employee-form/employee-form.component';
import { ListSpacesComponent } from '../../unit/list-spaces/list-spaces.component';
import { SpacesFormComponent } from '../../unit/spaces-form/spaces-form.component';
import { DetailsEmployeeComponent } from '../../employees/details-employee/details-employee.component';
import { ResidentsFormComponent } from '../../../app/residents/residents-form/residents-form.component';
import { NotifyResidentsFormComponent } from '../../../app/residents/notify-residents-form/notify-residents-form.component';
import { DetailsResidentComponent } from '../../residents/details-resident/details-resident.component';
import { SpaceScheduleComponent } from '../../unit/space-schedule/space-schedule.component';
import { ScheduleFormComponent } from '../../unit/schedule-form/schedule-form.component';
import { ListEventsComponent } from '../../events/list-events/list-events.component';
import { EventFormComponent } from '../../events/event-form/event-form.component';
import { QrCodeCleaningComponent } from '../../cleaning/qr-code-cleaning/qr-code-cleaning.component';
import { RoomFormComponent } from '../../rooms/room-form/room-form.component';
import { ListSuggestionsComponent } from '../../../app/suggestions/list-suggestions/list-suggestions.component';
import { DetailsSuggestionsComponent } from '../../../app/suggestions/details-suggestions/details-suggestions.component';
import { ListCleaningTypeComponent } from '../../cleaning/list-cleaning-type/list-cleaning-type.component';
import { DetailsCleaningTypeComponent } from '../../../app/cleaning/details-cleaning-type/details-cleaning-type.component';
import { CleaningTypeFormComponent } from '../../../app/cleaning/cleaning-type-form/cleaning-type-form.component';
import { ListRoomsComponent } from '../../../app/rooms/list-rooms/list-rooms.component';
import { ListAreasComponent } from '../../../app/areas/list-areas/list-areas.component';
import { AreaFormComponent } from '../../../app/areas/area-form/area-form.component';
import { CalendarPageComponent } from '../../../app/cleaning/calendar/calendar.component';
import { ListMaintenancesComponent } from '../../../app/maintenances/list-maintenances/list-maintenances.component';
import { MaintenanceDetailComponent } from '../../../app/maintenances/maintenance-detail/maintenance-detail.component';
import { VouchersListComponent } from '../../../app/orange-club/vouchers-list.component';
import { VoucherFormComponent } from '../../../app/orange-club/vouchers-form/vouchers-form.component';
import { ListExtraServiceComponent } from '../../../app/cleaning/list-extra-service/list-extra-service.component';
import { ExtraServiceFormComponent } from '../../../app/cleaning/extra-service-form/extra-service-form.component';
import { CleaningFormComponent } from '../../../app/cleaning/cleaning-form/cleaning-form.component';
import { CanDeactivateGuard } from '../guard/can-deactivate.guard';
import { CalendarFormComponent } from '../../../app/cleaning/calendar-form/calendar-form.component';
import { SyncResidentsComponent } from '../../residents/sync-residents/sync-residents.component';

import { EventCalendarComponent } from '../../events/calendar/calendar.component';
import { VisitorFormComponent } from '../../visitor/visitor-form/visitor-form.component';
import { ListVisitsComponent } from '../../visitor/visit-list/list-visit.component';
import { ListVisitorsComponent } from 'src/app/visitor/visitor-list/list-visitor.component';
import { DetailsVisitComponent } from 'src/app/visitor/details-visit/details-visit.component';
import { DetailsVisitorComponent } from 'src/app/visitor/details-visitor/details-visitor.component';
import { RoleGuard } from '../guard/RoleGuard.guard';
import { BillingFormComponent } from '../../billing/billing-form/billing-form.component';
import { ListBillingComponent } from '../../billing/list-billing/list-billing.component';
import { MaintenanceFormComponent } from '../../maintenances/maitenance-form/maitenance-form.component';
import { MaintenancesReportsComponent } from '../../maintenances/maintenances-reports/maintenances-reports.component';
import { VisitFormComponent } from '../../visitor/visit-form/visit-form.component';
import { ListOccurrencesComponent } from 'src/app/occorrences/list-occurrences/list-occurrences.component';
import { OccurrenceFormComponent } from 'src/app/occorrences/occurrence-form/occurrence-form.component';
import { OccurrenceDetailComponent } from 'src/app/occorrences/occurrence-detail/occurrence-detail.component';
import { ListStatementsComponent } from 'src/app/statement/list-statement/list-statement.component';
import { DetailsStatementComponent } from 'src/app/statement/details-statement/details-statement.component';
import { StatementFormComponent } from 'src/app/statement/statement-form/statement-form.component';

export const content: Routes = [
  {
    path: 'feedback/:type',
    canActivateChild: [RoleGuard],
    data: { path: 'feedback' },
    children: [
      {
        path: '',
        component: ListSuggestionsComponent,
        data: { action: 'list' },
      },
      {
        path: ':id',
        component: DetailsSuggestionsComponent,
        data: { action: 'read' },
      },
    ],
  },
  {
    path: 'residents',
    canActivateChild: [RoleGuard],
    data: { path: 'residents' },
    children: [
      {
        path: '',
        component: ListResidentsComponent,
        data: { action: 'list' },
      },
      {
        path: 'sync',
        component: SyncResidentsComponent,
        data: { action: 'update' },
      },
      {
        path: 'create',
        component: ResidentsFormComponent,
        data: { action: 'create' },
      },
      {
        path: 'notify',
        component: NotifyResidentsFormComponent,
        data: { action: 'create' },
      },
      {
        path: ':residentId/edit',
        component: ResidentsFormComponent,
        data: { action: 'update' },
      },
      {
        path: ':residentId',
        component: DetailsResidentComponent,
        data: { action: 'read' },
      },
    ],
  },
  {
    path: 'statements',
    canActivateChild: [RoleGuard],
    data: { path: 'statements' },
    children: [
      {
        path: '',
        component: ListStatementsComponent,
        data: { action: 'list' },
      },
      {
        path: 'create',
        component: StatementFormComponent,
        data: { action: 'create' },
      },
      {
        path: ':statementId',
        component: DetailsStatementComponent,
        data: { action: 'read' },
      },
      {
        path: ':statementId/edit',
        component: StatementFormComponent,
        data: { action: 'update' },
      },
    ],
  },
  {
    path: 'spaces',
    canActivateChild: [RoleGuard],
    data: { path: 'spaces' },
    children: [
      {
        path: '',
        component: ListSpacesComponent,
        data: { action: 'list' },
      },
      {
        path: 'create',
        component: SpacesFormComponent,
        data: { action: 'create' },
      },
      {
        path: ':spaceId/edit',
        component: SpacesFormComponent,
        data: { action: 'update' },
      },
    ],
  },
  {
    path: 'rooms',
    canActivateChild: [RoleGuard],
    data: { path: 'rooms' },
    children: [
      {
        path: '',
        component: ListRoomsComponent,
        data: { action: 'list' },
      },
      {
        path: 'create',
        component: RoomFormComponent,
        data: { action: 'create' },
      },
      {
        path: ':roomId/edit',
        component: RoomFormComponent,
        data: { action: 'update' },
      },
    ],
  },
  {
    path: 'areas',
    canActivateChild: [RoleGuard],
    data: { path: 'areas' },
    children: [
      {
        path: '',
        component: ListAreasComponent,
        data: { action: 'list' },
      },
      {
        path: 'create',
        component: AreaFormComponent,
        data: { action: 'create' },
      },
      {
        path: ':areaId/edit',
        component: AreaFormComponent,
        data: { action: 'update' },
      },
    ],
  },
  {
    path: 'schedule',
    canActivateChild: [RoleGuard],
    data: { path: 'schedule' },
    children: [
      {
        path: '',
        component: SpaceScheduleComponent,
        data: { action: 'list' },
      },
      {
        path: 'create',
        component: ScheduleFormComponent,
        data: { action: 'create' },
      },
      {
        path: ':scheduleId/edit',
        component: ScheduleFormComponent,
        data: { action: 'update' },
      },
    ],
  },
  {
    path: 'cleaning',
    canActivateChild: [RoleGuard],
    data: { path: 'cleaning' },
    children: [
      {
        path: 'calendar',
        component: CalendarPageComponent,
        canDeactivate: [CanDeactivateGuard],
        data: { action: 'list' },
      },
      {
        path: 'calendar/create',
        component: CalendarFormComponent,
        // canDeactivate: [CanDeactivateGuard]
        data: { action: 'create' },
      },
      {
        path: 'qr-codes',
        component: QrCodeCleaningComponent,
        data: { action: 'read' },
      },
      {
        path: 'create',
        component: ScheduleFormComponent,
        data: { action: 'create' },
      },
      {
        path: ':scheduleId/edit',
        component: ScheduleFormComponent,
        data: { action: 'update' },
      },
      {
        path: 'cleaning-types',
        component: ListCleaningTypeComponent,
        data: { action: 'list' },
      },
      {
        path: 'cleaning-types/create',
        component: CleaningTypeFormComponent,
        data: { action: 'create' },
      },
      {
        path: 'cleaning-types/:id',
        component: DetailsCleaningTypeComponent,
        data: { action: 'read' },
      },
      {
        path: 'cleaning-types/:id/edit',
        component: CleaningTypeFormComponent,
        data: { action: 'typeUpdate' },
      },
      {
        path: 'extra-services',
        component: ListExtraServiceComponent,
        data: { action: 'list' },
      },
      {
        path: 'extra-services/create',
        component: ExtraServiceFormComponent,
        data: { action: 'create' },
      },
      {
        path: 'extra-services/:id',
        component: ExtraServiceFormComponent,
        data: { action: 'typeUpdate' },
      },
      {
        path: 'cleaning-form/create',
        component: CleaningFormComponent,
        data: { action: 'create' },
      },
      {
        path: 'cleaning-form/:id',
        component: CleaningFormComponent,
        data: { action: 'update' },
      },
    ],
  },
  {
    path: 'employees/All',
    canActivateChild: [RoleGuard],
    data: { path: 'employees' },
    children: [
      {
        path: '',
        component: ListEmployeesComponent,
        data: { action: 'list' },
      },
      {
        path: 'create',
        component: EmployeeFormComponent,
        data: { action: 'create' },
      },
      {
        path: ':employeeId/edit',
        component: EmployeeFormComponent,
        data: { action: 'update' },
      },
    ],
  },
  {
    path: 'employees/:role',
    canActivateChild: [RoleGuard],
    data: { path: 'employees' },
    children: [
      {
        path: '',
        component: ListEmployeesComponent,
        data: { action: 'list' },
      },
      {
        path: 'create',
        component: EmployeeFormComponent,
        data: { action: 'create' },
      },
      {
        path: ':employeeId/edit',
        component: EmployeeFormComponent,
        data: { action: 'update' },
      },
      {
        path: ':employeeId',
        component: DetailsEmployeeComponent,
        data: { action: 'read' },
      },
    ],
  },
  {
    path: 'events',
    canActivateChild: [RoleGuard],
    data: { path: 'events' },
    children: [
      {
        path: 'all',
        component: ListEventsComponent,
        data: { action: 'list' },
      },
      {
        path: 'all/create',
        component: EventFormComponent,
        data: { action: 'create' },
      },
      {
        path: 'all/:id/edit',
        component: EventFormComponent,
        data: { action: 'update' },
      },
      {
        path: 'calendar',
        component: EventCalendarComponent,
        data: { action: 'list' },
      },
      {
        path: ':id/edit',
        component: EventFormComponent,
        data: { action: 'update' },
      },
    ],
  },
  {
    path: 'maintenance',
    canActivateChild: [RoleGuard],
    data: { path: 'maintenance' },
    children: [
      {
        path: 'request',
        component: ListMaintenancesComponent,
        data: { action: 'list' },
      },
      {
        path: 'request/create',
        component: MaintenanceFormComponent,
        data: { action: 'create' },
      },
      {
        path: 'request/:id',
        component: MaintenanceDetailComponent,
        data: { action: 'read' },
      },
      {
        path: 'reports',
        component: MaintenancesReportsComponent,
        data: { action: 'reports' },
      },
    ],
  },
  {
    path: 'occurrence',
    canActivateChild: [RoleGuard],
    data: { path: 'occurrence' },
    children: [
      {
        path: 'request',
        component: ListOccurrencesComponent,
        data: { action: 'list' },
      },
      {
        path: 'request/create',
        component: OccurrenceFormComponent,
        data: { action: 'create' },
      },
      {
        path: 'request/:id',
        component: OccurrenceDetailComponent,
        data: { action: 'read' },
      },
    ],
  },
  {
    path: 'vouchers',
    canActivateChild: [RoleGuard],
    data: { path: 'vouchers' },
    children: [
      {
        path: '',
        component: VouchersListComponent,
        data: { action: 'list' },
      },
      {
        path: 'create',
        component: VoucherFormComponent,
        data: { action: 'create' },
      },
      {
        path: ':voucherId/edit',
        component: VoucherFormComponent,
        data: { action: 'update' },
      },
    ],
  },
  {
    path: 'visits',
    canActivateChild: [RoleGuard],
    data: { path: 'visits' },
    children: [
      {
        path: '',
        component: ListVisitsComponent,
        data: { action: 'list' },
      },
      {
        path: 'create',
        component: VisitFormComponent,
      },
      {
        path: 'edit/:id',
        component: VisitFormComponent,
      },
      {
        path: ':id',
        component: DetailsVisitComponent,
        data: { action: 'read' },
      },
    ],
  },
  {
    path: 'visitors',
    canActivateChild: [RoleGuard],
    data: { path: 'visitors' },
    children: [
      {
        path: '',
        component: ListVisitorsComponent,
        data: { action: 'list' },
      },
      {
        path: ':id',
        component: DetailsVisitorComponent,
        data: { action: 'read' },
      },
    ],
  },
  {
    path: 'billing',
    canActivateChild: [RoleGuard],
    data: { path: 'billing' },
    children: [
      {
        path: '',
        component: ListBillingComponent,
        data: { action: 'list' },
      },
      {
        path: 'create',
        component: BillingFormComponent,
        data: { action: 'create' },
      },
      {
        path: ':id/edit',
        component: BillingFormComponent,
        data: { action: 'update' },
      },
    ],
  },
];
