import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AreaFormComponent } from './area-form/area-form.component';
import { AreasService } from './areas.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from '../app-routing.module';
import { NgbAlertModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ListAreasComponent } from './list-areas/list-areas.component';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  declarations: [AreaFormComponent, ListAreasComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AppRoutingModule,
    NgbAlertModule,
    NgbModalModule,
    QRCodeModule,
  ],
  providers: [ AreasService ]
})
export class AreasModule { }
