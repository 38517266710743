import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Unit } from '../../entities/units/unit.entity';
import { Router, ActivatedRoute } from '@angular/router';
import { NavService } from '../../shared/service/nav.service';
import Swal from 'sweetalert2';
import { showCustomErrorAlert } from '../../api.service';
import { Room } from '../../entities/rooms/room.entity';
import { RoomsService } from '../../rooms/rooms.service';
import { AreasService } from '../../areas/areas.service';
import { Area } from '../../entities/units/area.entity';
import { Employee } from "../../entities/employees/employee.entity";
import { UserRole } from "../../entities/residents/user.entity";
import { EmployeesService } from "../../employees/employees.service";
import { AreaType, handleErrors, Occurrence, OccurrenceService, OccurrenceStatus, OccurrenceType, occurrenceTypeTranslator } from '../occurrence.service';
import { OccurrenceCategory } from 'src/app/occurrence-categories/occurrence-categories.entity';
import { OccurrenceCategoryService } from 'src/app/occurrence-categories/occurrence-categories.service';

@Component({
  selector: 'app-occurrence-form',
  templateUrl: './occurrence-form.component.html',
  styleUrls: ['./occurrence-form.component.scss']
})
export class OccurrenceFormComponent implements OnInit {

  form: FormGroup;
  occurrence: Occurrence = new Occurrence();
  loading = false;
  errorMessage: string;
  id: number = undefined;
  hotelInfo: Unit;

  types = Object.keys(OccurrenceType).map(k => ({ name: occurrenceTypeTranslator(k as OccurrenceType), value: k }));

  subcategories: OccurrenceCategory[] = undefined;
  subcategoriesFromType: OccurrenceCategory[] = [];
  rooms: Room[] = undefined;
  areas: Area[] = undefined;
  employees: Employee[];
  chosenEmployee: Employee | undefined;

  OccurrenceArea = AreaType;
  OccurrenceType = OccurrenceType;
  OccurrenceStatus = OccurrenceStatus;

  photoUrl: string | ArrayBuffer;
  file: any;

  constructor(
    private formBuilder: FormBuilder,
    public service: OccurrenceService,
    public router: Router,
    public route: ActivatedRoute,
    public navService: NavService,
    public roomsSrvc: RoomsService,
    public areasSrvc: AreasService,
    public occurrenceCategorySrvc: OccurrenceCategoryService,
    public employeeSrvc: EmployeesService) {
      this.employees = [];
    }

  ngOnInit() {
  this.route.params.subscribe((params) => {
      this.hotelInfo = this.navService.hotelInfo;
      this.initCreateForm();
    });
  }

  async loadEmployees() {
    const employees = await this.employeeSrvc.search(this.hotelInfo.hotelCode, '', UserRole.Maintenance)
      .catch(error => {
        const errorMessage = handleErrors(error.error);
        showCustomErrorAlert({ text: errorMessage });
        return { data: [] };
      });
    console.log('TCL: OccurrenceDetailComponent -> loadEmployees -> employees', employees);
    this.employees = employees.data;
  }

  async loadOccurrenceCategories() {
    this.subcategories = await this.occurrenceCategorySrvc.getAll()
    .catch(error => {
      console.error('ERROR: OccurrenceFormPage -> loadOccurrenceCategories -> error', error);
      const errorMessage = handleErrors(error.error);
      showCustomErrorAlert( { title: "Problema ao carregar subcategorias.", text: errorMessage });
      return [];
    });
  }

  async loadRooms() {
    this.rooms = await this.roomsSrvc.getAllFromUnit(this.hotelInfo.hotelCode)
    .catch(error => {
      console.error('ERROR: OccurrenceFormPage -> loadRooms -> error', error);
      const errorMessage = handleErrors(error.error);
      showCustomErrorAlert( { title: "Problema ao carregar quartos.", text: errorMessage });
      return [];
    });
  }

  async loadAreas() {
    this.areas = await this.areasSrvc.getAllFromUnit(this.hotelInfo.hotelCode)
    .catch(error => {
      console.error('ERROR: OccurrenceFormPage -> loadRooms -> error', error);
      const errorMessage = handleErrors(error.error);
      showCustomErrorAlert( { title:"Problema ao carregar as areas.", text: errorMessage } );
      return [];
    });

    console.log('areas -> ', this.areas);
  }

  initCreateForm() {
    this.loadAreas();
    this.loadRooms();
    this.loadOccurrenceCategories();
    this.loadEmployees();
    this.form = this.formBuilder.group({
      areaType: [null, [Validators.required]],
      area: [null, []],
      roomId: [null, []],
      type: [null, [Validators.required]],
      subcategory: [null, [Validators.required]],
      comments: [null, [Validators.required]],
      file: [null, []],
      createdDate: [null],
      approvedDate: [null],
      finishedDate: [null],
      responsibleEmployee: [null],
      status: [null],
    });
  }

  updateSubcategories() {
    this.subcategoriesFromType = this.subcategories ? this.subcategories.filter(s => s.type === this.form.value.type) : [];
  }

  submit() {
    this.create();
  }

  create() {
    this.loading = true;
    console.log('DEBUG: OccurrenceFormComponent -> create -> this.form.value', this.form.value);
    this.occurrence = new Occurrence({
      ...this.form.value,
    });
    this.occurrence.subcategory = new OccurrenceCategory({ id: Number(this.form.value.subcategory) });
    this.occurrence.unit = new Unit({ hotelCode: this.hotelInfo.hotelCode});
    if (this.form.value.area) {
      const areaId = Number(this.form.value.area);

      this.occurrence.area = new Area({ id: areaId });
    } else {
      this.occurrence.room = new Room({ id: Number(this.form.value.roomId) });
    }

    if (this.form.value.responsibleEmployee) {
      this.occurrence.employee = new Employee(this.form.value.responsibleEmployee);
    }

    this.service.create(this.occurrence, this.file).then(occurrence => {
      this.successNew(occurrence);
      this.loading = false;
      this.errorMessage = null;
    }, error => {
      console.error('Error in create occurrence component ', error);
      this.loading = false;
      const errorMessage = handleErrors(error.error);
      this.errorMessage = errorMessage;

      if (error.message && error.message.length > 0) {
        for (const e of error.message) {
          this.form.get(e.property).setErrors({
            error: true
          });
        }
      }
    });
  }

  showError(field?: string) {
    if (this.form.get(field)) {
      return (this.form.get(field).invalid && this.form.get(field).touched) ||
        (!this.form.get(field).touched && this.errorMessage && this.errorMessage.length > 0 && this.form.get(field).invalid );
    }
  }

  successNew(occurrence: Occurrence) {
    Swal.fire({
      title: 'Ordem de serviço ' + occurrence.serviceNumber + ' cadastrada',
      type: 'success',
      showConfirmButton: true,
      confirmButtonText: 'Ir para ordem de serviço',
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary float-left',
      cancelButtonText: 'Cadastrar outra',
      showCloseButton: true,
      onClose: () => this.goToList(),
    }).then(resp => {
      if (resp.value) {
        this.goToDetails(occurrence.id);
      } else {
        this.goToForm();
      }
    });
  }

  successEdit() {
    return Swal.fire({
      title: 'Ordem de serviço editada',
      type: 'success',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary',
      cancelButtonText: 'Voltar',
      showCloseButton: true,
      onClose: () => {
        this.goToList();
      },
    });
  }

  clickDelete(id: number) {
    Swal.fire({
      title: 'Deseja mesmo excluir esta ordem de serviço?',
      text: 'As ordens de serviço associadas a ela perderão essa informação',
      showConfirmButton: true,
      confirmButtonText: 'Sim, excluir',
      confirmButtonClass: 'btn btn-danger',
      showCancelButton: true,
      cancelButtonClass: 'btn btn-secondary',
      cancelButtonText: 'Cancelar',
      showCloseButton: true,
    }).then(resp => {
      if (resp.value) {
        this.delete(id);
      }
    });
  }

  delete(id: number) {
    this.service
      .delete(id)
      .then(del => {
        Swal.fire({
          type: 'success',
          text: 'Ordem de serviço' + id + ' deletada com sucesso.',
          titleText: 'Excluído.',
        }).then(resp => {
          this.goToList();
        });
      })
      .catch(err => {
        Swal.fire({
          type: 'error',
          titleText: 'Erro ao excluir.',
          text: 'Por favor, tente novamente.',
        });
      });
  }

  // FILE UPLOADING
  onFileSelect(event: any) {
    console.log('DEBUG: OccurrenceFormComponent -> onFileSelect -> event', event);
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log('DEBUG: OccurrenceFormComponent -> onFileSelect -> file', file);
      this.file = file;
      // this.uploadForm.get('avatar').setValue(file);
      const mimeType = file.type;
      if (mimeType.match(/image\/*/) === null) {
        alert('Only images (.jpg, .jpeg, .png) are supported.');
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = _event => {
        this.photoUrl = reader.result;
        console.log('TCL: OccurrenceFormComponent -> onFileSelect -> reader.result', reader.result);
        console.log('TCL: OccurrenceFormComponent -> onFileSelect -> this.photoUrl', this.photoUrl);
        // this.bgAvatar = true;
      };
    }
  }

  goToList() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'occurrence', 'request']);
  }
  goToDetails(id: number) {
    this.router.navigate(['/unit', this.hotelInfo.code, 'occurrence', 'request', id]);
  }

  goToForm() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'occurrence', 'request', 'create']);
  }

  compareById(a: Employee, b: Employee) {
    return a && b && a.id === b.id;
  }
}
