import { MaintenanceType } from '../maintenances/maintenance.service';
import { RestEntity } from '../entities/rest.entity';

export enum IssueCategorySortOptions {
  CATEGORY = 'CATEGORY',
  CATEGORYINVERSE = 'CATEGORYINVERSE',
  SUBCAT = 'SUBCAT',
  SUBCATINVERSE = 'SUBCATINVERSE',
}

export class IssueCategory extends RestEntity<IssueCategory> {
  type?: MaintenanceType;
  name?: string;
}
