import { Component, OnInit } from '@angular/core';
import { Guest } from 'src/app/entities/residents/guest.entity';
import { Unit } from 'src/app/entities/units/unit.entity';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NavService } from 'src/app/shared/service/nav.service';
import Swal from 'sweetalert2';
import { DEFAULT_AVATAR, User, UserRole } from 'src/app/entities/residents/user.entity';
import { StatementService } from '../statement.service';
import { Statement, StatementStatus, translateStatementStatus } from 'src/app/entities/statement/statement.entity';
import { translateItemKind, ItemKind, StatementItem } from 'src/app/entities/statement/statement-item.entity';
import { ResidentsService } from 'src/app/residents/residents.service';
import { addDays } from 'date-fns';

@Component({
  selector: 'app-statement-form',
  templateUrl: './statement-form.component.html',
  styleUrls: ['./statement-form.component.scss'],
})
export class StatementFormComponent implements OnInit {
  constructor(
    public statementSrvc: StatementService,
    public route: ActivatedRoute,
    public navService: NavService,
    public router: Router,
    private formBuilder: FormBuilder,
    public guestSrvc: ResidentsService,
  ) {
    this.guests = [];
  }

  form: FormGroup;
  itemForm: FormGroup;
  statement: Statement = new Statement();
  guests: Guest[];
  guestFullName: string = '';
  loading = false;
  translateStatementStatus = translateStatementStatus;
  translateItemKind = translateItemKind;
  errorMessage: string;
  errorCode: number;
  hotelInfo: Unit;
  statementItemKind = ItemKind;
  items: StatementItem[];
  shouldUpdate = false;
  itemIdToUpdate: number | undefined = undefined;
  isNotLoading = false;

  periodValue = '';

  selectedGuestId: number | undefined = undefined;

  async ngOnInit() {
    this.hotelInfo = this.navService.hotelInfo;
    await this.loadGuests();
    this.route.params.subscribe((params) => {
      this.statement.id = params.statementId;
      if (this.statement.id) {
        this.initCreateForm();
        this.getData(Number(this.statement.id));
      } else {
        this.initCreateForm();
      }
    });

    this.isNotLoading = true;
  }

  initCreateForm() {
    this.form = this.formBuilder.group({
      period: [null, Validators.required],
      status: [null, Validators.required],
      observations: [null, []],
      selectGuest: [null, Validators.required],
      items: [null, []],
      item: this.formBuilder.group({
        amount: [1, []],
        value: [0, []],
        kind: [this.statementItemKind.MONTHLY_PAYMENT, []],
      }),
    });

    if (!this.statement.id) {
      this.statement = new Statement({ items: [] });
    }

    // if (this.statement.guest) {
    //   this.form.value.selectGuest = this.statement.guest;
    // }
  }

  initUpdateForm() {
    const correctDate = addDays(this.statement.period, 3).toLocaleDateString('en-US');
    const splittedPeriod = correctDate.split('/');
    const formattedPeriod = `${splittedPeriod[2]}-${splittedPeriod[0].startsWith('1') ? splittedPeriod[0] : '0' + splittedPeriod[0]}`;
    document.getElementById('periodFilter')['value'] = formattedPeriod;
    document.getElementById('status')['selectedIndex'] = this.statement.status;
    this.form.patchValue({
      ['observations']: this.statement.observations,
      ['period']: formattedPeriod,
      ['status']: this.statement.status,
      ['selectGuest']: this.statement.guest.GuestCode,
    });
  }

  async loadGuests() {
    this.hotelInfo = this.navService.hotelInfo;
    const guests = await this.guestSrvc.getAllGuests(this.hotelInfo.hotelCode).catch((error) => {
      // const errorMessage = handleErrors(error.error);
      // showCustomErrorAlert({ text: errorMessage });
      return { data: [] };
    });
    this.guests = guests.data;
  }

  async getData(code: number) {
    this.loading = true;
    const statement = await this.statementSrvc.read(code);
    if (!statement) {
      Swal.fire({
        title: 'Ops, ocorreu um erro',
        text: 'Por favor, tente novamente ou contate a equipe de desenvolvimento',
        showConfirmButton: true,
        confirmButtonText: 'Ok',
        showCloseButton: true,
        type: 'error',
      });
    }

    this.statement = statement;
    this.initUpdateForm();
    this.loading = false;
    this.guestFullName = this.statement.guest ? `${this.statement.guest.Name}${this.statement.guest.SurName ? ' ' + this.statement.guest.SurName : ''}` : '';
  }

  async submit() {
    this.loading = true;
    const statement: Statement = {
      ...this.statement,
      observations: this.form.value['observations'],
      status: this.form.value['status'],
      guest: this.guests.find((guest) => guest.GuestCode === Number(this.selectedGuestId)),
      period: this.statement.period,
    };
    await this.statementSrvc.create(statement).then(
      (statement) => {
        this.statement.id ? this.successEdit() : this.successNew(statement);
        this.loading = false;
        this.errorMessage = null;
      },
      (error) => {
        console.error('Error in create statement component ', error);
        this.loading = false;
        // const errorMessage = handleErrors(error.error);
        // this.errorMessage = errorMessage;

        if (error.message && error.message.length > 0) {
          for (const e of error.message) {
            this.form.get(e.property).setErrors({
              error: true,
            });
          }
        }
      },
    );
  }

  successEdit() {
    return Swal.fire({
      title: 'Extrato editado',
      type: 'success',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary',
      cancelButtonText: 'Voltar',
      showCloseButton: true,
      onClose: () => {
        this.goToList();
      },
    });
  }

  successNew(statement: Statement) {
    Swal.fire({
      title: 'Extrato ' + statement.id + ' cadastrada',
      type: 'success',
      showConfirmButton: true,
      confirmButtonText: 'Ir para extrato',
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary float-left',
      cancelButtonText: 'Cadastrar outro',
      showCloseButton: true,
      onClose: () => this.goToList(),
    }).then((resp) => {
      if (resp.value) {
        this.goToDetails(Number(statement.id));
      } else {
        this.goToList();
      }
    });
  }

  getStatusColor(status: StatementStatus) {
    return status === StatementStatus.PAID ? 'success' : 'warning';
  }

  showError(field?: string) {
    if (!this.form.get(field)) {
      return false;
    }
    return (
      (this.form.get(field).invalid && this.form.get(field).touched) ||
      (!this.form.get(field).touched && this.errorMessage && this.errorMessage.length > 0 && this.errorCode === 400)
    );
  }

  compareById(a: Guest, b: Guest) {
    return a && b && a.id === b.id;
  }

  onKey(event: any) {
    this.periodValue = event.target.value;
    this.statement.period = `${this.periodValue}-01`;
  }

  saveItem() {
    const kind: ItemKind = document.getElementById('kind')['value'] ? document.getElementById('kind')['value'] : 'OTHERS';
    const value = document.getElementById('value')['value'] ? document.getElementById('value')['value'] : '0';
    const amount = document.getElementById('amount')['value'] ? document.getElementById('amount')['value'] : '1';

    if (this.shouldUpdate && this.itemIdToUpdate) {
      const foundItemIndex = this.statement.items.findIndex((item) => item.id === this.itemIdToUpdate);
      this.statement.items[foundItemIndex].itemKind = kind;
      this.statement.items[foundItemIndex].value = value;
      this.statement.items[foundItemIndex].amount = amount;
    } else {
      this.statement.items.push(new StatementItem({ itemKind: kind, value, amount }));
    }
  }

  updateItem(id: number) {
    const foundItemIndex = this.statement.items.findIndex((item) => item.id === id);

    document.getElementById('kind')['value'] = this.statement.items[foundItemIndex].itemKind;
    document.getElementById('value')['value'] = this.statement.items[foundItemIndex].value;
    document.getElementById('amount')['value'] = this.statement.items[foundItemIndex].amount;

    this.shouldUpdate = true;
    this.itemIdToUpdate = id;
  }

  async deleteItem(id: number) {
    await this.statementSrvc.deleteItem(id).then(() => {
      const foundIndexId = this.statement.items.findIndex((item: StatementItem) => item.id === id);
      this.statement.items.splice(foundIndexId, 1);
    });
  }

  cancelItemUpdate() {
    this.shouldUpdate = false;
    this.itemIdToUpdate = undefined;

    document.getElementById('kind')['value'] = 'OTHERS';
    document.getElementById('value')['value'] = '0';
    document.getElementById('amount')['value'] = '1';
  }

  validateSelectedGuest(id: number) {
    return this.statement.guest && this.statement.guest.GuestCode === id ? true : false;
  }

  changeGuest(guestId: number) {
    this.selectedGuestId = guestId;
  }

  goToList() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'statements']);
  }

  goToDetails(id: number) {
    this.router.navigate(['/unit', this.hotelInfo.code, 'statements', id]);
  }

  // goToForm() {
  //   this.router.navigate(['/unit', this.hotelInfo.code, 'statement', 'request', 'create']);
  // }
}
