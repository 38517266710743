import { RestEntity } from '../rest.entity';
import { Cleaning } from './cleaning.entity';
import { Unit } from '../units/unit.entity';

export enum Frequency {
  SINGLE = 'SINGLE',
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'BIWEEKLY',
  MONTHLY = 'MONTHLY',
}
export class ExtraService extends RestEntity<ExtraService> {

  name: string;

  price: number;

  description: string;

  iconUrl?: string;

  unit: Unit;
}
