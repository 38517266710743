import { Injectable } from '@angular/core';
import { User } from '../entities/residents/user.entity';
import { Employee } from '../entities/employees/employee.entity';
import { APIService, APIPaginationdResponse } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class EmployeesService {
  constructor(public API: APIService) {}

  async getAllFromUnit(hotelCode: number): Promise<Employee[]> {
    return (await this.search(hotelCode, '', '')).data;
  }

  async search(hotelCode: number, query: string, role: string, page = 1, limit = 1000000) {
    const response = await this.API.get<APIPaginationdResponse<Employee>>(
      '/employees/search',
      {
        HotelCode: hotelCode,
        q: query,
        role: role,
        page,
        limit,
      },
      { tokenRequired: true },
    );
    return {
      totalPages: response.totalPages,
      data: response.data.map(e => new Employee(e)),
    };
  }

  createAdmin(user: User) {
    return this.API.post<User, User>('/users/create', user, { tokenRequired: true });
  }

  create(employee: Employee) {
    return this.API.post<Employee, Employee>('/employees', employee, { tokenRequired: true });
  }

  read(employeeId: number) {
    return this.API.get<Employee>(`/employees/${employeeId}`, {}, { tokenRequired: true });
  }

  update(employee: Employee, employeeId: number) {
    return this.API.patch<Employee, Employee>(`/employees/${employeeId}`, employee, { tokenRequired: true });
  }

  delete(employeeId: number) {
    return this.API.delete<Employee>(`/employees/${employeeId}`, {}, { tokenRequired: true });
  }

  setAvatar(userId: number, avatar: any) {
    const formData = new FormData();
    formData.append('file', avatar);
    return this.API.uploadFile<User>(`/users/${userId}/setAvatar`, formData);
  }
}
