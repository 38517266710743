import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { CalendarView, CalendarEventAction, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { CalendarEvent } from 'calendar-utils';
import { Subject } from 'rxjs';
import { subDays, startOfDay, addDays, endOfMonth, addHours, isSameMonth, isSameDay, endOfDay } from 'date-fns';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Unit } from '../../entities/units/unit.entity';
import { NavService } from '../../shared/service/nav.service';
import { EventsService } from '../events.service';
import { UserEvent } from '../event.entity';
import { EventCalenderFormComponent } from '../calendar-form/event-form.component';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};
@Component({
  selector: 'app-event-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class EventCalendarComponent implements OnInit {

  loading = true;
  hotelInfo: Unit;

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Day;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent<UserEvent>[] = [];

  activeDayIsOpen = true;

  constructor(
    private modal: NgbModal,
    public navService: NavService,
    public eventsService: EventsService,
  ) {}

  ngOnInit() {
    this.navService.currentUnit$.subscribe((unit) => {
      this.hotelInfo = unit;
      this.loadEvents();
    });
  }

  loadEvents() {
    this.eventsService.getAllFromUnit(this.hotelInfo.hotelCode, '', {limit: 999, page: 1}).then((events) => {
      this.events = events.data.map(s => this.mapToCalendar(s));
      console.log('DEBUG: ngOnInit -> events', events);
    });
  }

  getColorByEvent(event: UserEvent) {
    const colorList = [
      '#056E38',
      '#087C7C',
      '#0B588B',
      '#0F1599',
      '#5E14A8',
      '#B619B6',
      '#C51F74',
      '#D22625',
      '#DC862C',
      '#E2E633',
      '#8FEF3B',
      '#E9C441',
      '#E24D48',
      '#DC4EB4',
      '#9954D6',
      '#5B74D0',
      '#61C6C9'
    ];

    const id = ((event.id * 15) % colorList.length);
    return colorList[id];
  }

  mapToCalendar(event: UserEvent): CalendarEvent<UserEvent> {
    event = new UserEvent(event);
    return {
      start: new Date(event.startDate),
      end: new Date(event.endDate),
      title: event.title,
      meta: event,
      draggable: false,
      resizable: {
        beforeStart: false,
        afterEnd: false,
      },
      color: {
        secondary: this.getColorByEvent(event),
        primary: this.getColorByEvent(event),
      },
    };
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map(iEvent => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    const eventModal = this.modal.open(EventCalenderFormComponent, { size: 'lg', centered: true });
    eventModal.componentInstance.isModal = true;
    eventModal.componentInstance.id = event.meta.id;
    eventModal.result.then((res) => {
      if (res.updated) {
        this.loadEvents();
      }
    });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true
        }
      }
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter(event => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

}
