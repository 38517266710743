import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Unit } from 'src/app/entities/units/unit.entity';
import { FormGroup, FormBuilder } from '@angular/forms';
import { User, UserRole } from 'src/app/entities/residents/user.entity';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth.service';
import { NavService } from 'src/app/shared/service/nav.service';
import Swal from 'sweetalert2';
import { EmployeesService } from 'src/app/employees/employees.service';
import { Employee } from 'src/app/entities/employees/employee.entity';
import { NotificationService } from 'src/app/notification.service';
import { showDefaultErrorAlert, showCustomErrorAlert } from '../../api.service';
import { handleErrors, Occurrence, OccurrenceService, OccurrenceStatus, OccurrenceType, occurrenceTypeTranslator } from '../occurrence.service';
import { OccurrenceCategory } from 'src/app/occurrence-categories/occurrence-categories.entity';
import { OccurrenceCategoryService } from 'src/app/occurrence-categories/occurrence-categories.service';

@Component({
  selector: 'app-occurrence-detail',
  templateUrl: './occurrence-detail.component.html',
  styleUrls: ['./occurrence-detail.component.scss'],
})
export class OccurrenceDetailComponent implements OnInit {
  occurrence: Occurrence = new Occurrence();
  hotelInfo: Unit;
  loading = false;
  user: User;
  employees: Employee[] = [];
  subcategories: { type: OccurrenceType; subcategories: OccurrenceCategory[] }[] = [];
  chosenEmployee: Employee; // employee id to send request

  replyComment = "";
  rejoinderComment = "";

  /** photo uploading section */
  bgAvatar = false;
  previewUrl: string | ArrayBuffer;
  file: any;
  uploadForm: FormGroup;

  OccurrenceStatus = OccurrenceStatus;
  occurrenceTypeTranslator = occurrenceTypeTranslator;

  @ViewChild('content', undefined) public commentRef: TemplateRef<any>;
  @ViewChild('reply', undefined) public replyRef: TemplateRef<any>;
  @ViewChild('commentRejoinder', undefined) public commentRejoinderRef: TemplateRef<any>;

  constructor(
    public service: OccurrenceService,
    public router: Router,
    private modalService: NgbModal,
    public route: ActivatedRoute,
    public authService: AuthService,
    public navService: NavService,
    public formBuilder: FormBuilder,
    private employeeSrvc: EmployeesService,
    private occurrenceCategorySrvc: OccurrenceCategoryService,
    private occurrenceSrvc: OccurrenceService,
    private notificationSrvc: NotificationService,
  ) {
    this.uploadForm = this.formBuilder.group({
      avatar: [''],
    });
  }

  ngOnInit() {
    this.hotelInfo = this.navService.hotelInfo;
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.occurrence.id = params.id;
        this.getData(params.id);
        this.loadEmployees();
        this.loadOccurrenceCategories();
      } else {
        this.router.navigate(['error', '404']);
      }
    });
    this.authService.getLoggedProfile().subscribe((usr) => {
      this.user = usr;
    });
    this.uploadForm = this.formBuilder.group({
      // TODO update
    });
  }

  async loadOccurrenceCategories() {
    const subcategories = await this.occurrenceCategorySrvc.getAll().catch((error) => {
      console.error('ERROR: OccurrenceFormPage -> loadOccurrenceCategories -> error ', error);
      const errorMessage = handleErrors(error.error);
      showCustomErrorAlert({ title:"Problema ao carregar subcategorias.", text: errorMessage });
      return [] as OccurrenceCategory[];
    });

    for (const sub of subcategories) {
      const existent = this.subcategories.find((s) => s.type === sub.type);
      if (existent) {
        existent.subcategories.push(sub);
      } else {
        this.subcategories.push({
          type: sub.type,
          subcategories: [sub],
        });
      }
    }
  }

  /** PHOTO UPLOAD */

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log('DEBUG: DetailsGuestComponent -> onFileSelect -> file', file);
      this.file = file;
      // this.uploadForm.get('avatar').setValue(file);
      const mimeType = file.type;
      if (mimeType.match(/image\/*/) === null) {
        alert('Only images (.jpg, .jpeg, .png) are supported.');
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.previewUrl = reader.result;
        Swal.fire({
          html: `
            <img src="${this.previewUrl}" style="width: 100%; height: auto" />
          `,
          showConfirmButton: true,
          confirmButtonClass: 'btn btn-success m-2 float-right',
          confirmButtonText: 'Confirmar',
          showCancelButton: true,
          cancelButtonClass: 'btn btn-outline-danger m-2 float-left',
          cancelButtonText: 'Cancelar',
          allowOutsideClick: false,
          width: 700,
          buttonsStyling: false,
        }).then(async (resp) => {
          if (resp.value) {
            this.updateAvatar();
          }
        });
      };
    }
  }

  updateAvatar() {
    if (!this.file) {
      return;
    }
    console.log('sending file...', this.file);
    this.occurrenceSrvc.uploadPhoto(this.occurrence.id, this.file).then(
      (res) => {
        console.log('Upload feito', res);
        Swal.fire({
          type: 'success',
          text: 'Foto enviada',
          titleText: 'Feito!',
        });
        this.uploadForm.reset();
        this.getData(this.occurrence.id);
        this.bgAvatar = true;
      },
      (error) => {
        console.error('Erro no upload', error);
        if (error.statusCode === 413) {
          Swal.fire({
            titleText: 'Imagem muito grande!',
            text: 'Por favor, envie imagens com menos de 2MB',
            type: 'error',
          });
          this.uploadForm.reset();
          this.previewUrl = undefined;
        } else {
          const errorMessage = handleErrors(error.error);
          showCustomErrorAlert({ title: "Ops!", text: errorMessage });
        }
      },
    );
  }

  resetForm() {
    this.uploadForm.reset();
    this.previewUrl = undefined;
    this.bgAvatar = false;
  }

  deleteAvatar() {
    Swal.fire({
      titleText: 'Deseja mesmo remover a imagem previamente enviada?',
      text: 'Esta ação não poderá ser desfeita.',
      type: 'question',
      showCancelButton: true,
      cancelButtonText: 'Manter foto',
      focusCancel: true,
      cancelButtonClass: 'float-left btn btn-primary',
      showCloseButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Remover',
      confirmButtonClass: 'float-right btn btn-danger',
    }).then(async (resp) => {
      if (resp.value) {
        this.previewUrl = undefined;
        this.bgAvatar = false;

        await this.service.update(this.occurrence.id, new Occurrence()).catch((error) => {
          const errorMessage = handleErrors(error.error);
          showCustomErrorAlert({ text: errorMessage });
        });

        await Swal.fire({
          type: 'success',
          text: 'Foto removida',
          titleText: 'Pronto!',
        });

        this.previewUrl = undefined;
        this.occurrence.photoUrl = null;
      }
    });
  }

  /** ============================================================================================= */

  getData(id: number) {
    this.loading = true;
    this.service
      .read(id)
      .then((sggs) => {
        this.occurrence = new Occurrence(sggs);
        this.previewUrl = this.occurrence.photoUrl;
        this.chosenEmployee = this.occurrence.employee;
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        console.error('Erro ao ler sugestão ', error);
        const errorMessage = handleErrors(error.error);
        showCustomErrorAlert({ title: "Ops, ocorreu um erro", text: errorMessage });
      });
  }

  open(modal: TemplateRef<any>) {
    this.modalService.open(modal, { ariaLabelledBy: 'modal-basic-title' }).result.then(async (result) => {
      console.log(result);
    });
  }


  async comment() {
    await this.service.update(this.occurrence.id, new Occurrence({ adminComment: this.occurrence.adminComment })).catch((e) => {
      return showDefaultErrorAlert(e);
    });
    Swal.fire({
      title: 'Comentário',
      text: 'Comentário salvo',
      showConfirmButton: true,
      confirmButtonText: 'Ok',
      showCloseButton: true,
      type: 'success',
    });
  }

  async loadEmployees() {
    const employees = await this.employeeSrvc.search(this.hotelInfo.hotelCode, '', UserRole.Maintenance).catch((e) => {
      showDefaultErrorAlert(e);
      return { data: [] };
    });
    console.log('TCL: OccurrenceDetailComponent -> loadEmployees -> employees', employees);
    this.employees = employees.data;
  }

  setChosenEmployee(employee: Employee) {
    console.log('TCL: OccurrenceDetailComponent -> setChosenEmployee -> employee', employee);
    this.chosenEmployee = employee;
  }

  assignEmployee() {
    console.log(this.chosenEmployee);

    if (!this.chosenEmployee) {
      Swal.fire({
        title: 'Erro ao atribuir serviço',
        text: 'Você deve informar pelo menos um funcionário da lista.',
        showConfirmButton: true,
        confirmButtonText: 'Ok',
        showCloseButton: true,
        type: 'error',
      });

      return;
    }

    console.log('TCL: assignEmployee -> this.occurrence.employee', this.occurrence.employee);
    if (this.occurrence.employee) {
      Swal.fire({
        title: 'Reatribuição de serviço',
        text: `Tem certeza que deseja atribuir o serviço à ${this.chosenEmployee.firstName}`,
        showConfirmButton: true,
        confirmButtonText: 'Ok',
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: 'Não',
        type: 'question',
      }).then((res) => {
        console.log('Swall response ->', res);

        if (!res.dismiss) {
          this.sendRequestToAssign();
        }
      });
    } else {
      this.sendRequestToAssign();
    }
  }

  sendRequestToAssign() {
    this.loading = true;
    this.occurrenceSrvc
      .assignEmployee(this.occurrence.id, this.chosenEmployee.id)
      .then(() => {
        this.loading = false;
        Swal.fire({
          title: 'Serviço atribuído',
          text: `O serviço foi atribuído com sucesso para ${this.chosenEmployee.firstName}`,
          showConfirmButton: true,
          confirmButtonText: 'Ok',
          showCloseButton: true,
          type: 'success',
        }).then((res) => {
          console.log('TCL: sendRequestToAssign -> res', res);
          if (res.value) {
            this.getData(this.occurrence.id);
          }
        });
      })
      .catch((error) => {
        console.log('Service assignment error::', error);
        this.loading = false;
        const errorMessage = handleErrors(error.error);
        showCustomErrorAlert( { title:"Ops, ocorreu um erro", text: errorMessage } );
      });
  }

  start() {
    if (!this.occurrence.employee) {
      Swal.fire({
        title: 'Funcionário necessário',
        text: `É necessário atribuir um funcionário para iniciar a ordem de serviço`,
        showConfirmButton: true,
        confirmButtonText: 'Ok',
        showCloseButton: true,
        type: 'warning',
      });
    } else {
      this.occurrenceSrvc
        .updateAdmin(this.occurrence.id, { status: OccurrenceStatus.PROGRESS })
        .then(() => {
          Swal.fire({
            title: 'Ordem de serviço em andamento',
            // tslint:disable-next-line: max-line-length
            text: `Assim que o serviço estiver concluído, adicione uma foto e um comentário de resposta e finalize a OS para informar o morador.`,
            showConfirmButton: true,
            confirmButtonText: 'Ok',
            showCloseButton: true,
            type: 'success',
          }).then((res) => {
            if (res.value) {
              this.occurrence.status = OccurrenceStatus.PROGRESS;
            }
          });
        })
        .catch((error) => {
          const errorMessage = handleErrors(error.error);
          showCustomErrorAlert( { text: errorMessage } );
        });
    }
  }

  finish() {
    this.occurrenceSrvc.updateAdmin(this.occurrence.id, { status: OccurrenceStatus.FINISHED }).then(() => {
      Swal.fire({
        title: 'Finalização de serviço',
        text: `Serviço finalizado com sucesso`,
        showConfirmButton: true,
        confirmButtonText: 'Ok',
        showCloseButton: true,
        type: 'success',
      }).then((res) => {
        if (res.value) {
          this.getData(this.occurrence.id);
        }
      });
    });
  }

  reopen() {
    this.occurrenceSrvc.updateAdmin(this.occurrence.id, { status: OccurrenceStatus.REQUESTED }).then(() => {
      Swal.fire({
        title: 'Reabertura de serviço',
        text: `Serviço reaberto com sucesso`,
        showConfirmButton: true,
        confirmButtonText: 'Ok',
        showCloseButton: true,
        type: 'success',
      }).then((res) => {
        if (res.value) {
          this.getData(this.occurrence.id);
        }
      });
    });
  }

  async deleteOccurrenceClick() {
    Swal.fire({
      title: 'Tem certeza que quer deletar a ordem de serviço ' + this.occurrence.serviceNumber + '?',
      text: 'Esta ação não poderá ser desfeita',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Excluir',
      confirmButtonClass: 'btn btn-danger',
      cancelButtonClass: 'btn btn-secondary',
    }).then((resp) => {
      if (resp.value) {
        this.delete(this.occurrence.id);
      }
    });
  }

  delete(occurrenceCode: number) {
    this.service
      .delete(occurrenceCode)
      .then((del) => {
        Swal.fire({
          type: 'success',
          text: 'Ordem de serviço ' + this.occurrence.serviceNumber + ' deletada com sucesso.',
          titleText: 'Excluído.',
        }).then((resp) => {
          this.goToList();
        });
      })
      .catch((error) => {
        const errorMessage = handleErrors(error.error);
        showCustomErrorAlert( { title:"Erro ao excluir" , text: errorMessage } );
      });
  }

  updateSubcategory(event: any) {
    this.occurrenceSrvc
      .update(
        this.occurrence.id,
        new Occurrence({
          type: this.occurrence.subcategory.type,
          subcategory: this.occurrence.subcategory,
        }),
      )
      .then(() => {
        this.occurrence.type = this.occurrence.subcategory.type;
        Swal.fire({
          toast: true,
          position: 'bottom-right',
          timer: 5000,
          type: 'success',
          title: 'Salvo',
          text: 'Tipo e subcategoria atualizados',
        });
      })
      .catch((e) => {
        showDefaultErrorAlert(e);
      });
  }

  guestInfo(occurrence: Occurrence) {
    return `${occurrence.user.guest.GivenName} ${occurrence.user.guest.SurName}`;
  }

  userInfo(occurrence: Occurrence) {
    return `${occurrence.user ? occurrence.user.name : 'Uliving'} - ${occurrence.roomNumber}`;
  }

  goToList() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'occurrence', 'request']);
  }

  compareById(a: Employee, b: Employee) {
    return a && b && a.id === b.id;
  }
}
