import { Component, OnInit } from '@angular/core';
import { Unit } from '../entities/units/unit.entity';
import { ActivatedRoute } from '@angular/router';
import { NavService } from '../shared/service/nav.service';
import { Voucher, VouchersService } from './vouchers.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-vouchers-list',
  templateUrl: './vouchers-list.component.html',
  styleUrls: ['./vouchers-list.component.scss'],
})
export class VouchersListComponent implements OnInit {
  searchTerm = '';
  loading = false;
  hotelInfo: Unit;
  vouchers: Voucher[] = [];
  pagination = {
    page: 1,
    limit: 15,
    pages: 5,
    collectionSize: 75,
  };

  constructor(public route: ActivatedRoute, public navService: NavService, private vouchersSrvc: VouchersService) {}

  ngOnInit() {
    this.navService.currentUnit$.subscribe((unit) => {
      this.hotelInfo = unit;
      this.loading = false;
      this.loadVouchers().then(() => (this.loading = false));
    });
  }

  async loadVouchers() {
    const resp = await this.vouchersSrvc.loadUnitVouchers(this.pagination.page, this.pagination.limit);
    this.vouchers = resp.data;
    this.pagination.pages = resp.totalPages;
    this.pagination.collectionSize = resp.totalPages * this.pagination.limit;
  }

  deleteVoucher(event, voucher: Voucher) {
    event.stopPropagation();

    Swal.fire({
      title: 'Deletar voucher',
      text: `Tem certeza que deseja excluir o voucher ${voucher.title}?`,
      type: 'question',
      cancelButtonText: 'Não',
      showCancelButton: true,
      cancelButtonColor: '#aaaaaa',
      confirmButtonText: 'Sim',
      confirmButtonColor: '#30d646',
      showConfirmButton: true,
    }).then(res => {
      const { value } = res;

      if (value) {
        this.vouchersSrvc.deleteVoucher(voucher.id).then(() => {
          this.loadVouchers().then(() => (this.loading = false));
        });
      }
    });
  }
}
