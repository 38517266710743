import { CleaningType } from './cleaning-type.entity';
import { ExtraService } from './extra-service.entity';
import { Unit } from '../units/unit.entity';
import { Room } from '../rooms/room.entity';
import { Employee } from '../employees/employee.entity';
import { RestEntity, DeepPartial } from '../rest.entity';
import { Area } from '../units/area.entity';
import { GuestBilling } from '../residents/guestBilling.entity';
import { User } from '../residents/user.entity';

export enum CleaningStatus {
  REQUESTED = 'Requested',
  CONFIRMED = 'Confirmed',
  STARTED = 'Started',
  FINISHED = 'Finished',
  REFUSED = 'Refused',
  CANCELLED = 'Cancelled',
}

export function translateStatus(status) {
  switch(status) {
    case 'Requested':
      return 'Solicitado';
    case 'Confirmed':
      return 'Confirmado';
    case 'Started':
      return 'Iniciado';
    case 'Finished':
      return 'Finalizado';
    case 'Refused':
      return 'Recusado';
    case 'Cancelled':
      return 'Cancelado';
  }
}

export enum CleaningPeriod {
  MORNING = 'Morning',
  AFTERNOON = 'Afternoon',
  NIGHT = 'Night',
}

export function translatePeriod(period) {
  switch(period)  {
    case 'Night':
      return 'Noite';
    case 'Afternoon':
      return 'Tarde';
    case 'Morning':
      return 'Manhã';
    default:
      return 'Período não informado'
  }
}

export interface CleaningQR {
  hotelCode: number;
  roomId?: number;
  areaId?: number;
  type: 'area' | 'room';
}

export class Cleaning extends RestEntity<Cleaning> {
  constructor(obj?: DeepPartial<Cleaning>) {
    super(obj);
    if (obj && obj.startDate) {
      this.startDate = new Date(String(obj.startDate));
    }
    if (obj && obj.endDate) {
      this.endDate = new Date(String(obj.endDate));
    }
    if (obj && obj.user) {
      this.user = new User(obj.user);
    }
    if (obj && obj.room) {
      this.room = new Room(obj.room);
    }
  }

  user: User;

  unit: Unit;

  room: Room;

  area: Area;

  employee: Employee;

  roomNumber: string;

  cleaningType: CleaningType;

  extraService?: ExtraService[];

  observation?: string;

  status: CleaningStatus;

  period: CleaningPeriod;

  totalPrice: number;

  date: Date;

  startDate?: Date;

  effectiveStartDate?: Date;

  endDate?: Date;

  effectiveEndDate?: Date;

  comment?: string;

  billing: GuestBilling;
}
