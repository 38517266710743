export declare type DeepPartial<T> = {
  [P in keyof T]?:
    T[P] extends Array<infer U> ?
      Array<DeepPartial<U>> :
      // tslint:disable-next-line: no-shadowed-variable
      T[P] extends ReadonlyArray<infer U> ?
        ReadonlyArray<DeepPartial<U>> :
        DeepPartial<T[P]>;
};

export class RestEntity<T> {

  constructor(obj?: DeepPartial<T>) {
    Object.assign(this, obj);
  }

  id?: number;

  createdDate?: Date;

  updatedDate?: Date;
}
