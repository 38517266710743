import { Component, OnInit, Input } from '@angular/core';
import { Color } from 'ng2-charts';
import * as chartJs from 'chart.js';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

const COLORS: Color[] = [
  {backgroundColor: '#4572A7'},
  {backgroundColor: '#AA4643'},
  {backgroundColor: '#89A54E'},
  {backgroundColor: '#80699B'},
  {backgroundColor: '#3D96AE'},
  {backgroundColor: '#DB843D'},
  {backgroundColor: '#92A8CD'},
  {backgroundColor: '#A47D7C'},
  {backgroundColor: '#B5CA92'}
];

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {

  @Input() public labels = ['Sales Q1', 'Sales Q2', 'Sales Q3', 'Sales Q4'];
  @Input() public data = [120, 150, 180, 90];
  @Input() public groups = [];
  @Input() public canvasId: string;
  @Input() public legend = true;
  @Input() public type: 'pie' | 'doughnut'  = 'pie';
  @Input() public colors = undefined;
  @Input() public title = '';
  @Input() public options: chartJs.ChartOptions = {
    animation: {
      easing: 'easeInOutCubic'
    },
    plugins: {
    },
    tooltips: {
    },
    legend: {
      display: this.legend,
      position: 'right'
    },
  };

  constructor(
    public router: Router
  ) { }

  ngOnInit() {
    console.log('init groups', this.groups);
  }

  showTableAlert(group: any, textValue: string) {
    console.log('DEBUG: PieChartComponent -> group',  group);
    let rows = `
    <thead>
        <tr>
            <th scope="col">Nome</th>
            <th scope="col">Email</th>
            <th scope="col">Profissão</th>
            <th scope="col">Local</th>
            <th scope="col">Score</th>
        </tr>
    </thead><tbody>`;
    if (!group || !group.items) {
      return;
    }
    group.items = group.items.sort((a, b) => {
    console.log('DEBUG: PieChartComponent -> a, b', a.note, b.note, a.note > b.note ? a.id : b.id);
    console.log('DEBUG: PieChartComponent -> types a e b', typeof a.note === 'number' && typeof b.note === 'number');
      if (typeof a.note === 'number' && typeof b.note === 'number') {
        return a.note > b.note ? -1 : 1;
      } else if (typeof a.note === 'number') {
        return -1;
      } else if (typeof b.note === 'number') {
        return 1;
      }
      return 0;
    });
    for (const item of group.items) {
      rows = rows + `<tr id="tr-${item.id}" class="cursor-pointer">`;
      rows = rows + `<td>${item['name']}</td>`;
      rows = rows + `<td>${item['email']}</td>`;
      rows = rows + `<td>${item.getProfession()} <small>${item.job ? '(' + item.job + ')' : ''}</small></td>`;
      rows = rows + `<td>${item.getPlace()}</td>`;
      rows = rows + `
      <td>
        <div class="badge ${this.getNoteColor(item.note)}">
            ${item.note}
        </div>
      </td>`;
      rows = rows + `</tr>`;
    }
    console.log('DEBUG: PieChartComponent -> rows', rows);
    Swal.fire({
      html: `
        <h5>${this.title}:</h5>
        <h3>${textValue}</h3>
        <table class="table table-striped border">
          ${rows}
          </tbody>
        </table>`,
      width: 800,
      showCloseButton: true,
      customContainerClass: 'table-alert',
    });

    for (const item of group.items) {
      document.getElementById(`tr-${item.id}`).addEventListener('click', (e) => {
        Swal.close();
        this.router.navigate(['/professionals', item.id]);
      });
    }
  }

  getNoteColor(note: number | '?') {
    if (note === '?') {
      return ['badge-light'];
    } else if (note < 15) {
      return ['bg-scale-0'];
    } else if (note >= 15 && note < 30) {
      return ['bg-scale-1'];
    } else if (note >= 30 && note < 50) {
      return ['bg-scale-2'];
    } else if (note >= 50 && note < 70) {
      return ['bg-scale-3'];
    } else if (note >= 70 && note < 90) {
      return ['bg-scale-4'];
    } else if (note >= 90 && note <= 100) {
      return ['bg-scale-5'];
    }
  }

}
