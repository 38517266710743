import { Injectable } from '@angular/core';
import { APIService } from '../api.service';
import { OccurrenceCategory } from './occurrence-categories.entity';

@Injectable({
  providedIn: 'root',
})
export class OccurrenceCategoryService {
  constructor(public API: APIService) {}

  async getAll(sort?: string): Promise<OccurrenceCategory[]> {
    const params = {};

    if (sort) {
      Object.assign(params, { sort });
    }

    const occurrenceCategories = await this.API.get<OccurrenceCategory[]>('/occurrence-categories', params, { tokenRequired: true }).catch((e) => {
      throw e;
    });
    if (occurrenceCategories && occurrenceCategories.length) {
      return occurrenceCategories.map((s) => new OccurrenceCategory(s));
    }
    return [];
  }

  create(occurrenceCategory: OccurrenceCategory) {
    occurrenceCategory = this.clearNulls(occurrenceCategory);
    return this.API.post<OccurrenceCategory, OccurrenceCategory>('/occurrence-categories', occurrenceCategory, { tokenRequired: true });
  }

  read(id: number) {
    return this.API.get<OccurrenceCategory>(`/occurrence-categories/${id}`, {}, { tokenRequired: true });
  }

  update(occurrenceCategory: OccurrenceCategory, id: number) {
    return this.API.patch<OccurrenceCategory, OccurrenceCategory>(`/occurrence-categories/${id}`, occurrenceCategory, { tokenRequired: true });
  }

  delete(id: number) {
    return this.API.delete<OccurrenceCategory>(`/occurrence-categories/${id}`, {}, { tokenRequired: true });
  }

  clearNulls(occurrenceCategory: OccurrenceCategory) {
    for (const key in occurrenceCategory) {
      if (occurrenceCategory[key] === null || occurrenceCategory[key] === undefined) {
        delete occurrenceCategory[key];
      }
    }
    return occurrenceCategory;
  }
}
