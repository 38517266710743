import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Headers } from '@angular/http';
import {  Professional } from './professional.entity';
import { Evaluation } from '../evaluations/evaluations.entity';

export const BASE_URL = 'http://localhost:8090';
@Injectable({
  providedIn: 'root'
})
export class ProfessionalsService {

  httpHeaders: HttpHeaders = new HttpHeaders({
    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjEsImVtYWlsIjoiYWRtaW5AZXNwcmVzc29sYWJzLmNvbS5iciIsInJvbGUiOiJBZG1pbiIsInNlc3Npb25JZCI6MSwiaWF0IjoxNTY1NzEyMjYxLCJleHAiOjE1NjgzMDQyNjF9.nxpFL6KNbKEDd2do275VDsXYD7iRWfx_jfQye0ljSIw',
  });
  constructor(
    private http: HttpClient
  ) {}


  getAll() {
    return this.http.get<Professional[]>(BASE_URL + '/professionals', {
      headers: this.httpHeaders,
      params: {
        // filter: 'user.role||eq||Professional'
      },
    });
  }

  search(query?: string) {
    const props = ['id', 'name', 'email', 'job', 'profession', 'city', 'state', 'countryCode'];
    let searchString = '';

    if (query && query.length > 0) {
      for (const prop of props) {
        searchString = searchString.concat(`or=${prop}||cont||${query}&`);
      }
    }

    return this.http.get<Professional[]>(BASE_URL + '/professionals?' + searchString, {
      headers: this.httpHeaders,
      params: {
        sort: 'name,ASC'
      },
    });
  }



  read(id: number) {
    return this.http.get<Professional>(BASE_URL + '/professionals/' + id, {
      headers: this.httpHeaders
    });
  }

  create(professional: Professional) {
    return this.http.post<Professional>(BASE_URL + '/professionals', professional, {
      headers: this.httpHeaders
    });
  }

  evaluate(professionalId: number, evaluation: Evaluation) {
    evaluation.professional = professionalId;
    evaluation.reporter = 1;
    return this.http.post<Professional>(BASE_URL + '/evaluations', evaluation, {
      headers: this.httpHeaders
    });
  }
}
