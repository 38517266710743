import { AnswerAOS, AnswerDiagnosisVolume, AnswerPAP, AnswerPrescriptionEquipments, AnswerPrescriptionMasks, AnswerPrescriptionVolume, AnswerAirViewDiagnosis, AnswerAirViewTherapy, AnswerMyAirPrescription, AnswerReference, AnswerEventsWorkshop, AnswerEventsLecture, AnswerEventsReach, AnswerLecturesRMD, AnswerLaunchesRMD, AnswerWebinarsRMD, AnswerEventsScience, AnswerPublications, AnswerPublicationsRMD, AnswerRelevance } from './answers';
import { Evaluation } from './evaluations.entity';

export interface Question {
  title: string;
  subtitle?: string;
  required: boolean;
  text: string;
  enum: object;
  options: string[];

  evaluationKey: keyof Evaluation;
}

export const QUESTIONS: Question[] = [
  {
    title: 'Realiza diagnóstico de AOS?',
    required: true,
    text: 'Diagnóstico',
    enum: AnswerAOS,
    options: ['Não', 'PSG', 'PSG+HST', 'Apenas HST', 'HST com ApneaLink'],
    evaluationKey: 'AOS'
  },
  {
    title: 'Volume de diagnósticos domiciliares por mês',
    required: true,
    text: 'Voume Diagnóstico',
    enum: AnswerDiagnosisVolume,
    options: ['Zero', '< 50', '1 a 75', '76 a 100', '> 100'],
    evaluationKey: 'diagnosisVolume'
  },
  {
    title: 'Realiza exames de titulação de PAP?',
    required: true,
    text: 'Titulação',
    enum: AnswerPAP,
    options: ['Não', 'In Lab', 'In Lab e Remota', 'Só remota com AirView', 'Remota AirView com Oximetria'],
    evaluationKey: 'titulationPAP'
  },
  {
    title: 'Prescrição/ indicação de PAP',
    required: true,
    text: 'Equipamentos',
    enum: AnswerPrescriptionEquipments,
    options: [
      'Não sugere marca',
      'Sugere 2+ opções',
      'Sugere ResMed e outra Marca',
      'Prescreve ativamente ResMed e outra marca',
      'Prescreve ativamente só ResMed'
    ],
    evaluationKey: 'prescriptionEquipments'
  },
  {
    title: 'Prescrição/ indicação de PAP',
    required: true,
    text: 'Máscaras',
    enum: AnswerPrescriptionMasks,
    options: [
      'Não sugere marca',
      'Sugere 2+ opções',
      'Sugere ResMed e outra Marca',
      'Prescreve ativamente ResMed e outra marca',
      'Prescreve ativamente só ResMed'
    ],
    evaluationKey: 'prescriptionMasks'
  },
  {
    title: 'Volume de prescrições, indicações ou adaptações de PAP/ mês',
    subtitle: 'Considere adaptações ou indicações por profissionais não-médicos',
    required: true,
    text: 'Volume Prescrição',
    enum: AnswerPrescriptionVolume,
    options: ['< 20', '21 a 40', '41 a 60', '> 60'],
    evaluationKey: 'prescriptionVolume'
  },
  {
    title: 'Uso do AirView como ferramenta',
    required: true,
    text: 'AirView Diagnóstico',
    enum: AnswerAirViewDiagnosis,
    options: ['Não', 'Tem conta, mas não usa', 'Usa às vezes', 'Parte AirView, parte software', 'Apenas AirView'],
    evaluationKey: 'airViewDiagnosis'
  },
  {
    title: 'Uso do AirView como ferramenta',
    required: true,
    text: 'AirView Terapia',
    enum: AnswerAirViewTherapy,
    options: ['Não', 'Tem conta, mas não usa', 'Usa às vezes', 'Parte AirView, parte software', 'Apenas AirView'],
    evaluationKey: 'airViewTherapy'
  },
  {
    title: 'Prescrição de My Air para os pacientes',
    required: true,
    text: 'My Air',
    enum: AnswerMyAirPrescription,
    options: ['Não', 'A/C do paciente', 'Indica a alguns pacientes', 'Indica a todos os pacientes', 'Cadastra todos os pacientes'],
    evaluationKey: 'myAirPrescription'
  },
  {
    title: 'Nível de referência clínica na área de Sono',
    required: false,
    text: 'Referência',
    enum: AnswerReference,
    options: ['Na cidade', 'No estado', 'Na região', 'No país'],
    evaluationKey: 'reference'
  },
  {
    title: 'Envolvimento/ Promoção de cursos e atividades educativas (Workshops)',
    subtitle: 'Considere o envolvimento dos produtos ResMed no conteúdo da atividade',
    required: true,
    text: 'Workshop',
    enum: AnswerEventsWorkshop,
    options: ['Não faz', '1 por ano', '2 por ano', '2+ por ano'],
    evaluationKey: 'eventsWorkshop'
  },
  {
    title: 'Envolvimento/ Promoção de cursos e atividades educativas (Palestras/Aulas teóricas)',
    subtitle: 'Considere o envolvimento dos produtos ResMed no conteúdo da atividade',
    required: true,
    text: 'Workshop',
    enum: AnswerEventsLecture,
    options: ['Não faz', '1 por ano', '2 por ano', '2+ por ano'],
    evaluationKey: 'eventsLecture'
  },
  {
    title: 'Relativo às atividades anteriores:',
    required: true,
    text: 'Alcance',
    enum: AnswerEventsReach,
    options: ['Não faz', 'Até 25 pessoas', '26 a 50 pessoas', '51 a 75 pessoas', '75+ pessoas'],
    evaluationKey: 'eventsReach'
  },
  {
    title: 'Engajamento nas atividades Resmed (Palestras)',
    required: false,
    text: 'Palestras',
    enum: AnswerLecturesRMD,
    options: ['Nunca', 'Raramente (25%)', 'Às vezes (50%)', 'Frequente (75%)', 'Sempre'],
    evaluationKey: 'lecturesRMD'
  },
  {
    title: 'Engajamento nas atividades Resmed (Lançamentos)',
    required: false,
    text: 'Lançamentos',
    enum: AnswerLaunchesRMD,
    options: ['Nunca', 'Raramente (25%)', 'Às vezes (50%)', 'Frequente (75%)', 'Sempre'],
    evaluationKey: 'launchesRMD'
  },
  {
    title: 'Engajamento nas atividades Resmed (Webinares)',
    required: false,
    text: 'Webinares',
    enum: AnswerWebinarsRMD,
    options: ['Nunca', 'Raramente (25%)', 'Às vezes (50%)', 'Frequente (75%)', 'Sempre'],
    evaluationKey: 'webinarsRMD'
  },
  {
    title: 'Desenvolvimento de atividades como pesquisador na área de Sono (ciência)',
    subtitle: 'Considere apresentação como speaker (eventos), primeiro autor ou orientador (publicações)',
    required: false,
    text: 'Eventos científicos',
    enum: AnswerEventsScience,
    options: ['Não participa', 'Regional', 'Nacional', 'Internacional'],
    evaluationKey: 'eventsScience'
  },
  {
    title: 'Desenvolvimento de atividades como pesquisador na área de Sono (publicações)',
    subtitle: 'Considere apresentação como speaker (eventos), primeiro autor ou orientador (publicações)',
    required: false,
    text: 'Publicações',
    enum: AnswerPublications,
    options: ['Não participa', 'Regional', 'Nacional', 'Internacional'],
    evaluationKey: 'publications'
  },
  {
    title: 'Desenvolvimento de atividades como pesquisador na área de Sono (publicações c/ ResMed)',
    subtitle: 'Considere apresentação como speaker (eventos), primeiro autor ou orientador (publicações)',
    required: false,
    text: 'Publicações com ResMed',
    enum: AnswerPublicationsRMD,
    options: ['Não participa', 'Regional', 'Nacional', 'Internacional'],
    evaluationKey: 'publicationsRMD'
  },
  {
    title: 'Relevância da linha de pesquisa para ResMed',
    subtitle: 'Alinhamento com estratégias comerciais vigentes no período de apresentação/desenvolvimento',
    required: false,
    text: 'Relevância ResMed',
    enum: AnswerRelevance,
    options: ['Não', 'Baixa', 'Moderada', 'Alta'],
    evaluationKey: 'relevance',
  },
];
