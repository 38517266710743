import { Injectable } from '@angular/core';
import { APIService } from '../api.service';
import { Space } from '../entities/spaces/space.entity';
import { Unit } from '../entities/units/unit.entity';
import { Schedule } from '../entities/spaces/schedule.entity';

@Injectable({
  providedIn: 'root'
})
export class SpacesService {

  constructor(
    public API: APIService
  ) { }

  async getAllFromUnit(hotelCode: number) {
    const unit = await this.API.get<Unit>('/units/' + hotelCode, {}, {tokenRequired: true});
    return unit.spaces.map(s => new Space(s));
  }

  create(space: Space) {
    space = this.clearNulls(space);
    return this.API.post<Space, Space>('/spaces', space, {tokenRequired: true});
  }

  read(id: Number) {
    return this.API.get<Space>(`/spaces/${id}`, {}, {tokenRequired: true});
  }

  update(space: Space, id: Number) {
    return this.API.patch<Space, Space>(`/spaces/${id}`, space, {tokenRequired: true});
  }

  delete(id: number) {
    return this.API.delete<Space>(`/spaces/${id}`, {}, {tokenRequired: true});
  }

  clearNulls(space: Space) {
    for (const key in space) {
      if (space[key] === null || space[key] === undefined) {
        delete space[key];
      }
    }
    return space;
  }
}
