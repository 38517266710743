import { CanActivate, Router, Route, ActivatedRoute, ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';
import { APIService } from '../../api.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { PERMISSIONS } from '../permissions';
import Swal from 'sweetalert2';
import { NavService } from '../service/nav.service';
import { UserRole } from '../../entities/residents/user.entity';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivateChild {
  constructor(private API: APIService, private router: Router, private nav: NavService) {}
  canActivateChild(childRoute: ActivatedRouteSnapshot) {
    const { path, action } = childRoute.data;
    const user = this.API.loggedUser$.getValue();
    const all = [UserRole.Admin, UserRole.Manager, UserRole.Porter, UserRole.Maintenance, UserRole.Cleaning, UserRole.Facilities];
    const guardArray = PERMISSIONS[path] && PERMISSIONS[path][action] ? PERMISSIONS[path][action] : all;
    if (user && user.role) {
      if (!guardArray.includes(user.role)) {
        if (path === 'units' && action === 'list') {
          const unit = this.nav.currentUnit$.getValue() ? this.nav.currentUnit$.getValue().code : user.employee.unit.code;
          this.router.navigate(['/unit', unit, 'residents']);
          return true;
        }
        Swal.fire({
          type: 'error',
          titleText: 'Área restrita por cargo',
          text: 'Você não tem permissão para realizar esta ação',
        });
        return false;
      }
      return true;
    }
    this.router.navigate(['/login']);
    return true;
  }
}
