import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Professional } from '../professionals/professional.entity';
import { ProfessionalsService } from '../professionals/professionals.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-professional',
  templateUrl: './professional.component.html',
  styleUrls: ['./professional.component.scss']
})
export class ProfessionalComponent implements OnInit {

  professional: Professional = new Professional();
  constructor(
    private route: ActivatedRoute,
    public professionalService: ProfessionalsService
  ) {
    this.loadData();
  }

  ngOnInit() {
  }

  async loadData() {
    console.log('loading data');
    const params = await this.route.params.pipe(take(1)).toPromise();
    this.professional.id = params['id'];
    this.professionalService.read(this.professional.id).subscribe(prof => {
      console.log("TCL: ProfessionalComponent -> loadData -> prof", prof)
      this.professional = new Professional(prof);
    });
  }

}
