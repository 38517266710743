import { Component, OnInit } from '@angular/core';
import { SuggestionsService } from '../suggestions.service';
import { ActivatedRoute } from '@angular/router';
import { NavService } from 'src/app/shared/service/nav.service';
import { Unit } from '../../entities/units/unit.entity';
import { Suggestion } from 'src/app/entities/units/suggestions.entity';
import { SuggestionType, SuggestionStatus } from '../../entities/units/suggestions.entity';

@Component({
  selector: 'app-list-suggestions',
  templateUrl: './list-suggestions.component.html',
  styleUrls: ['./list-suggestions.component.scss'],
})
export class ListSuggestionsComponent implements OnInit {
  suggestions: Suggestion[] = [];
  type: SuggestionType = SuggestionType.SUGGESTION;
  SuggestionStatus = SuggestionStatus;
  chosenStatus = 'ALL';
  loading = true;
  hotelInfo: Unit;

  pagination = {
    page: 1,
    limit: 15,
    pages: 5,
    collectionSize: 75,
  };
  constructor(public service: SuggestionsService, public route: ActivatedRoute, public navService: NavService) {}

  ngOnInit() {
    this.route.parent.params.subscribe(params => {
      this.hotelInfo = this.navService.hotelInfo;
      this.type = params.type === 'suggestions' ? SuggestionType.SUGGESTION : SuggestionType.COMPLAINT;

      this.getAll();
    });
    this.navService.currentUnit$.subscribe((unit) => {
      this.hotelInfo = unit;

      this.getAll();
    });
  }

  async getAll() {
    const params: any = {};

    if (!this.hotelInfo || !this.hotelInfo.hotelCode) {
      return;
    }

    if (this.chosenStatus) {
      params.status = this.chosenStatus;
      if (this.chosenStatus === 'ALL') {
        delete params.status;
      }
    }

    this.loading = true;

    const res = await this.service.loadUnitSuggestions(this.hotelInfo.hotelCode, this.type, this.pagination.page, this.pagination.limit, params)
    .catch((e): any => {
      alert('Erro ao buscar sugestões');
      this.loading = false;
      return { data: []};
    });

    this.suggestions = res.data;
    this.pagination.pages = res.totalPages;
    this.pagination.collectionSize = res.totalPages * this.pagination.limit;
    this.loading = false;
  }
}
