import { Component, OnInit } from '@angular/core';
import { APIService } from './api.service';
import { NavService } from './shared/service/nav.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  constructor(public API: APIService, public navService: NavService) {
    this.loadSession();
  }

  loadSession() {
    this.API.loadSession();
  }

  ngOnInit() {
    console.log('DEBUG: AppComponent -> ngOnInit -> this.API.hasSession()', this.API.hasSession());
    if (this.API.hasSession()) {
      this.navService.getUnitsArray();
    }
 }

}
