import { Injectable } from '@angular/core';
import { UserRole, User } from '../entities/residents/user.entity';
import { Suggestion, SuggestionType } from '../entities/units/suggestions.entity';
import { APIService, APIPaginationdResponse } from '../api.service';
import { roLocale } from 'ngx-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class SuggestionsService {

  constructor(
    public API: APIService,
  ) { }

  async loadUnitSuggestions(hotelCode: number, type: string, page: number = 1, limit: number = 100, filter: any = {}): Promise<APIPaginationdResponse<Suggestion>> {
    const response = await this.API.get<APIPaginationdResponse<Suggestion>>('/suggestions', {
      type,
      page,
      limit,
      hotelCode,
      ...filter
    }, {tokenRequired: true});
    const sugg = response.data.map((s) => new Suggestion(s));
    return {...response, data: sugg};
  }

  async search(hotelCode: number, type?: SuggestionType) {
    const sggs = await this.API.get<Suggestion[]>('/suggestions', {
      filter: 'unit.HotelCode||eq||' + hotelCode,
    }, {tokenRequired: true});
    return sggs.map(g => new Suggestion(g));
  }

  read(id: number) {
    return this.API.get<Suggestion>(`/suggestions/${id}`, {}, {tokenRequired: true});
  }

  update(suggestion: Suggestion, id: number) {
    return this.API.patch<Suggestion, Suggestion>(`/suggestions/${id}`, suggestion, {tokenRequired: true});
  }

  delete(id: number) {
    return this.API.delete<Suggestion>(`/suggestions/${id}`, {}, {tokenRequired: true});
  }
}
